<div class="message-container {{ variant }}">
  <h5 class="mb-3 w-100" *ngIf="title">{{ title }}</h5>

  <div class="d-flex">
    <i *ngIf="icon" [class]="icon"></i>

    <div class="message">
      <p class="m-0" *ngIf="message" [innerHTML]="message"></p>
      <ng-content></ng-content>
    </div>
  </div>
</div>
