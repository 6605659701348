<!-- <div class="alert alert-dismissible m-0" #alert [ngClass]="[_alert.active ? 'my-3 show-alert' : 'hide-alert', constructorClass,  _alert.className]" role="alert">
  <strong class="text-center" *ngIf="_alert.title">{{_alert.title}}</strong> 
  <p class="m-0" [innerHTML]="_alert.content"></p>
</div> -->

<div
  class="alert alert-dismissible m-0"
  [ngClass]="[
    _alert.active ? 'my-3 show-alert' : 'hide-alert',
    constructorClass,
    _alert.className
  ]"
  role="alert"
  *ngIf="_alert.active"
  appAutoFocus
>
  <div class="row m-0 text-center">
    <div class="col-12 mb-2" *ngIf="_alert.title">
      <strong>{{ _alert.title }}</strong>
    </div>

    <div class="col-12">
      <p class="m-0" [innerHTML]="_alert.content"></p>
    </div>
  </div>

  <button type="button" class="btn-close" (click)="closeAlert()"></button>
</div>
