import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AlertModal, AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { DEGREES } from './AcademicDegree';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { OtherDegrees } from '@models/OtherDegrees.model';
import { environment } from 'src/environments/environment';
import { SiteEnum } from '@enums/Site.enum';
import { SiteService } from '@services/site.service';

@Component({
  selector: 'app-other-degrees',
  templateUrl: './other-degrees.component.html',
  styleUrls: ['./other-degrees.component.scss'],
})
export class OtherDegreesComponent extends AmmFormComponent {
  degrees: string[] = [...DEGREES];
  specialities: any[] = [];
  environment: any = environment;
  site: SiteEnum = SiteEnum.Amm;

  override data: OtherDegrees = new OtherDegrees();

  constructor(api: ApiService, private siteService: SiteService) {
    super(api);
  }

  override settings(): void {
    this.endpoint = 'portal/me/profile/grade';
    this.method = 'put';

    const services = [this.api.get('specialities'), this.api.call('portal/me')];

    forkJoin(services).subscribe({
      next: ([specialityRes, profileRes]) => {
        if (specialityRes) {
          this.specialities = specialityRes;
        }

        if (profileRes.status === 200 && profileRes.data.profile) {
          this.data.setData(profileRes.data.profile);
        }

        this.loading = false;
      },
    });
  }

  ngOnInit(): void {
    this.site = this.siteService.site;
  }

  override isValid(): boolean {
    const valid = this.data.isValid();

    if (!valid) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena los campos requeridos *',
        className: AlertType.Danger,
      };
    }

    return valid;
  }

  override getParams() {
    return this.data.getJson();
  }
}
