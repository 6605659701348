<div class="min-container" [ngClass]="{ loading: loading }">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="row">
    <h3 class="col-12 mb-4">Completar pago</h3>
  </div>

  <div class="p-3 p-md-4 boxshadow-amm">
    <div class="row">
      <h5 class="col-12 text-primary mb-4">Revise su compra</h5>

      <div class="col mb-4">
        <h6 class="text-primary mb-0">Concepto</h6>
      </div>

      <div class="col-auto mb-4">
        <h6 class="text-primary mb-0">Monto</h6>
      </div>

      <div class="col-12 px-0" *ngIf="!multiple; else isMultiple">
        <div class="row m-0">
          <div class="col-12 px-0">
            <div class="row align-items-center m-0">
              <div class="col">
                {{ currentSubscription.name }}
              </div>

              <div class="col-auto text-end">
                {{ currentSubscription.amount | currency }}
              </div>
            </div>

            <div
              class="row align-items-center text-primary m-0"
              *ngIf="
                currentSubscription.discount &&
                +currentSubscription.discount.amount > 0
              "
            >
              <div class="col">* {{ currentSubscription.discount.name }}</div>

              <div class="col-auto text-end">
                {{ currentSubscription.discount.amount | currency }}
              </div>
            </div>
          </div>

          <hr class="col-12 text-primary my-4" />

          <div class="col-6">
            <h6 class="text-primary">Total</h6>
          </div>

          <div class="col-6 text-end">
            {{ currentSubscription.amount | currency }}
          </div>
        </div>
      </div>

      <ng-template #isMultiple>
        <div class="col-12 px-0">
          <div
            class="row align-items-center mx-0 mb-3"
            *ngFor="let sub of suscriptions"
          >
            <div class="col p-0">
              <div class="row align-items-center m-0">
                <div class="col-12">
                  <ng-container *ngIf="sub.name">
                    <span class="fw-light">{{ sub.name }}</span>

                    <br />
                  </ng-container>

                  <small>
                    <strong>{{ sub.concept }}</strong>
                  </small>
                </div>

                <div class="col-12 p-0">
                  <div class="row m-0" *ngIf="sub.extras">
                    <div class="col-12" *ngFor="let extra of sub.extras">
                      <strong [innerHTML]="extra"></strong>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 mt-1"
                  *ngIf="sub.discount && +sub.discount.amount > 0"
                >
                  <div class="d-flex w-100 text-green-dark">
                    <strong class="col">
                      * {{ sub.discount.name }} aplicada
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto text-end">
              {{ sub.amount | currency }}
            </div>
          </div>

          <div class="row mx-0">
            <hr class="col-12 text-primary mb-4" />

            <div class="col">
              <h6 class="text-primary">Total</h6>
            </div>

            <div class="col-auto text-end">
              {{ total | currency }}
            </div>
          </div>
        </div>
      </ng-template>

      <div class="col-md-6 ms-auto my-4">
        <a class="btn btn-outline-dark" [routerLink]="back">
          Modificar compra
        </a>
      </div>
    </div>
  </div>

  <div
    class="p-3 p-md-4 boxshadow-amm my-5"
    [style]="data.isZero ? 'display: none;' : ''"
  >
    <div class="row">
      <h5 class="col-12 mb-3 text-primary">Elija un método de pago</h5>

      <div class="col-12 mb-3">
        <h5>Informacion de la tarjeta</h5>
      </div>

      <app-card
        (success)="onSuccessCard($event)"
        (failed)="onFailedCard()"
      ></app-card>
    </div>
  </div>

  <div class="p-3 p-md-4 boxshadow-amm mb-5" *ngIf="!data.isZero">
    <div class="row">
      <h5 class="col-12 text-primary mb-3">Facturación</h5>

      <div class="col-12">
        <app-amm-message
          [message]="
            'Por políticas administrativas y fiscales su factura debe expedirse al momento de realizar su pago. No habrá
        posibilidad de facturar posteriormente. Si no requiere factura se expedirá con RFC genérico por lo que no
        podrá refacturarse. Podrá descargarse después en la sección de pagos de su perfil.'
          "
        ></app-amm-message>

        <div class="form-check my-3">
          <input
            class="form-check-input"
            type="radio"
            name="billRequired"
            id="bill1"
            [value]="1"
            [(ngModel)]="data.invoice"
          />
          <label class="form-check-label" for="bill1">
            No necesito expedir factura. NOTA: una factura con RFC genérico será
            generada.
          </label>
        </div>

        <div class="form-check my-3">
          <input
            class="form-check-input"
            type="radio"
            name="billRequired"
            id="bill2"
            [value]="2"
            [(ngModel)]="data.invoice"
            (click)="changeInvoiceRequired(1)"
          />
          <label class="form-check-label" for="bill2">
            Sí necesito expedir factura.
          </label>
        </div>

        <div class="form-check my-3">
          <input
            class="form-check-input"
            type="radio"
            name="billRequired"
            id="bill3"
            [value]="3"
            [(ngModel)]="data.invoice"
            (click)="changeInvoiceRequired(3)"
          />
          <label class="form-check-label" for="bill3">
            Soy extranjero y no cuento con RFC válido. NOTA: una factura con RFC
            genérico de ciudadano extranjero será generada.
          </label>
        </div>
      </div>

      <!-- When billing is not required -->
      <div
        class="col-12 horizontally-container my-4"
        *ngIf="data.invoice === 1"
      >
        <span class="mb-2">Forma de pago</span>
        <span class="required">
          <ng-select
            [items]="paymentMethods"
            bindLabel="description"
            bindValue="id"
            [(ngModel)]="genericInvoicing.payment_method"
            [clearable]="false"
            placeholder="Seleccionar forma de pago"
          ></ng-select>
        </span>
      </div>
    </div>
  </div>

  <div
    class="p-3 p-md-4 boxshadow-amm"
    *ngIf="data.invoice !== 1 && !data.isZero"
  >
    <div class="row">
      <h5 class="col-12 text-primary mb-3">Datos de facturación</h5>
    </div>

    <app-bill-form [data]="data.invoicing"></app-bill-form>

    <div class="col-12 my-4" *ngIf="data.invoice !== 1">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="saveData"
          [(ngModel)]="data.save_invoicing"
        />
        <label class="form-check-label" for="saveData">
          Guardar Datos de Facturación
        </label>
      </div>
    </div>
  </div>

  <div class="row my-4">
    <app-alert [data]="alertData"></app-alert>

    <div class="col-12 mb-4" *ngIf="taxAlertData.active">
      <div class="alert-component border-primary text-primary">
        <span class="mb-3 text-center">
          {{ taxAlertData.title }}
        </span>

        <div class="my-3" [innerHTML]="taxAlertData.content"></div>

        <a
          class="btn btn-primary btn-amm mx-auto px-4 my-3"
          routerLink="/payment/invoice"
        >
          SOLUCIONAR
          <i class="fa-solid fa-arrow-right ms-1"></i>
        </a>
      </div>
    </div>

    <div class="col-6 mx-auto">
      <a class="btn btn-outline-dark" [routerLink]="back"> REGRESAR </a>
    </div>

    <div class="col-6" *ngIf="!disabled">
      <button
        class="btn btn-primary btn-amm"
        (click)="makePayment()"
        [disabled]="loading || disabled"
      >
        {{ total == 0 ? "FINALIZAR" : "COMPLETAR PAGO" }}
        <i class="fa-regular fa-arrow-right ms-2"></i>
      </button>
    </div>
  </div>
</div>
