<div
  class="card clickable p-2"
  [ngClass]="{ 'border-amm active': currentRadio === data.id }"
  (click)="changeType()"
>
  <h6>{{ data.name }}</h6>

  <div class="form-check w-100">
    <input
      class="form-check-input"
      type="radio"
      name="{{ groupName }}"
      [id]="'cardRadio' + data.id"
      [(ngModel)]="currentRadio"
      [value]="data.id"
    />
    <p class="form-check-label m-0">
      {{ data.description }}
    </p>
  </div>
</div>
