import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[validationdirective]',
})
export class ValidationDirective implements OnInit {
  private errorSpan: HTMLSpanElement;
  @Input() errorMessage: string = '* Este campo es requerido';
  @Input() required: boolean = true;
  @Input() readonlyDir: boolean = false;
  @HostBinding('class') classNames = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.errorSpan = this.renderer.createElement('span');
    this.renderer.setProperty(this.errorSpan, 'innerText', this.errorMessage);
    this.renderer.setStyle(this.errorSpan, 'display', 'none');
    this.renderer.addClass(this.errorSpan, 'error-message');
    this.renderer.insertBefore(
      this.el.nativeElement.parentNode,
      this.errorSpan,
      this.el.nativeElement.nextSibling
    );
  }

  ngOnInit(): void {
    if (this.readonlyDir) {
      this.el.nativeElement.setAttribute('readonly', this.readonlyDir);
    }

    if (this.required) {
      this.el.nativeElement.setAttribute('required', this.required);
    }
  }
  
  @HostListener('input') onInput(): void {
    this.validation();
  }

  @HostListener('blur') onBlur(): void {
    this.validation();
  }

  private validation() {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const isValid = this.isValid(inputElement.value);

    if (!this.required || this.readonlyDir) {
      return;
    }

    if (isValid) {
      this.classNames = '';
      this.renderer.setStyle(this.errorSpan, 'display', 'none');
    } else {
      this.classNames = 'is-invalid border border-danger';
      this.renderer.setStyle(this.errorSpan, 'display', 'block');
    }
  }

  private isValid(value: string): boolean {
    return value !== '';
  }
}
