<div class="row m-0 w-100 px-lg-4" [ngClass]="{ loading: loading }">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="col-12 mb-4">
    <h3>Pagos</h3>
  </div>

  <div class="col-12">
    <mat-tab-group class="w-100">
      <mat-tab
        [label]="
          suscriptions.length > 0
            ? 'Pagos pendientes'
            : 'No hay pagos pendientes'
        "
      >
        <div class="row m-0">
          <hr class="col-12 my-4" />

          <div class="col-md-auto ms-auto mb-5" *ngIf="suscriptions.length > 0">
            <button class="btn btn-primary btn-amm" (click)="goTo()">
              COMPLETAR PAGO
              <i class="fa-regular fa-chevron-right ms-2"></i>
            </button>
          </div>

          <div class="col-12" *ngIf="suscriptions.length > 0">
            <div class="table-responsive">
              <table>
                <tr class="table-header">
                  <th>Elegir</th>
                  <!-- <th class="text-primary">Fecha emisión</th> -->
                  <th class="text-primary">Tipo</th>
                  <th class="text-primary">Concepto</th>
                  <!-- <th class="text-primary">Estado</th> -->
                  <th class="text-primary">Total</th>
                </tr>

                <tr
                  class="table-line"
                  (click)="currentPay = suscription.id"
                  *ngFor="let suscription of suscriptions"
                >
                  <td>
                    <input
                      [(ngModel)]="currentPay"
                      class="form-check-input"
                      type="radio"
                      name="pays"
                      [id]="suscription.id"
                      [value]="suscription.id"
                    />
                  </td>
                  <td>{{ suscription.name }}</td>
                  <td>
                    <p class="mb-0" [innerHTML]="suscription.concept"></p>
                  </td>
                  <td>{{ suscription.amount | currency }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab
        [label]="
          payments.length > 0 ? 'Pagos completados' : 'No hay pagos realizados'
        "
      >
        <div class="row m-0">
          <hr class="col-12 my-4" />

          <div class="col-12" *ngIf="payments.length > 0">
            <div class="table-responsive">
              <table>
                <tr class="table-header text-primary">
                  <th>Fecha emisión</th>
                  <th>Tipo</th>
                  <th>Concepto</th>
                  <th>Total</th>
                  <th>Documento</th>
                </tr>

                <tr *ngFor="let payment of payments">
                  <td>{{ payment.created_at | zerodate }}</td>
                  <td>{{ payment.subscription_type | paymenttype }}</td>
                  <td class="text-start">
                    <div
                      class="mb-0"
                      [innerHTML]="payment.description | safepipe : 'html'"
                    ></div>
                  </td>
                  <td>{{ payment.amount | currency }}</td>
                  <td>
                    <div
                      *ngIf="
                        payment.subscription_type === 'webcast' ||
                          payment.subscription_type === 'membership' ||
                          (payment.invoice !== 0 &&
                            payment.subscription_type &&
                            (payment.subscription_type === 'membership' ||
                              payment.subscription_type === 'affiliation'));
                        else noAvailable
                      "
                    >
                      <button
                        class="btn btn-link text-primary fw-light"
                        (click)="handleAction(payment)"
                      >
                        {{
                          payment.subscription_type
                            | paymentactiontype : payment.vigency
                        }}
                      </button>
                    </div>

                    <ng-template #noAvailable>
                      <div class="horizontally-container text-center">
                        No disponible
                        <a
                          href="mailto:{{
                            siteService.site === 'ammom'
                              ? 'educacion@ammom.mx'
                              : 'contacto@' + environment.domain
                          }}"
                          class="text-primary w-100"
                          >Contactar {{ environment.association.name }}</a
                        >
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
