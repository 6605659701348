import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, AlertType } from '@models/Alert.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { UtilsService } from '@services/utils.service';
import { AlertModalComponent } from '@shared/components/alert-modal/alert-modal.component';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
})
export class AccessComponent extends AmmFormComponent {
  alertEmailData: AlertModal = new AlertModal();
  profile: Profile = new Profile();
  email: string = '';
  password: string = '';
  confirm: string = '';
  isLogged: boolean = false;
  currentStep: number = 0;
  nextStep: number = 2;
  environment: any = environment;

  constructor(
    api: ApiService,
    private user: UserService,
    private modalService: NgbModal,
    protected siteService: SiteService,
    private utils: UtilsService
  ) {
    super(api);
  }

  ngOnInit(): void {
    this.isLogged = this.user.logged;

    if (this.isLogged) {
      if (this.user.profile) this.profile.setData(this.user.profile);
      this.endpoint = 'portal/me/password';
      this.method = 'put';

      this.alertEmailData.data = {
        className: AlertType.Danger,
        content: 'Correo electrónico pendiente de verificación',
      };
    } else {
      this.currentStep = this.user.registerStep;

      this.getRegisterData();
    }

    this.loading = false;
  }

  getRegisterData() {
    const data = this.user.getRegister('STEP1');

    if (data) {
      this.email = data.email;
      this.password = data.password;
      this.confirm = data.confirm;
      this.profile.legals = data.legals;
      this.profile.newsletter = data.newsletter;
    }
  }

  handleChange() {
    if (this.isLogged) {
      this.submit();
    } else {
      this.checkMail();
    }
  }

  checkMail() {
    // call service
    if (!this.isValid()) {
      return;
    }

    this.loading = true;

    this.api.call('portal/status', 'post', { email: this.email }).subscribe({
      next: (response) => {
        // Email is not registered
        if (response.status === 400) {
          this.loading = false;
          this.saveData();
          this.user.registerStep =
            this.nextStep < this.currentStep ? this.currentStep : this.nextStep;
        } else {
          this.handleAlert(
            'OCURRIÓ UN ERROR',
            'Ese usuario ya existe, ingrese otro correo electrónico para iniciar su registro'
          );
          this.loading = false;
        }
      },
      error: (error) => {
        console.error(error);

        this.handleAlert(
          'OCURRIÓ UN ERROR',
          'Ese usuario ya existe, ingrese otro correo electrónico para iniciar su registro'
        );

        this.loading = false;
      },
    });
  }

  saveDataRegister() {
    this.loading = true;

    const data = {
      key: 'STEP1',
      value: {
        email: this.email,
        password: this.password,
        confirm: this.confirm,
        legals: Number(this.profile.legals),
        newsletter: Number(this.profile.newsletter),
      },
    };

    this.user.setRegister(data);
  }

  saveData() {
    if (this.loading) return;

    if (this.isLogged) {
      this.submit();
    }

    if (!this.isValid()) return;

    this.saveDataRegister();

    return;
  }

  override success(response: any): void {
    this.alertData.data = {
      className: AlertType.Success,
      content: '¡Contraseña actualizada!',
    };

    this.password = '';
    this.confirm = '';

    this.loading = false;
  }

  override failed(error: any): void {
    console.error(error);
    this.alertData.data = {
      className: AlertType.Danger,
      content: error,
    };

    this.loading = false;
  }

  override getParams(): {} {
    return {
      password: this.password,
      confirm: this.confirm,
    };
  }

  override isValid(): boolean {
    if (this.password === '' || this.confirm === '') return false;

    if (this.password !== this.confirm) {
      this.alertData.data = {
        className: AlertType.Danger,
        content: '¡Las contraseñas no coinciden!',
      };

      return false;
    }

    if (!this.utils.isValidPassword(this.password)) {
      this.alertData.data = {
        className: AlertType.Danger,
        content:
          '¡La contraseña debe tener al menos 6 caracteres con al menos una mayúscula, una minúscula y un número!',
      };

      return false;
    }

    if (!this.isLogged && !this.utils.isValidMail(this.email)) {
      this.alertData.data = {
        className: AlertType.Danger,
        content: '¡El correo electrónico es obligatorio!',
      };

      return false;
    }

    if (!this.profile.legals) {
      this.alertData.data = {
        className: AlertType.Danger,
        content: '¡Tienes que aceptar el aviso de privacidad!',
      };

      return false;
    }

    return true;
  }

  override handleAlert(title: string, message: string, btnText: string = 'ACEPTAR') {
    const modalRef = this.modalService.open(AlertModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnText = btnText;
    modalRef.componentInstance.title = title;

    return true;
  }
}
