<div class="min-container boxshadow-amm px-lg-4 py-3">
  <div class="row" [ngClass]="loading ? 'loading': '' ">
    <app-loading-spinner class="loading-spinner" [loading]="loading"></app-loading-spinner>
    
    <div class="col-12 d-flex align-items-center mb-4">
      <h3>Datos de facturación</h3>

      <span class="ms-auto text-primary" *ngIf="invoiceData.isForeign">Extranjero</span>
    </div>
  
    <app-bill-form [data]="invoiceData" [isPayment]="false"></app-bill-form>
  
    <app-alert [data]="alertData"></app-alert>
  
    <div class="col-12 my-4">
      <button class="btn btn-primary btn-amm" (click)="updateBillingData()" [disabled]="loading">
        GUARDAR
      </button>
    </div>
  </div>
</div>