<div class="row mx-0 gy-4 mb-4 w-100">
  <div class="col-12" *ngIf="hasTitle">
    <h3 class="mb-0">Dirección</h3>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[0] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Calle"
        [(ngModel)]="data.street"
        validationdirective
        [required]="requireds[0]"
      />
    </span>
  </div>

  <div class="col-6">
    <span [ngClass]="{ required: requireds[1] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Núm. Ext."
        [(ngModel)]="data.external_house_number"
        validationdirective
        [required]="requireds[1]"
      />
    </span>
  </div>

  <div class="col-6">
    <span [ngClass]="{ required: requireds[2] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Núm. Int."
        [(ngModel)]="data.internal_house_number"
        [required]="requireds[2]"
      />
    </span>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[3] }">
      <input
        type="number"
        maxdigits
        [maxlength]="5"
        class="form-control input-amm"
        placeholder="Código postal"
        [(ngModel)]="data.zip_code"
        validationdirective
        [required]="requireds[3]"
      />
    </span>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[4] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Municipio"
        [(ngModel)]="data.municipality"
        validationdirective
        [required]="requireds[4]"
      />
    </span>
  </div>

  <div class="col-12" *ngIf="data.country">
    <span [ngClass]="{ required: requireds[5] }">
      <ng-select
        [items]="states"
        bindLabel="name"
        bindValue="id"
        placeholder="Estado"
        [(ngModel)]="data.state"
        [clearable]="false"
        *ngIf="
          data.country.toLowerCase() === 'méxico' ||
            data.country.toLowerCase() === 'mexico';
          else noMexico
        "
      ></ng-select>

      <ng-template #noMexico>
        <input
          type="text"
          class="form-control input-amm"
          placeholder="Estado"
          [(ngModel)]="data.state"
          validationdirective
          [required]="requireds[5]"
        />
      </ng-template>
    </span>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[6] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Colonia"
        [(ngModel)]="data.neighborhood"
        validationdirective
        [required]="requireds[6]"
      />
    </span>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[7] }">
      <ng-select
        [items]="countries"
        bindLabel="name"
        bindValue="name"
        placeholder="Elija un país"
        [(ngModel)]="data.country"
        [clearable]="false"
        [required]="requireds[7]"
      ></ng-select>
    </span>
  </div>

  <div class="col-12">
    <span [ngClass]="{ required: requireds[8] }">
      <input
        type="text"
        class="form-control input-amm"
        placeholder="Ciudad"
        [(ngModel)]="data.city"
        validationdirective
        [required]="requireds[8]"
      />
    </span>
  </div>
</div>
