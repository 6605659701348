export class PrivacyPreferences {
  public: boolean = true;
  newsletter: boolean = true;
  legals: boolean = true;

  getJSON(hasPublic: boolean = true) {
    const params: any = {
      newsletter: Number(this.newsletter),
      legals: Number(this.legals),
    };

    params['public'] = Number(this.public);

    return params;
  }
}
