<div class="container py-4 px-0" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="row">
    <div class="col-12 mb-4" [ngClass]="{ 'text-center': !isLogged }">
      <h3>Datos de acceso</h3>
    </div>

    <div class="col-12 horizontally-container">
      <h5>Usuario{{ isLogged ? " actual" : "" }}:</h5>

      <span *ngIf="isLogged; else isInput">{{ profile.email }}</span>

      <ng-template #isInput>
        <div class="has-validation d-flex flex-column required">
          <input
            type="text"
            class="form-control input-amm text-start"
            name="email"
            id="email"
            [(ngModel)]="email"
            placeholder="sucorreo@dominio.com"
            validationdirective
          />

          <div class="invalid-feedback ps-2">¡Ingresa un email valido!</div>
        </div>
      </ng-template>
    </div>

    <!-- <div class="col-12 my-4">
      <app-alert [data]="alertEmailData"></app-alert>
    </div> -->

    <!-- <div class="col-12">
      <a href="" class="amm-link">
        Reenviar correo de verificación
      </a>
    </div> -->

    <hr class="col-12 my-5" />

    <div class="col-12 mb-3">
      <h5>Contraseña</h5>
    </div>

    <div class="col-12 mb-4">
      <p class="mb-0">
        Su contraseña debe ser de mínimo 6 caracteres, 1 número y al menos una
        mayúscula y una minúscula.
      </p>
    </div>

    <div class="col-12 mb-4">
      <label class="mb-2" for="password">
        {{ isLogged ? "Cambiar contraseña" : "Contraseña" }}
      </label>

      <input
        type="password"
        class="form-control input-amm"
        name="password"
        id="password"
        [(ngModel)]="password"
        validationdirective
        placeholder="Contraseña"
      />
    </div>

    <div class="col-12 mb-4">
      <label class="mb-2" for="confirm">Confirmar</label>

      <input
        type="password"
        class="form-control input-amm"
        name="confirm"
        id="confirm"
        [(ngModel)]="confirm"
        validationdirective
        placeholder="Confirmar contraseña"
        [autocomplete]="false"
      />
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12 p-0" *ngIf="!isLogged">
      <div class="row m-0 w-100">
        <div class="col-12 mb-3">
          <div class="form-check w-100">
            <input
              class="form-check-input"
              type="checkbox"
              id="terms"
              [(ngModel)]="profile.legals"
            />
            <label class="form-check-label m-0" for="terms">
              Al enviar mis datos acepto el
              <a
                routerLink="/terms"
                class="text-primary"
                target="_blank"
                rel="noopener noreferrer"
                >Aviso de privacidad</a
              >
              de la Asociación
            </label>
          </div>
        </div>

        <div class="col-12 mb-4" *ngIf="siteService.site !== 'ammom'">
          <div class="form-check w-100">
            <input
              class="form-check-input"
              type="checkbox"
              id="newsletter"
              [(ngModel)]="profile.newsletter"
            />
            <label class="form-check-label m-0" for="newsletter">
              Es de mi interés recibir información sobre la
              {{ environment.association.short_name }} a mi correo electrónico y
              por otros medios digitales
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 my-4">
      <button
        class="btn btn-primary btn-amm"
        (click)="handleChange()"
        [disabled]="loading"
      >
        {{ isLogged ? "CAMBIAR CONTRASEÑA" : "SIGUIENTE" }}

        <i class="fa-regular fa-arrow-right ms-1 my-auto" *ngIf="!isLogged"></i>
      </button>
    </div>
  </div>
</div>
