import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-amm-message',
  templateUrl: './amm-message.component.html',
  styleUrls: ['./amm-message.component.scss']
})
export class AmmMessageComponent {
  @Input() variant: string = 'bg-light-gray';
  @Input() message: string = '';
  @Input() title: string = '';
  @Input() icon: string = 'fa-solid fa-circle-info mx-3 text-pink fa-xl';
}
