<div class="row py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="col-12 mb-4" [ngClass]="{ 'text-center': !isLogged }">
    <h3>
      {{
        isLogged
          ? "Membresía"
          : saved
          ? "Perfil de usuario No socio"
          : "Elija su tipo de Membresía"
      }}
    </h3>

    <h4 *ngIf="isLogged && !data.is_member">
      {{
        siteService.site === "ammom"
          ? "Aliado Solidario (No socio)"
          : "No socio"
      }}
    </h4>
  </div>

  <!-- When logged -->
  <div
    class="col-12 p-0"
    *ngIf="
      isLogged && data.membership_id !== environment.memberships.pending;
      else noPayment
    "
  >
    <div class="row gy-3 m-0 w-100">
      <div class="col-12 mb-4" *ngFor="let member of memberships">
        <app-membership-selector
          [hasRadio]="false"
          [data]="member"
          [currentId]="data.membership_id"
          [isCollapsed]="collapseId !== member.id"
          (onMemberChange)="selectMembership(member)"
          (onCollapse)="collapseId = $event"
        ></app-membership-selector>
      </div>
    </div>
  </div>

  <ng-template #noPayment>
    <app-amm-message
      icon=""
      [message]="message"
      variant="border-primary text-primary bg-white"
      class="mb-3"
      *ngIf="isLogged"
    >
    </app-amm-message>
  </ng-template>

  <div class="col-12 p-0" *ngIf="!isLogged">
    <div class="row gy-4 w-100 m-0">
      <div
        class="col-12 p-0 mb-4 membership-container"
        *ngFor="let member of memberships"
      >
        <app-membership-selector
          [data]="member"
          [currentId]="data.membership_id"
          [isCollapsed]="collapseId !== member.id"
          (onMemberChange)="changeMember(member)"
          (onCollapse)="collapseId = $event"
        ></app-membership-selector>
      </div>
    </div>
  </div>

  <app-alert [data]="alertData"></app-alert>

  <!-- SOCIO ASOCIADO / SOCIO TITULAR -->
  <div
    class="col-12 border-primary py-3 my-4"
    *ngIf="submitted && data.membership_id === 5"
    appAutoFocus
  >
    <p>
      Estimado Socio
      <br />
      <br />
      Hemos recibido su solicitud de cambio de membresía, recuerde que usted
      debe cumplir con los siguientes requisitos:
    </p>

    <ul>
      <li>Ser socio asociado con una antigüedad de cinco años.</li>

      <li>No tener adeudo de los últimos cinco años.</li>
    </ul>

    <p>
      Consulte el status de su solicitud dentro de su sesión en un plazo de 24
      hrs., si no hay un cambio, por favor comuníquese al siguiente pool de
      atención.
      <br />
      <br />

      Consulta de membresías:
      <a class="amm-link" href="mailto:contabilidad@{{ environment.domain }}"
        >contabilidad@{{ environment.domain }}</a
      >
      <br />
      <br />

      Problemas con mi usuario:
      <a class="amm-link" href="mailto:soporte@{{ environment.domain }}"
        >soporte@{{ environment.domain }}</a
      >
    </p>
  </div>

  <!-- SOCIO TRANSITORIO / SOCIO ASOCIADO -->
  <div
    class="col-12 border-primary py-3 my-4"
    *ngIf="
      submitted &&
      (data.membership_id === 2 ||
        data.membership_id === 3 ||
        data.membership_id === 4)
    "
    appAutoFocus
  >
    <p>
      Estimado Socio
      <br />
      <br />

      Hemos recibido su solicitud de cambio de membresía, este se condiciona a
      los siguientes requisitos:
    </p>

    <ul>
      <li>
        En documentación debe estar el adjunto de la constancia de especialidad
      </li>
      <li>Haber cumplido un año como socio transitorio</li>
      <li>No tener adeudo de cuotas.</li>
    </ul>

    <p>
      Consulte el status de su solicitud dentro de su sesión en un plazo de 24
      hrs., si no hay un cambio, por favor comuníquese al siguiente pool de
      atención.
      <br />
      <br />
      Consulta de membresías:

      <a class="amm-link" href="mailto:contabilidad@{{ environment.domain }}"
        >contabilidad@{{ environment.domain }}</a
      >
      <br />
      <br />

      Problemas con mi usuario:
      <a class="amm-link" href="mailto:soporte@{{ environment.domain }}"
        >soporte@{{ environment.domain }}</a
      >
    </p>
  </div>

  <hr class="col-12 my-4" *ngIf="!isLogged" />

  <!-- Check user member  -->
  <div
    class="col-12"
    *ngIf="
      (canChange &&
        data.membership_id !== environment.memberships.pending &&
        memberships.length > 1) ||
      !isLogged
    "
  >
    <button
      class="btn btn-primary btn-amm"
      (click)="handleAction()"
      [disabled]="loading || submitted"
    >
      {{
        isLogged
          ? data.is_member === 1
            ? "SOLICITAR CAMBIO DE MEMBRESÍA"
            : "SOLICITAR AFILIACIÓN A ASOCIACIÓN"
          : "SIGUIENTE"
      }}

      <i class="fa-regular fa-arrow-right ms-1 my-auto" *ngIf="!isLogged"></i>
    </button>
  </div>
</div>
