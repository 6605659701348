<div class="container py-4 my-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="mt-4 mb-5 text-center">Información Socios {{ environment.association.name }}</h2>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <span class="text-primary"> * Datos obligatorios </span>
    </div>

    <div class="col-12">
      <h3>Consultorio</h3>
    </div>

    <div class="col-12">
      Hospital o Consultorio
      <span class="required">
        <input
          type="email"
          class="form-control input-amm mt-2"
          [(ngModel)]="data.hospital_name"
          placeholder="Nombre"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Nº de consultorio

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.hospital_number"
          placeholder="Nombre"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Teléfono Consultorio
      <span class="mt-2">
        <input
          id="phone"
          name="phone"
          type="tel"
          class="form-control input-amm"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          [(ngModel)]="data.hospital_phone"
          placeholder="Teléfono a  10 dígitos"
          maxdigits
        />
      </span>
    </div>

    <div class="col-12">
      Extensión Telefónica Consultorio
      <span class="mt-2">
        <input
          type="tel"
          id="phone"
          name="phone"
          class="form-control input-amm"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          [(ngModel)]="data.hospital_ext"
          placeholder="Teléfono a  10 dígitos"
          maxdigits
        />
      </span>
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Domicilio Consultorio</h3>
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Calle"
        [(ngModel)]="data.hospital_address_street"
      />
    </div>

    <div class="col-6">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Núm. Ext."
        [(ngModel)]="data.hospital_address_ext"
      />
    </div>

    <div class="col-6">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Núm. Int."
        [(ngModel)]="data.hospital_address_int"
      />
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Colonia"
        [(ngModel)]="data.hospital_address_colony"
      />
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Municipio o alcaldía"
        [(ngModel)]="data.hospital_address_municipality"
      />
    </div>

    <div class="col-12">
      <ng-select
        [items]="states"
        bindLabel="name"
        bindValue="id"
        placeholder="Estado"
        [(ngModel)]="data.hospital_address_state"
        [clearable]="false"
        *ngIf="
          data.hospital_address_country?.toLowerCase() === 'méxico' ||
            data.hospital_address_country?.toLowerCase() === 'mexico';
          else noMexico
        "
      ></ng-select>

      <ng-template #noMexico>
        <input
          type="text"
          class="form-control input-amm"
          placeholder="Estado"
          [(ngModel)]="data.hospital_address_state"
        />
      </ng-template>
    </div>

    <div class="col-12">
      <input
        type="number"
        class="form-control input-amm text-start"
        placeholder="Código postal"
        [(ngModel)]="data.hospital_address_zip"
        maxdigits
        [maxlength]="5"
      />
    </div>

    <div class="col-12">
      <ng-select
        [items]="countries"
        bindLabel="name"
        bindValue="name"
        placeholder="Elija un país"
        [(ngModel)]="data.hospital_address_country"
        [clearable]="false"
      ></ng-select>
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Ciudad"
        [(ngModel)]="data.hospital_address_city"
      />
    </div>
  </div>

  <div class="row">
    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
