import { Component } from '@angular/core';
import { Observable, Observer, forkJoin } from 'rxjs';

const MB_SIZE = 1024 * 1024;

class Template {
  name: string = '';
  file: string = '';
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent {
  templates: Template[] = [];
  fileTypes: string = 'image/png, image/jpeg, .png, .jpg, .pdf';
  maxSize: number = 5;
  maxFiles: number = 1;
  loading: boolean = false;

  reset(inputRef: HTMLInputElement, index: number) {
    inputRef.value = '';
    this.templates = [];
    this.onFileDelete(index)
  }

  onFileError(message: string) {}

  onFileSuccess(files: string[]) {}

  onFileDelete(index: number) {}

  onLoading(state: boolean) {
    this.loading = state;
  }

  // 1 Titulo, 2 Cedula
  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files instanceof FileList) {
      if (files.length > this.maxFiles) {
        this.onFileError('¡Número de archivos máximo superado!');
        return;
      }

      const allFilesValid = Array.from(files).every((file) => {
        return file.size <= (this.maxSize * MB_SIZE);
      });

      if (!allFilesValid) {
        this.onFileError('El archivo supera el tamaño máximo permitido');
        return;
      }

      this.onLoading(true);

      this.convertFilesToBase64(files).subscribe({
        next: (base64Array: string[]) => {
          for (let i = 0; i < base64Array.length; i++) {
            const template = {
              name: '',
              file: '',
            };
            template.name = files[i].name;
            template.file = base64Array[i];

            this.templates.push(template);
          }

          this.onFileSuccess(base64Array);
        },
        error: (error) => {
          console.error(error);
          this.onFileError(error);
        },
      });
    }
  }

  remove(inputElement: HTMLInputElement, index: number) {
    if (inputElement.files) {
      const files = Array.from(inputElement.files);

      if (this.templates.length <= 1) {
        this.reset(inputElement, index);
        return;
      }

      if (index >= 0 && index < files.length) {
        files.splice(index, 1);
        inputElement.files = this.createFileList(files);
      }
      
    }
    
    this.templates.splice(index, 1);
    this.onFileDelete(index) 
  }

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();

    for (const file of files) {
      dataTransfer.items.add(file);
    }

    return dataTransfer.files;
  }

  convertFilesToBase64(files: FileList): Observable<string[]> {
    const conversions: Observable<string>[] = Array.from(files).map((file) =>
      this.convertToBase64(file)
    );
    return forkJoin(conversions);
  }

  convertToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.onerror = () => {
        observer.error('Error al cargar el archivo.');
      };

      reader.readAsDataURL(file);
    });
  }
}
