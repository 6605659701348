import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SolveInvoiceComponent } from 'src/app/payment/components/solve-invoice/solve-invoice.component';
import { Invoice } from '@models/Invoice.model';
import { ApiService } from '@services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {
  loading: boolean = true;
  invoices: Invoice[] = [];
  environment:any = environment;

  constructor(private api: ApiService, private modalService: NgbModal) {
    this.getInvoices();
  }

  getInvoices() {
    this.api.callReq(
      { service: 'portal/invoices', method: 'GET', params: {} },
      (response: any) => {
        this.invoices = response.data;
        this.loading = false;
      },
      (error: any) => {
        console.error(error);
        this.loading = false;
      }
    );
  }


  downloadFile(id: number, type: string = 'pdf') {
    if (this.loading) return;

    this.loading = true;

    const url = `portal/invoices/download/${id}/${type}`;
    this.api.download(url, () => {
      this.loading = false;      
    });

  }

  solveInvoice(invoice: Invoice) {
    const modalRef = this.modalService.open(SolveInvoiceComponent, { backdrop: 'static', size: 'lg', scrollable: true });
    modalRef.componentInstance.invoice = invoice;

    modalRef.result.then((result) => {
      this.getInvoices();
    });
  }
}
