import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// STRIPE
import { NgxStripeModule } from 'ngx-stripe';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AccessComponent } from './account/pages/access/access.component';
import { AccountComponent } from './account/account.component';
import { AccountFormComponent } from './account/components/account-form/account-form.component';
import { PaymentsComponent } from './payment/pages/payments/payments.component';
import { BillFormComponent } from './payment/components/bill-form/bill-form.component';
import { BillingDataComponent } from './payment/pages/billing-data/billing-data.component';
import { CardComponent } from './payment/components/stripe-card/stripe-card.component';
import { DoctorsOfficeComponent } from './account/pages/doctors-office/doctors-office.component';
import { DocumentationComponent } from './account/pages/documentation/documentation.component';
import { FinishRegisterComponent } from './account/pages/finish-register/finish-register.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FormFileSelectorComponent } from './account/components/form-file-selector/form-file-selector.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { InfoComponent } from './account/pages/info/info.component';
import { InstitutionalInformationComponent } from './account/pages/institutional-information/institutional-information.component';
import { InvoiceComponent } from './payment/pages/invoice/invoice.component';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { MakePaymentComponent } from './payment/pages/make-payment/make-payment.component';
import { MedicalEducationComponent } from './account/pages/medical-education/medical-education.component';
import { MembershipComponent } from './account/pages/membership/membership.component';
import { OtherDegreesComponent } from './account/pages/other-degrees/other-degrees.component';
import { PasswordModalComponent } from './auth/password-modal/password-modal.component';
import { PaymentComponent } from './payment/payment.component';
import { PreferencesComponent } from './payment/pages/preferences/preferences.component';
import { PrivacyComponent } from './account/pages/privacy/privacy.component';
import { ProfileMenuComponent } from './layout/profile-menu/profile-menu.component';
import { RecommendationComponent } from './account/pages/recommendation/recommendation.component';
import { RecoverComponent } from './auth/recover/recover.component';
import { RegisterComponent } from './auth/register/register.component';
import { RegisterMessageComponent } from './auth/components/register-message/register-message.component';
import { SolveInvoiceComponent } from './payment/components/solve-invoice/solve-invoice.component';
import { SpecialtyEducationComponent } from './account/pages/specialty-education/specialty-education.component';
import { SpecialtyModalComponent } from './account/components/specialty-modal/specialty-modal.component';
import { InstitucionalComponent } from './account/pages/institucional/institucional.component';

// SERVICES
import { ApiInterceptorInterceptor } from './services/guards/interceptors/api-interceptor.interceptor';
import { UserService } from './services/user.service';

// MODULES
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AddressComponent } from './account/components/address/address.component';
import { MembershipSelectorComponent } from './account/components/membership-selector/membership-selector.component';
import { AutologinComponent } from './auth/autologin/autologin.component';

@NgModule({
  declarations: [
    AccessComponent,
    AccountComponent,
    AccountFormComponent,
    AddressComponent,
    PaymentsComponent,
    AppComponent,
    BillFormComponent,
    BillingDataComponent,
    CardComponent,
    DoctorsOfficeComponent,
    DocumentationComponent,
    FinishRegisterComponent,
    FooterComponent,
    FormFileSelectorComponent,
    HeaderComponent,
    HomeComponent,
    InfoComponent,
    InstitucionalComponent,
    InstitutionalInformationComponent,
    InvoiceComponent,
    LoginComponent,
    MainComponent,
    MakePaymentComponent,
    MedicalEducationComponent,
    MembershipComponent,
    MembershipSelectorComponent,
    OtherDegreesComponent,
    PasswordModalComponent,
    PaymentComponent,
    PreferencesComponent,
    PrivacyComponent,
    ProfileMenuComponent,
    RecommendationComponent,
    RecoverComponent,
    RegisterComponent,
    RegisterMessageComponent,
    SolveInvoiceComponent,
    SpecialtyEducationComponent,
    SpecialtyModalComponent,
    AutologinComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // SHARED
    SharedModule,

    // stripe modules
    NgxStripeModule.forRoot(environment.stripeKey),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorInterceptor,
      multi: true,
    },
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
