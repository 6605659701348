import { Component } from '@angular/core';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { AlertType } from '@models/Alert.model';
import { PrivacyPreferences } from '@models/Privacy.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { SiteEnum } from '@enums/Site.enum';
import { SiteService } from '@services/site.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent extends AmmFormComponent {
  override data: PrivacyPreferences = new PrivacyPreferences();
  profile: Profile = new Profile();
  site: SiteEnum = SiteEnum.Amm;

  constructor(
    api: ApiService,
    private user: UserService,
    private siteService: SiteService
  ) {
    super(api);

    this.data = new PrivacyPreferences();
    this.site = this.siteService.site;

    if (user.profile) {
      this.profile.setData(user.profile);
      this.data.public = this.profile.public === 1;
      this.data.newsletter = this.profile.newsletter === 1;
      this.data.legals = this.profile.legals === 1;
    }

    this.endpoint = 'portal/me/privacy';
    this.method = 'put';
    this.loading = false;
  }

  override isValid(): boolean {
    if (this.loading) return false;

    return true;
  }

  override getParams(): {} {
    return this.data.getJSON(this.site !== SiteEnum.Ammom);
  }

  override success(response: any): void {
    this.alertData.data = {
      title: '¡Éxito!',
      content: 'Tus preferencias han sido actualizadas.',
      className: AlertType.Success,
    };

    if (response.data) {
      this.profile.setData(response.data);
      this.user.profile = this.profile;
    }

    this.loading = false;
  }

  override failed(error: any): void {
    console.error(error);
    this.alertData.data = {
      title: '¡Error!',
      content: error,
      className: AlertType.Danger,
    };

    this.loading = false;
  }
}
