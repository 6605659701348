import { Component } from '@angular/core';
import { AlertType } from '@models/Alert.model';
import { Document } from '@models/Document.component';
import { DocumentForm } from '@models/DocumentForm.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent extends AmmFormComponent {
  environment: any = environment;
  logged: boolean = false;
  currentStep: number = 0;
  nextStep: number = 5;
  site: SiteEnum = SiteEnum.Amm;
  override data: DocumentForm = new DocumentForm();

  constructor(
    api: ApiService,
    private user: UserService,
    private siteService: SiteService
  ) {
    super(api);
    this.site = this.siteService.site;

    if (this.site === SiteEnum.Ammom) this.data.curricularFile = new Document();
  }

  ngOnInit(): void {
    this.logged = this.user.logged;

    if (this.logged) {
      this.endpoint = 'portal/me/profile/documents';
      this.method = 'put';

      this.api.callReq(
        {
          service: 'portal/me',
          method: 'get',
          params: {},
        },
        (response) => {
          if (response.status === 200 && response.data.profile) {
            this.data.setData(response.data.profile);
          }

          this.loading = false;
        },
        (error) => {
          console.error(error);

          this.loading = false;
        }
      );
    } else {
      this.currentStep = this.user.registerStep;

      this.getRegisterData();
    }
  }

  override getParams() {
    return this.data.getJson();
  }

  handleSubmit() {
    if (this.logged) {
      this.submit();
    } else {
      if (!this.isValid()) return;

      this.saveDataRegister();
    }
  }

  saveDataRegister() {
    const value: any = {
      document_title: this.data.degreeFile,
      document_card: this.data.professionalFile,
    };

    if (this.site === 'ammom' && this.data.curricularFile) {
      value['document_curricular'] = this.data.curricularFile;
    }

    const data = {
      key: 'STEP4',
      value,
    };

    this.user.setRegister(data);
    // console.log('saving: ', value);

    this.user.registerStep =
      this.nextStep < this.currentStep ? this.currentStep : this.nextStep;
  }

  // REGISTER STEPS
  getRegisterData() {
    const data = this.user.getRegister('STEP4');

    if (data) {
      this.data.degreeFile = data['document_title'];
      this.data.professionalFile = data['document_card'];
      this.data.curricularFile = data['document_curricular'];
    }

    // console.log(data);

    this.loading = false;
  }

  override isValid(): boolean {
    let valid = this.data.isValid();

    if (this.site === 'ammom') {
      if (!this.data.curricularFile) {
        valid = false;
      } else {
        valid = valid && this.data.curricularFile.contents.length > 0;
      }
    }

    !valid && this.failed('Por favor, seleccione los archivos requeridos');

    return valid;
  }

  onFileSelected(templates: Document[], type: number) {
    switch (type) {
      case 1:
        this.data.degreeFile = templates[0];

        break;

      case 2:
        this.data.professionalFile = templates[0];

        break;

      case 3:
        this.data.curricularFile = templates[0];

        break;

      default:
        break;
    }
  }

  onFileError(error: string) {
    this.alertData.data = {
      title: '¡Error!',
      content: error,
      className: AlertType.Danger,
    };

    return;
  }
}
