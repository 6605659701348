<header>
  <div class="row align-items-center justify-content-between w-100">
    <div class="col-12 col-lg mb-3 mb-lg-0 text-center text-md-start">
      <a class="header-logo text-decoration-none text-light" routerLink="/">
        <!-- src="assets/images/logo_header.png" -->
        <img
          class="clickable me-1 me-md-2"
          [src]="'images/logo_header.png' | siteAsset : site"
          alt="Logo Header"
        />

        {{ environment.association.short_name }}
      </a>
    </div>

    <div class="col-auto ms-lg-auto me-lg-3" *ngIf="isLogged">
      <div ngbDropdown class="d-inline-block">
        <span id="dropdownBasic1" class="clickable" ngbDropdownToggle>
          Servicios

          <i
            class="fa-solid fa-grid fa-xl ms-2 text-light"
            [ngClass]="{
              'text-light': site === 'somos' || site === 'ammom',
              'text-primary': site === 'amm'
            }"
          ></i>
        </span>

        <div
          ngbDropdownMenu
          class="dropdown-menu-dark quick-access"
          aria-labelledby="dropdownBasic1"
        >
          <a
            class="d-flex w-100 align-items-center"
            [routerLink]="item.link"
            routerLinkActive="dropdown-item-active"
            *ngFor="let item of servicesItems"
            ngbDropdownItem
          >
            <span class="me-2">{{ item.name }} </span>

            <img
              class="ms-auto"
              [src]="'icons/services/' + item.image | siteAsset : site"
              alt="Service image"
              width="18"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="col-auto ms-auto" *ngIf="isLogged">
      <div ngbDropdown class="d-inline-block clickable">
        <span id="dropdownBasic1" class="clickable" ngbDropdownToggle>
          <img
            class="profile-img"
            [src]="profile.avatar_url"
            alt="Logo amm"
            width="33"
            onerror="this.onerror=null; this.src='/assets/images/avatar.png'"
          />

          <span class="d-none d-md-inline-flex mx-2">
            {{ profile.name + " " + profile.last_name }}
          </span>

          <i class="fa-solid fa-chevron-down ms-2"></i>
        </span>

        <div
          ngbDropdownMenu
          class="dropdown-menu-dark"
          aria-labelledby="dropdownBasic1"
        >
          <a routerLink="/account/info" ngbDropdownItem> Cuenta </a>

          <a
            routerLink="/payment/membership"
            ngbDropdownItem
            *ngIf="profile.is_member === 1"
          >
            Pagos
          </a>

          <button (click)="logout()" ngbDropdownItem>Cerrar sesión</button>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #specialityModal>
  <app-specialty-modal
    [specialityError]="speciality"
    [membershipError]="membership"
    (close)="closeModal()"
  ></app-specialty-modal>
</ng-template>
