<div class="row py-4 text-center">
  <h2 class="col-12">
    Se ha registrado como:
    <span class="text-primary">
      {{ currentMembership.name }}
    </span>
  </h2>

  <div class="col-12 px-0" *ngIf="siteService.site !== 'ammom'">
    <div class="row gy-4 text-center">
      <div class="col-12">
        <h3 class="mb-0">Siguientes pasos:</h3>
      </div>

      <div clas="col-12" *ngIf="profile.is_member === 0">
        1. Si desea registrarse al Congreso anual vaya a
        <a routerLink="/congress" class="text-primary">Congresos</a>.
      </div>

      <div clas="col-12" *ngIf="profile.is_member === 0">
        2. Vaya a su
        <a routerLink="/account/info" class="text-primary">Cuenta</a> si
        requiere modificar sus datos
      </div>

      <div clas="col-12" *ngIf="profile.is_member === 1">
        1. Vaya a la sección de
        <a routerLink="/payment/membership" class="text-primary"
          >Pagos y facturación</a
        >
      </div>

      <div clas="col-12" *ngIf="profile.is_member === 1">
        2. Entre a <strong>Pagos pendientes</strong>, elija pagar su Afiliación
        y anualidad del año en curso
      </div>

      <div clas="col-12" *ngIf="profile.is_member === 1">
        3. <strong>Pague y facture</strong> el mismo día
      </div>
    </div>
  </div>

  <div clas="col-12 mt-4 mt-lg-5" *ngIf="siteService.site === 'ammom'">
    <div class="row" *ngIf="profile.is_member === 0">
      <div class="col-12 mb-4">
        <h3 class="mb-0">Siguientes pasos:</h3>
      </div>

      <div class="col-12">
        1. Vaya a su
        <a routerLink="/account/info" class="text-primary">Cuenta</a> si
        requiere modificar sus datos
      </div>
    </div>

    <div class="row" *ngIf="profile.is_member !== 0">
      <div class="col-12">
        <p>
          Revisaremos sus datos y documentos probatorios. En caso de que falte
          información le notificaremos por correo electrónico, una vez aceptado
          será notificado por el mismo medio y activaremos su pago en la sección
          <a routerLink="/payment/membership" class="text-primary"
            >Pagos y facturación</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>
