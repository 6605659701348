import { Component } from '@angular/core';
import { AlertModal, AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-amm-form',
  templateUrl: './amm-form.component.html',
  styleUrls: ['./amm-form.component.scss'],
})
export class AmmFormComponent {
  alertData: AlertModal = new AlertModal();
  endpoint: string = '';
  method: string = 'put';
  loading: boolean = true;
  data: any = {};

  constructor(protected api: ApiService) {
    this.settings();
  }

  settings() {}

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit(optionalParams?: any) {
    if (this.loading || !this.isValid()) return;

    this.loading = true;

    const params = optionalParams ? optionalParams : this.getParams();

    this.api.callReq(
      { service: this.endpoint, method: this.method, params: params },
      (response) => {
        this.success(response);
      },
      (error) => {
        this.failed(error);
      }
    );
  }

  success(response: any) {
    this.alertData.data = {
      content: '¡Cambios guardados correctamente!',
      className: AlertType.Success,
    };

    this.loading = false;
  }

  failed(response: any) {
    this.alertData.data = {
      title: '¡Error!',
      content: response,
      className: AlertType.Danger,
    };

    this.loading = false;
  }

  handleAlert(
    msg: string,
    title: string = '',
    type: AlertType = AlertType.Danger
  ) {
    this.alertData.data = {
      title: title,
      content: msg,
      className: type,
    };
  }
}
