import { CongressCourse } from './CongressCourse.model';

export class Congress {
  id: number = -1;
  created_at: string | null = null;
  current: number = 0;
  deleted_at: string | null = null;
  description: string = '';
  end_date: string | null = null;
  extra_participant_price: number = 0;
  image_badge: string | null = null;
  image_register: string | null = null;
  hotel_date: string | null = null;
  name: string = '';
  register: number = -1;
  reservation_end_date: string | null = null;
  reservation_start_date: string | null = null;
  start_date: string | null = null;
  subscription_id: number = -1;
  type: string = '';
  courses: CongressCourse[] = [];
  updated_at: string | null = null;
  url_prices: string | null = null;
  url: string | null = null;
  works_max: number = 0;
  works: number = 0;
}
