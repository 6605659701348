import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertModal, AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
})
export class PasswordModalComponent {
  @Output() close = new EventEmitter();

  alertData: AlertModal = new AlertModal();
  form: FormGroup;
  loading: boolean = false;
  success: boolean = false;
  environment: any = environment;
  site: SiteEnum = SiteEnum.Amm;

  // public activeModal: NgbActiveModal,
  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private siteService: SiteService
  ) {
    this.site = this.siteService.site;

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  closeModal() {
    this.close.emit();
  }

  isValid(name: string, errors: [] = []) {
    let control = this.form.get(name);
    let error = false;

    if (!control) {
      return true;
    }

    if (errors.length > 0 && control.errors) {
      for (var p in errors) {
        if (control.errors[errors[p]] == true) {
          error = true;
          break;
        }
      }
    } else {
      error = control.invalid;
    }

    return error && (control.dirty || control.touched);
  }

  submitForm() {
    if (this.loading) return;

    if (!this.form.valid) {
      return;
    }

    this.loading = true;

    const params = {
      email: this.form.get('email')?.value,
    };

    this.api.callReq(
      { service: 'portal/recover', method: 'post', params: params },
      (response) => {
        this.alertData.data = {
          title: '¡Exito!',
          content: response.message,
          className: AlertType.Success,
        };

        this.form.reset();

        this.success = true;
        this.loading = false;
      },
      (error) => {
        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      }
    );
  }
}
