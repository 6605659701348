<app-amm-modal title="INFORMACIÓN NECESARIA" (close)="closeModal()">
  <div modalContent>
    <div
      class="row py-3 m-0 modal-image text-white"
      [style.background-image]="
        'url(/assets/images/horizontal_' + environment.key + '.jpg)'
      "
    >
      <div class="col-12 p-0 text-center">
        <img
          [src]="'images/logo_header.png' | siteAsset : site"
          alt="Logo {{ environment.association.name }}"
          width="80"
        />
      </div>

      <div class="col-12 mt-3 text-center">
        {{ environment.association.short_name }}
      </div>
    </div>

    <div class="container px-2 py-3 m-0" [ngClass]="{'loading': loading}">
      <app-loading-spinner
        class="loading-spinner"
        [loading]="loading"
      ></app-loading-spinner>
      <div class="row w-100 m-0">
        <div class="col-12">
          <!-- {{
            data.membership_id === 1 ? "USUARIO (no Socio)" : "SOCIO"
          }} -->
          <p>
            Estimado
            <strong>USUARIO</strong>, para lograr brindarle una mejor
            experiencia, seleccione los datos requeridos:
          </p>
        </div>

        <div class="col-12 mb-4" *ngIf="specialityError">
          <label for="speciality" class="mb-2">Especialidad</label>

          <span class="required">
            <ng-select
              [items]="specialities"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="specialityId"
              [clearable]="false"
              placeholder="Elegir"
              class="w-100"
              id="speciality"
            ></ng-select>
          </span>

          
        </div>

        <div class="col-12 mb-4" *ngIf="membershipError">
          <label for="profile" class="mb-2">Perfil de usuario</label>

          <span class="required">
            <ng-select
              [items]="memberships"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="membershipId"
              [clearable]="false"
              placeholder="Elegir"
              class="w-100"
              id="profile"
            ></ng-select>
          </span>
        </div>

        <app-alert [data]="alertData"></app-alert>

        <div class="col-6 col-md-5 mx-auto mt-4" *ngIf="!success">
          <button
            class="btn btn-primary btn-amm"
            (click)="handleSave()"
            [disabled]="loading || success"
          >
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  </div>
</app-amm-modal>
