<div class="modal-header text-center w-100 {{ headerClass }}">
  <div class="row align-items-center position-relative w-100 h-100 m-0">
    <div class="col-10 mx-auto text-center m-0">
      <span class="fs-5">{{ title }}</span>
    </div>

    <h5 class="col-auto close-icon m-0">
      <i class="fa-solid fa-xmark clickable" (click)="closeModal()"></i>
    </h5>
  </div>
</div>

<div class="modal-body {{ modalBodyClass }}">
  <ng-content select="[modalContent]"></ng-content>
</div>

<div class="modal-footer" *ngIf="hasFooter">
  <ng-content select="[modalFooter]"></ng-content>
</div>
