<div class="row" (click)="changeType()">
  <div class="col-auto my-4" *ngIf="hasRadio">
    <input
      class="form-check-input"
      type="radio"
      name="mebers"
      [id]="'cardRadio' + data.id"
      [(ngModel)]="currentId"
      [value]="data.id"
    />
  </div>

  <div class="col">
    <div
      class="card p-2 p-md-3 card-hoverable clickable"
      [ngClass]="{ 'card-selected': data.id === currentId }"
    >
      <small
        class="text-info"
        [ngClass]="data.id === currentId ? 'show-data' : 'hide-data d-none'"
      >
        SU MEMBRESÍA
      </small>

      <h5 [ngClass]="{ 'text-primary': data.id === currentId }">
        {{ data.name }}
      </h5>

      <div class="text-justify font-light" [innerHTML]="data.description"></div>

      <div class="row">
        <div class="col-12 my-3 px-0">
          <button
            class="btn btn-link mx-auto w-auto text-decoration-none text-primary"
            (click)="handleCollapsable()"
            *ngIf="data.benefits && data.benefits.length > 0"
          >
            {{ isCollapsed ? "VER BENEFICIOS" : "OCULTAR BENEFICIOS" }}
          </button>
        </div>

        <div
          *ngIf="data.benefits"
          class="col-12 px-0"
          #collapse="ngbCollapse"
          [(ngbCollapse)]="isCollapsed"
        >
          <div class="card">
            <div class="card-body">
              <h5 class="text-primary">Beneficios</h5>

              <ul class="ps-4">
                <li class="mb-2" *ngFor="let benefit of data.benefits">
                  {{ benefit }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
