import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertModal, AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent {
  alertData: AlertModal = new AlertModal();
  confirmPassword: string = '';
  email: string = '';
  loading: boolean = true;
  password: string = '';
  success: boolean = false;
  token: string = '';
  timer: any = null;
  step: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private user: UserService
  ) {
    this.loading = false;

    this.route.queryParams.subscribe((param) => {
      if (param['token']) this.token = param['token'];
      if (param['email']) this.email = param['email'];

      if (param['token']) {
        this.step = 2;
      }
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  sendMail() {
    if (!this.email) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Ingresa un correo electrónico válido',
        className: AlertType.Danger,
      };

      return;
    }

    this.loading = true;

    const params = {
      email: this.email,
    };

    this.api.callReq(
      { service: 'portal/recover', method: 'post', params: params },
      (response) => {
        this.alertData.data = {
          title: '¡Exito!',
          content: response.message,
          className: AlertType.Success,
        };

        this.email = '';

        this.success = true;
        this.loading = false;
      },
      (error) => {
        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      }
    );
  }

  recover() {
    if (this.loading) return;

    this.alertData.active = false;

    if (this.step === 1) {
      this.sendMail();

      return;
    }

    if (this.password !== this.confirmPassword) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Las contraseñas no coinciden',
        className: AlertType.Danger,
      };

      return;
    }

    if (!this.token) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'No se ha encontrado el token',
        className: AlertType.Danger,
      };

      return;
    }

    this.loading = true;

    const params = {
      password: this.password,
      token: this.token,
    };

    this.api.callReq(
      { service: 'portal/reset', method: 'post', params: params },
      (response) => {
        this.alertData.data = {
          title: '¡Éxito!',
          content: response.message || 'Se ha cambiado la contraseña',
          className: AlertType.Success,
        };

        this.user.profile = { email: this.email };
        this.success = true;
        this.loading = false;

        this.timer = setTimeout(() => {
          this.router.navigate(['/login'], {
            replaceUrl: true,
          });
        }, 2300);
      },
      (error) => {
        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      }
    );
  }
}
