<div class="main-container">
  <div class="container d-flex flex-column h-100 py-4">
    <div class="row my-4">
      <div class="col-12 text-center">
        <img [src]="logoImage" alt="Logo amm" width="150" />
      </div>
    </div>

    <div class="row auth-container m-auto">
      <div class="col-lg-6 order-2 order-md-1">
        <div class="card no-border no-border-radius card-shadow h-100 py-5">
          <div class="row h-100 m-0 px-md-4" *ngIf="step === 0">
            <div
              class="col-12 mb-4"
              *ngFor="let user of usersType; index as ind"
            >
              <app-user-type-selector
                [title]="'Tipo de usuario'"
                [groupName]="'userType'"
                [data]="user"
                [currentRadio]="currentUserType"
                (radioChange)="changeUser($event)"
              ></app-user-type-selector>
            </div>

            <app-alert [data]="alertData"></app-alert>

            <div class="col-12 text-center mb-4">
              <label for="mail" class="form-label strong">
                <h5>Correo electrónico de su cuenta</h5>
              </label>

              <input
                type="email"
                class="form-control input-amm was-validated"
                name="email"
                id="email"
                placeholder="usuario@micorreo.com"
                [(ngModel)]="email"
                required
              />
            </div>

            <!-- <div class="col-12 my-4 text-center">
            <a class="amm-link" href="http://">
              ¿Olvidó su correo electrónico?
            </a>
          </div> -->

            <div class="col-12 mb-4">
              <button
                class="btn btn-primary btn-amm"
                (click)="checkMail()"
                [disabled]="loading || currentUserType === -1"
              >
                SIGUIENTE <i class="ms-2 fa-solid fa-arrow-right"></i>
              </button>
            </div>

            <div class="col-12 mb-2 text-muted text-center">
              ¿No tienes cuenta?
            </div>

            <div class="col-12">
              <button (click)="register()" class="btn btn-outline-primary">
                CREAR NUEVA CUENTA <i class="ms-2 fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          <div class="row h-100 mx-0 my-5 px-4 text-center" *ngIf="step === 1">
            <div class="col-12 mb-5">
              <h6>
                Acceso como
                {{ currentUserType === 1 ? "Socio" : "No Socio" }}
                {{ environment.association.short_name }}
              </h6>
            </div>

            <div class="col-12 mb-3">
              <strong>Correo:</strong>
            </div>

            <div class="col-12 text-muted">
              {{ email }}
            </div>

            <div class="col-12 my-4">
              <app-alert [data]="alertData"></app-alert>
            </div>

            <div class="col-12 text-center mb-5">
              <label for="mail" class="form-label strong">
                <h6>Contraseña</h6>
              </label>
              <input
                type="password"
                class="form-control input-amm"
                id="pass"
                placeholder="Contraseña"
                [(ngModel)]="password"
              />
            </div>

            <div class="col-12 mb-5">
              <button
                class="btn btn-link amm-link"
                (click)="openModal(passwordModal)"
              >
                ¿Olvidó su contraseña?
              </button>
            </div>

            <div class="col-md-6 mb-3 mb-md-0">
              <button class="btn btn-outline-dark" (click)="changeMail()">
                REGRESAR
              </button>
            </div>

            <div class="col-md-6 mb-3 mb-md-0">
              <button class="btn btn-primary btn-amm" (click)="login()">
                INGRESAR <i class="ms-2 fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 order-1 order-md-2">
        <div
          class="row m-0 text-light py-5 px-4 text-center"
          [ngClass]="site + '-login'"
        >
          <div class="col-12">
            <h3>{{ environment.login.title }}</h3>
          </div>

          <div class="col-12">
            <p>
              {{ environment.login.description }}
            </p>
          </div>

          <div class="col-12 mt-auto">
            <a
              class="btn btn-outline-light mb-4"
              href="https://{{ environment.domain }}/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SITIO DE LA ASOCIACIÓN

              <i class="fa-regular fa-arrow-right-long ms-2"></i>
            </a>
          </div>

          <div class="col-12" *ngIf="environment.congress_domain">
            <a
              class="btn btn-outline-light"
              href="http://{{
                environment.congress_domain +
                  (site === 'ammom' ? '/congreso-anual' : '')
              }}"
              target="_blank"
              rel="noopener noreferrer"
            >
              SITIO CONGRESO 2024

              <i class="fa-regular fa-arrow-right-long ms-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row text-center mt-auto py-4">
      <div class="col-md">{{ environment.association.legal }}</div>

      <div class="col-md">
        <a class="amm-link" href="mailto:{{ support }}"> {{ support }}</a>
      </div>

      <div class="col-md">
        <a
          class="amm-link"
          href="http://{{ environment.domain }}"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ environment.domain }}
        </a>
      </div>

      <div class="col-md">
        <a href="" routerLink="/terms" class="amm-link">
          Aviso de Privacidad
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #passwordModal>
  <app-password-modal (close)="closeModal()"></app-password-modal>
</ng-template>
