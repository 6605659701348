import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SiteEnum } from '../enums/Site.enum';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  site: SiteEnum = SiteEnum.Amm;

  constructor() {
    this.site = environment.site;
  }
}
