export class Profile {
  avatar_url: any = null;
  birthdate: string = '';
  city: string = '';
  country: string | null = 'México';
  debit: number = 0;
  email: string = '';
  external_house_number: string = '';
  gender: string | null = null;
  id: number = -1;
  identification: any = null;
  internal_house_number: any = null;
  is_member: number = 0;
  job_title: string | null = null;
  last_name: string = '';
  legals: number = 0;
  membership_id: number = 0;
  municipality: string = '';
  name: string = '';
  neighborhood: string = '';
  newsletter: number = 0;
  phone_number: string = '';
  phone_number2: string = '';
  professional_number: string = '';
  profile: any = {};
  public: number = 0;
  recommendations: string[] | null = null;
  speciality_id: number | null = null;
  state_id: any = null;
  state: string | null = null;
  street: string = '';
  upgradable: number = 1;
  vat_address: any = null;
  vat_name: any = null;
  vat_number: any = null;
  vat_constancy: any = null;
  vat_type: any = null;
  zip_code: string = '';

  catalogs_cfdi_usage_id: number = 0;
  catalogs_regimen_id: number = 0;

  getJSON() {
    const { profile, ...data } = this;

    const myJSON = JSON.stringify(data);
    return myJSON;
  }

  getRegisterJSON(partner: boolean = true) {
    const params: any = {};

    // INFO
    params['name'] = this.name;
    params['last_name'] = this.last_name;
    params['job_title'] = this.job_title;

    // PROFESSIONAL
    params['speciality_id'] = this.speciality_id;

    // CONTACT
    params['phone_number'] = this.phone_number;

    if (partner) {
      // INFO
      params['gender'] = this.gender;
      params['birthdate'] = this.birthdate;

      // PROFESSIONAL
      params['identification'] = this.professional_number;

      // CONTACT
      params['phone_number2'] = this.phone_number2;

      // ADDRESS
      params['street'] = this.street;
      params['external_house_number'] = this.external_house_number;
      params['internal_house_number'] = this.internal_house_number;
      params['neighborhood'] = this.neighborhood;
      params['municipality'] = this.municipality;
      params['zip_code'] = this.zip_code;
      params['state'] = this.state;
      params['country'] = this.country;
      params['city'] = this.city;
    }

    return params;
  }

  isValid(): boolean {
    const { identification, phone_number2, internal_house_number, ...data } =
      this.getRegisterJSON();
    const values = Object.values(data);

    return values.every((value) => value && String(value).length > 0);
  }

  isValidRegister(partner: boolean = false): boolean {
    const data = this.getRegisterJSON(partner);
    // console.log(data);

    let valid = Object.values(data).every(
      (value) => value && String(value).length > 0
    );

    if (partner) {
      valid =
        valid &&
        this.speciality_id !== null &&
        typeof this.speciality_id === 'number';

      // console.log('partner ', valid);
    }

    // console.log(`register valid: ${valid}`);

    return valid;
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }

      if (data.identification) {
        this.professional_number = data.identification;
      }
    }

    if (!this.avatar_url || this.avatar_url === '') {
      this.avatar_url = 'assets/images/avatar.png';
    }
  }

  setProfile(profile: any) {
    Object.assign(this, profile);

    if (!this.avatar_url || this.avatar_url === '') {
      this.avatar_url = 'assets/images/avatar.png';
    }
  }
  //   {
  //     "name" : "D",
  //     "last_name" : "F",
  //     "job_title" : "Dr.",
  //     "gender" : "Male",
  //     "speciality_id" : 2,
  //     "street" : "Test",
  //     "external_house_number" : "12",
  //     "internal_house_number" : "",
  //     "neighborhood" : "Test",
  //     "municipality" : "Test",
  //     "zip_code" : "12345",
  //     "state" : "Colima",
  //     "country" : "Mexico"
  // }
  getProfileToSave() {
    return {
      birthdate: this.birthdate,
      city: this.city,
      country: this.country,
      external_house_number: this.external_house_number,
      gender: this.gender,
      internal_house_number: this.internal_house_number,
      job_title: this.job_title,
      last_name: this.last_name,
      municipality: this.municipality,
      name: this.name,
      neighborhood: this.neighborhood,
      professional_number: this.professional_number,
      speciality_id: this.speciality_id,
      state_id: this.state_id,
      state: this.state,
      street: this.street,
      zip_code: this.zip_code,
      // CONTACT
      phone_number: this.phone_number,
      phone_number2: this.phone_number2,
    };
  }
}
