<app-amm-modal (close)="cancel()" [title]="title" [hasFooter]="true">
  <div class="row py-5 m-0" modalContent>
    <div class="col-12 mb-4">
      <p [innerHTML]="message"></p>
    </div>
  </div>

  <div class="row w-100" modalFooter>
    <div class="mx-auto" [ngClass]="btnConfirmText ? 'col-6' : 'col-8 '">
      <button class="btn btn-primary btn-outline-primary" (click)="cancel()">
        {{ btnText }}
      </button>
    </div>

    <div class="col-6" *ngIf="btnConfirmText">
      <button class="btn btn-primary btn-amm" (click)="confirm()">
        {{ btnConfirmText }}
      </button>
    </div>
  </div>
</app-amm-modal>
