import { Pipe, PipeTransform } from '@angular/core';
import { SiteEnum } from '../../enums/Site.enum';

@Pipe({
  name: 'siteAsset',
})
export class SitePipe implements PipeTransform {
  transform(assets: string, site: SiteEnum): string {
    if (site === SiteEnum.Amm) {
      return `assets/${assets}`;
    }

    return `assets/${site}/${assets}`;
  }
}
