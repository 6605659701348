<!-- d-none d-lg-inline-block -->
<div class="row pt-4" *ngIf="isMobile">
  <div class="col-auto">
    <button class="btn btn-light border-dark-subtle" (click)="open(content)">
      <i class="fa-solid fa-bars fa-xl me-2"></i>

      <span class="fw-normal">Menú</span>
    </button>
  </div>
</div>

<div class="sidebar" *ngIf="!isMobile">
  <ng-content select="[sideContent]"></ng-content>

  <div class="row">
    <div class="col-12">
      <h4
        class="text-primary"
        [ngClass]="{ clickable: titleLink }"
        [routerLink]="titleLink"
        [innerHTML]="title"
      ></h4>
    </div>

    <div class="col-12" *ngFor="let item of items; index as ind">
      <button
        class="sidebar-item"
        [ngClass]="item.separator ? 'mt-3' : 'my-3'"
        [disabled]="item.disabled"
        (click)="handleActive(item, ind)"
        *ngIf="item.disabled; else noDisabled"
      >
        <!-- (ind + 1) > step -->
        <div class="d-flex">
          <span class="text-disabled">
            <span [innerHTML]="item.name"></span>

            <small class="text-primary ms-2" *ngIf="!item.unable"
              >PRÓXIMAMENTE</small
            >
          </span>
        </div>
      </button>

      <ng-template #noDisabled>
        <a
          *ngIf="item.isFull"
          class="sidebar-item amm-link d-block py-3"
          [href]="item.isFull"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span [innerHTML]="item.name"></span>
        </a>

        <button
          class="sidebar-item"
          [ngClass]="item.separator ? 'mt-3' : 'my-3'"
          (click)="download(item.download)"
          *ngIf="item.download"
        >
          <span [innerHTML]="item.name"></span>

          <span class="ms-2">
            <i class="fa-solid fa-download fa-sm"></i>
          </span>
        </button>

        <button
          class="sidebar-item"
          routerLinkActive="text-primary"
          [ngClass]="item.separator ? 'mt-3' : 'my-3'"
          [routerLink]="item.link"
          [routerLinkActiveOptions]="{ exact: true }"
          [disabled]="!isLogged && !enables.includes(ind + 1)"
          (click)="handleActive(item, ind)"
          *ngIf="!item.isFull && !item.download"
        >
          <!-- (ind + 1) > step -->
          <div class="d-flex">
            <span [innerHTML]="item.name"></span>

            <span class="ms-2">
              <i
                class="fa-solid"
                *ngIf="item.children"
                [ngClass]="
                  ind === activeChild ? 'fa-chevron-up' : 'fa-chevron-down'
                "
              ></i>

              <strong *ngIf="!item.children">
                <i class="fa-solid fa-chevron-right fa-sm"></i>
              </strong>
            </span>
          </div>
        </button>

        <div
          #collapse="ngbCollapse"
          [ngbCollapse]="ind !== activeChild"
          *ngIf="item.children"
        >
          <ul class="accordion-list" style="list-style: none">
            <li *ngFor="let subitem of item.children">
              <div class="w-100">
                <button
                  [routerLink]="subitem.link"
                  routerLinkActive="text-primary"
                  class="sidebar-item"
                  *ngIf="!subitem.download"
                >
                  <span [innerHTML]="subitem.name"></span>
                </button>

                <button
                  class="sidebar-item"
                  (click)="download(subitem.download)"
                  *ngIf="subitem.download"
                >
                  <span [innerHTML]="subitem.name"></span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>

      <hr class="my-4 border-sidebar" *ngIf="item.separator" />
    </div>
  </div>
</div>

<ng-template #content>
  <div class="offcanvas-header px-4 pb-0">
    <h4
      class="offcanvas-title text-primary"
      [ngClass]="{ clickable: titleLink }"
      [routerLink]="titleLink"
      [innerHTML]="title"
    ></h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>

  <div class="offcanvas-body">
    <ng-content select="[sideContentM]"></ng-content>

    <div class="row">
      <div class="col-12" *ngFor="let item of items; index as ind">
        <button
          class="sidebar-item"
          [ngClass]="item.separator ? 'mt-3' : 'my-3'"
          [disabled]="item.disabled"
          (click)="handleActive(item, ind)"
          *ngIf="item.disabled; else mDisabled"
        >
          <!-- (ind + 1) > step -->
          <div class="d-flex">
            <span class="text-disabled">
              <span [innerHTML]="item.name"></span>

              <small class="text-primary ms-2" *ngIf="!item.unable"
                >PRÓXIMAMENTE</small
              >
            </span>
          </div>
        </button>

        <ng-template #mDisabled>
          <a
            *ngIf="item.isFull"
            class="sidebar-item amm-link d-block py-3"
            [href]="item.isFull"
            target="_blank"
            rel="noopener noreferrer"
            (click)="close()"
          >
            <span [innerHTML]="item.name"></span>
          </a>

          <button
            class="sidebar-item"
            [ngClass]="item.separator ? 'mt-3' : 'my-3'"
            (click)="download(item.download)"
            *ngIf="item.download"
          >
            <span [innerHTML]="item.name"></span>

            <span class="ms-2">
              <i class="fa-solid fa-download fa-sm"></i>
            </span>
          </button>

          <button
            class="sidebar-item"
            routerLinkActive="text-primary"
            [ngClass]="item.separator ? 'mt-3' : 'my-3'"
            [routerLink]="item.link"
            [routerLinkActiveOptions]="{ exact: true }"
            [disabled]="!isLogged && !enables.includes(ind + 1)"
            (click)="handleActive(item, ind); close()"
            *ngIf="!item.isFull && !item.download"
          >
            <!-- (ind + 1) > step -->
            <div class="d-flex">
              <span [innerHTML]="item.name"></span>

              <span class="ms-2">
                <i
                  class="fa-solid"
                  *ngIf="item.children"
                  [ngClass]="
                    ind === activeChild ? 'fa-chevron-up' : 'fa-chevron-down'
                  "
                ></i>
  
                <strong *ngIf="!item.children">
                  <i class="fa-solid fa-chevron-right fa-sm"></i>
                </strong>
              </span>
            </div>
          </button>

          <div
            #collapse="ngbCollapse"
            [ngbCollapse]="ind !== activeChild"
            *ngIf="item.children"
          >
            <ul class="accordion-list" style="list-style: none">
              <li *ngFor="let subitem of item.children">
                <div class="w-100">
                  <button
                    [routerLink]="subitem.link"
                    routerLinkActive="text-primary"
                    class="sidebar-item"
                    *ngIf="!subitem.download"
                    (click)="close()"
                  >
                    <span [innerHTML]="subitem.name"></span>
                  </button>

                  <button
                    class="sidebar-item"
                    (click)="download(subitem.download)"
                    *ngIf="subitem.download"
                  >
                    <span [innerHTML]="subitem.name"></span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </ng-template>
        
        <hr class="my-4 border-sidebar" *ngIf="item.separator" />
      </div>
    </div>
  </div>
</ng-template>
