export enum AlertType {
  Primary = 'border-primary text-primary',
  Secondary = 'border-secondary text-secondary',
  Success = 'border-success text-success',
  Danger = 'border-danger text-danger',
  Warnings = 'border-warning text-warning',
  Info = 'border-info text-info',
  Light = 'border-light text-light',
  Dark = 'border-dark text-dark',
  Pink = 'border-amm border-pink text-pink',
}

interface AlertData {
  active?: boolean;
  className: AlertType;
  content: string | string[];
  title?: string;
}

export class AlertModal {
  active: boolean = false;
  title: string = '';
  className: AlertType = AlertType.Danger;
  content: string | string[] = '';
  timer: any;

  set data(data: AlertData) {
    this.active = false;
    this.title = data.title ?? '';
    this.className = data.className;
    this.content = data.content;

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.setActive(data.active ?? true);
    }, 100);
  }

  setActive(active: boolean) {
    if (!active) {
      this.className = AlertType.Danger;
    }

    this.active = active;
  }
}
