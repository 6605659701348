export enum HomeEnum {
  Ammom = 'ammom',
  AmmomCongress = 'AmmomCongress',
  Congress = 'congress',
  Default = 'default',
  Evaluation = 'evaluation',
  Register = 'register',
  Somos = 'somos',
  AmmomWebcasts = 'ammom_webcasts',
}
