<div class="modal-header bg-primary text-center w-100">
  <div class="row m-0"></div>
  <h4 class="col-12 m-0">
    Datos de la compra
  </h4>
</div>

<div class="modal-body" [ngClass]="loading ? 'loading': '' ">
  <app-loading-spinner class="loading-spinner" [loading]="loading"></app-loading-spinner>

  <div class="container">
    <div class="row p-3">
      <div class="col-12 mb-4">
        <h5><span class="text-primary">Concepto:</span> {{ invoice.concept }}</h5>
      </div>

      <div class="col-12 col-md-6 mb-4">
        <strong class="text-primary">Fecha:</strong> {{ invoice.created_at | shortdate }}
      </div>

      <div class="col-12 col-md-6 mb-4">
        <strong class="text-primary">Monto:</strong> {{ invoice.amount | currency }}
      </div>

      <div class="col-12 mb-4" *ngIf="!success">
        <strong class="text-danger">Error: </strong>
        <p>
          {{invoice.error}}
        </p>
      </div>

      <app-alert [data]="alertData"></app-alert>

      <hr class="col-12 mb-4">

      <div class="col-12 mb-4">
        <app-bill-form [data]="invoiceData"></app-bill-form>
      </div>

      <div class="col-12 mb-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="saveData" [(ngModel)]="saveInvoice">
          <label class="form-check-label" for="saveData">
            Guardar Datos de Facturación
          </label>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="model-footer">
  <div class="container">

    <div class="row py-4">
      <div class="col-md-5 mx-auto mt-4">
        <button ngbAutofocus class="btn btn-outline-dark" (click)="closeModal()" [disabled]="loading">
          {{ success ? 'CERRAR' : 'CANCELAR' }}
        </button>
      </div>

      <div class="col-md-5 mx-auto mt-4" *ngIf="!success">
        <button class="btn btn-primary btn-amm" (click)="solveInvoice()" [disabled]="loading || success">
          ENVIAR
        </button>
      </div>
    </div>
  </div>
</div>