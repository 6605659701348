export enum TableColors {
  Pink = 'amm-table-pink',
  Purple = 'amm-table-purple',
  White = 'amm-table-white'
}

export class TableHeader {
  title: string = '';
  color: TableColors = TableColors.Purple;
}

export class TableData {
  content: string = '';
  currency?: boolean = false;
}

export class Table {
  headers: TableHeader[] = [];
  data: TableData[][] = [];
  footer : string = '';
}