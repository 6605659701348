<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="mt-4 mb-5 text-center">Información Socios {{ environment.association.name }}</h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Recomendaciones</h3>

      <p class="mb-0">2 Miembros titulares de la {{ environment.association.name }} que lo recomiendan</p>
    </div>

    <div class="col-12 mb-3">
      Socio 1

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.recomendations[0]"
          placeholder="Nombre completo"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Socio 2

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.recomendations[1]"
          placeholder="Nombre completo"
          validationdirective
        />
      </span>
    </div>
  </div>

  <div class="row my-4">
    <div class="col-12 align-items-center mt-4 mb-3">
      <small class="text-primary">
        <strong>* Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
