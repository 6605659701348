import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  getNights(arrivalDate: string, leaveDate: string) {
    if (!arrivalDate || !leaveDate) {
      return 0;
    }

    const date1: Date = new Date(arrivalDate);
    const date2: Date = new Date(leaveDate);

    if (date1.getTime() > date2.getTime()) {
      return -1;
    }

    const miliseconds: number = Math.abs(date2.getTime() - date1.getTime());
    const days: number = Math.ceil(miliseconds / (1000 * 3600 * 24));

    return days;
  }

  getCurrency(currency: number | string): string {
    const currencyPipe = new CurrencyPipe('en-US');
    const currencyFormatted = currencyPipe.transform(currency);

    return currencyFormatted ?? String(currency);
  }

  getArray(min: number, max: number): number[] {
    const array: number[] = [];

    for (let i: number = min; i <= max; i++) {
      array.push(Number(i));
    }

    return array;
  }

  getDateFormated(date: string): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(date, 'yyyy-MM-dd');
    return formattedDate ?? date;
  }

  // Return date from yyyy-MM-dd to dd/MM/yyyy
  getDateStringFormated(date: string): string {
    const parts = date.split('-');

    if (parts && parts.length >= 3) {
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return date;
  }

  isValidPassword(password: string) {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+\-={};:]{6,}$/;
    return passwordRegex.test(password);
  }

  isValidMail(email: string) {
    if (email === '') {
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
