<div class="main-container min-container" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="container py-5 mx-auto">
    <h2 class="text-center mb-4">Recuperar contraseña</h2>

    <form class="row p-2 p-md-3 m-0 text-center" *ngIf="step === 1">
      <div class="col-12 my-4">
        <p>
          Ingresa tu correo electrónico de socio y te enviaremos instrucciones
          para restablecer tu contraseña.
        </p>
      </div>

      <div class="col-12">
        <input
          type="text"
          class="form-control input-amm mb-3"
          id="email"
          name="email"
          placeholder="Email"
          [(ngModel)]="email"
        />
      </div>

      <div class="col-12 my-3">
        <small>
          Te recomendamos revisar tu carpeta de SPAM o correo no deseado y
          marcarlo como correo seguro, si tardas en recibir el mensaje.
        </small>
      </div>

      <app-alert [data]="alertData"></app-alert>

      <div class="col-12 col-md-6 mx-auto my-3 my-lg-4">
        <button
          class="btn btn-primary btn-amm"
          (click)="recover()"
          [disabled]="loading || success"
        >
          CONFIRMAR
        </button>
      </div>

      <div class="col-12 text-center mt-5">
        <a class="amm-link" routerLink="/login"> Iniciar sesión </a>
      </div>
    </form>

    <div class="row" *ngIf="step === 2">
      <div class="col-12 mb-5 text-center">
        <strong>
          Su contraseña debe ser de mínimo 6 caracteres, 1 número y una
          mayúscula.
        </strong>
      </div>

      <div class="col-12 mb-5">
        <input
          type="password"
          class="form-control input-amm"
          id="new-password"
          placeholder="Contraseña"
          [(ngModel)]="password"
          autocomplete="new-password"
        />
      </div>

      <div class="col-12 mb-5">
        <input
          type="password"
          class="form-control input-amm"
          id="confirm-password"
          placeholder="Confirmar contraseña"
          [(ngModel)]="confirmPassword"
          autocomplete="new-password"
        />
      </div>

      <app-alert [data]="alertData"></app-alert>

      <div class="col-12 col-md-6 mx-auto my-5">
        <button
          class="btn btn-primary btn-amm"
          (click)="recover()"
          [disabled]="loading || success"
        >
          CONFIRMAR
        </button>
      </div>

      <div class="col-12 text-center mt-5">
        <a class="amm-link" routerLink="/login"> Iniciar sesión </a>
      </div>
    </div>
  </div>
</div>
