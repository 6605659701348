import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsertType } from '@models/UserType';

@Component({
  selector: 'app-user-type-selector',
  templateUrl: './user-type-selector.component.html',
  styleUrls: ['./user-type-selector.component.scss'],
})
export class UserTypeSelectorComponent {
  @Input() data: UsertType = new UsertType();
  @Input() radioValue: string = '';
  @Input() groupName: string = '';
  @Input() currentRadio: number = -1;
  @Output() radioChange = new EventEmitter<number>();

  changeType() {
    this.radioChange.emit(this.data.id);
  }
}
