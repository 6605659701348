import { Document } from './Document.component';

export class DocumentForm {
  degreeFile: Document = new Document();
  professionalFile: Document = new Document();
  curricularFile?: Document = new Document();

  document_title?: string = '';
  document_card?: string = '';
  document_curricular?: string = '';

  getJson(): any {
    const params: any = {
      document_title: this.degreeFile,
      document_card: this.professionalFile,
    };

    if (this.curricularFile)
      params['document_curricular'] = this.curricularFile;

    return params;
  }

  isValid(): boolean {
    return (
      this.degreeFile.contents.length > 0 &&
      this.professionalFile.contents.length > 0
    );
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
