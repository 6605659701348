import { Component, Input } from '@angular/core';
import privacy from '../../../assets/data/privacy.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  @Input() isComponent: boolean = false;
  privacy: string = '';
  
  constructor() {
    let k = environment.key as string;
    this.privacy = privacy[k as keyof typeof privacy];
  }
}
