import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Profile } from '@models/Profile.model';
import { STATES } from '../../pages/info/Data';
import { ApiService } from '@services/api.service';
import { ValidationDirective } from '@shared/directives/validation.directive';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  states: string[] = [...STATES];
  countries: string[] = [];

  @Input() requireds: boolean[] = [ true, true, false, true, true, true, true, true, true ];
  @Input() data: Profile = new Profile();
  @Input() hasTitle: boolean = true;

  // For input validation ref
  @ViewChildren(ValidationDirective)
  inputsDirectives!: QueryList<ValidationDirective>;

  constructor(private api: ApiService) {
    this.api.callReq(
      {
        service: 'contact/countries',
        method: 'get',
        params: {},
      },
      (response) => {
        this.countries = response.data;
      },
      (error) => {
        console.error(error);
        this.countries = [];
      }
    );
  }

  validate() {
    this.inputsDirectives.forEach((directive: ValidationDirective) => {
      directive.onBlur();
    });
  }
}
