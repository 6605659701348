<div class="sidebar-container">
  <app-sidebar
    [title]="'Pagos y facturación'"
    [items]="sidebarItems"
  ></app-sidebar>

  <div class="sidebar-content">
    <div class="app-content">
      <div class="content-container mx-auto px-3 my-3 my-lg-4 py-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
