export const PAYMENT_METHODS = [
  {
    id: '28',
    description: 'Tarjeta de débito',
  },
  {
    id: '4',
    description: 'Tarjeta de crédito',
  },
];
