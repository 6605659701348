import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, AlertType } from '@models/Alert.model';
import { Invoice, InvoiceDATA } from '@models/Invoice.model';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-solve-invoice',
  templateUrl: './solve-invoice.component.html',
  styleUrls: ['./solve-invoice.component.scss']
})
export class SolveInvoiceComponent {
  alertData: AlertModal = new AlertModal();
  loading: boolean = false;
  success: boolean = false;
  invoiceData: InvoiceDATA = new InvoiceDATA();
  saveInvoice: boolean = false;

  @Input() invoice: Invoice = new Invoice();

  constructor(private api: ApiService, public activeModal: NgbActiveModal) { }

  // {{url}}api/portal/invoices/2732
  solveInvoice() {
    if (this.loading) return;

    if (!this.invoiceData.isValid()) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Todos los campos son requeridos.',
        className: AlertType.Danger
      }

      return;
    }

    this.alertData.setActive(false);
    this.loading = true;

    const params = {
      "save_invoicing": this.saveInvoice ? 1 : 0,
      "email": this.invoiceData.email,
      "invoicing": {
        "vat_name": this.invoiceData.vat_name,
        "vat_number": this.invoiceData.vat_number,
        "vat_address": this.invoiceData.vat_address,
        "catalogs_cfdi_usage_id": this.invoiceData.catalogs_cfdi_usage_id,
        "catalogs_regimen_id": this.invoiceData.catalogs_regimen_id,
        "payment_method": this.invoiceData.payment_method,
      }
    }

    this.api.callReq(
      { service: `portal/invoices/${this.invoice.id}`, method: 'post', params: params },
      (response) => {
        if (response.data.invoice.status === 'completed') {
          this.alertData.data = {
            title: '¡Exito!',
            content: 'Factura generada con exito',
            className: AlertType.Success
          }

          this.success = true;
        } else {
          this.alertData.data = {
            title: '¡Error!',
            content: `Ocurrio un error: ${response.data.invoice.error ?? ''}.`,
            className: AlertType.Danger
          }
        }

        this.loading = false;
      },
      (error) => {
        this.alertData.data = {
          title: '¡Error!',
          content: 'Ocurrio un error al guardar los datos.',
          className: AlertType.Danger
        }

        console.error(error);
        this.loading = false;
      }
    )
  }

  closeModal() {
    if (this.loading) return;
    this.activeModal.close(true);
  }

}
