<div class="medium-container mx-auto">
  <div class="row w-100 m-0 mt-5">
    <h3 class="col-12 text-center p-0">Registro a Sistema {{ environment.association.short_name }}</h3>
  
    <div class="col-10 mx-auto p-0">
      <div class="alert-component my-4 border-primary p-3 {{alertData.className}}">
        <span [innerHTML]="alertData.content"></span>
      </div>
    </div>
  
    <div class="col-10 mx-auto p-0 text-center text-primary">
      <h5>{{step}} - {{total}}</h5>
    </div>
  </div>
</div>