<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="mt-4 mb-5 text-center">Información Socios {{ environment.association.name }}</h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Otros grados académicos</h3>
    </div>

    <div class="col-12">
      <label for="">Grado</label>
      <span class="mt-2">
        <ng-select
          [items]="degrees"
          [(ngModel)]="data.grade_college"
          [clearable]="false"
          placeholder="Seleccione Grado"
        ></ng-select>
      </span>
    </div>

    <div class="col-12">
      <label for="">Especialidad</label>

      <span class="mt-2">
        <ng-select
          [items]="specialities"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="data.grade_speciality"
          [clearable]="false"
          placeholder="Elija especialidad"
        ></ng-select>
      </span>

      <p class="mt-2" *ngIf="site === 'ammom'">
        <span class="text-warning">*</span> Si usted no encuentra su
        especialidad favor de contactar a AMMOM (<a
          href="mailto:educacion@ammom.mx"
          >educacion@ammom.mx</a
        >)
      </p>
    </div>

    <div class="col-12">
      Institución académica

      <span class="mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.grade_name"
          placeholder="Nombre"
        />
      </span>
    </div>

    <div class="col-12">
      Fecha de inicio

      <span class="mt-2">
        <app-date-selector
          id="date-start"
          name="date-start"
          class="w-100"
          [data]="data.grade_start_date"
          (onChange)="data.grade_start_date = $event"
        ></app-date-selector>
      </span>
    </div>

    <div class="col-12">
      Fecha de finalización
      <span class="mt-2">
        <app-date-selector
          id="date-start"
          name="date-start"
          class="w-100"
          [data]="data.grade_end_date"
          (onChange)="data.grade_end_date = $event"
        ></app-date-selector>
      </span>
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <small class="text-primary">
        <strong>* Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
