import { Component } from '@angular/core';
import { AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { STATES } from '../info/Data';
import { InstituteInfo } from '@models/Institute.model';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { Memebership } from '@models/Membership.model';
import { Router } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { environment } from 'src/environments/environment';
import { SiteEnum } from '@enums/Site.enum';
import { SiteService } from '@services/site.service';

@Component({
  selector: 'app-institutional-information',
  templateUrl: './institutional-information.component.html',
  styleUrls: ['./institutional-information.component.scss'],
})
export class InstitutionalInformationComponent extends AmmFormComponent {
  override data: InstituteInfo = new InstituteInfo();
  countries: string[] = [];
  specialities: any[] = [];
  states: string[] = [...STATES];
  membership: Memebership = new Memebership();
  environment: any = environment;
  site: SiteEnum = SiteEnum.Amm;

  constructor(
    api: ApiService,
    private user: UserService,
    private router: Router,
    private utils: UtilsService,
    private siteService: SiteService
  ) {
    super(api);
  }

  ngOnInit(): void {
    this.endpoint = 'portal/register';
    this.method = 'post';
    this.getRegisterData();
    this.site = this.siteService.site;
    this.loading = false;
  }

  getRegisterData() {
    const data = this.user.getRegister('STEP5');

    if (this.user.getRegister('MAINMEMBER')) {
      this.membership = this.user.getRegister('MAINMEMBER');
    } else {
      this.membership = this.user.getMembership();
    }

    if (data) this.data.setData(data);
  }

  saveDataRegister() {
    const data = {
      key: 'STEP5',
      value: this.data.getRegister(
        this.site !== 'ammom',
        this.site !== 'ammom'
      ),
    };

    this.user.setRegister(data);
  }

  override success(response: any): void {
    this.autoLogin();
  }

  override failed(error: any): void {
    console.error(error);
    this.alertData.data = {
      title: '¡Error!',
      content: error,
      className: AlertType.Danger,
    };

    this.loading = false;
  }

  override getParams(): {} {
    const registerData = this.user.getRegister();
    const params = Object.assign(
      {},
      registerData['STEP1'],
      registerData['STEP2'],
      registerData['STEP3'],
      registerData['STEP4'],
      registerData['STEP5']
    );

    return params ?? {};
  }

  override isValid(): boolean {
    if (!this.data.isValid()) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena los campos requeridos *',
        className: AlertType.Danger,
      };

      return false;
    }

    if (!this.utils.isValidMail(this.data.institute_email)) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Ingresa un correo institucional válido',
        className: AlertType.Danger,
      };

      return false;
    }

    return true;
  }

  saveChanges() {
    if (this.loading || !this.isValid) return;

    this.saveDataRegister();

    this.submit();
  }

  autoLogin() {
    const data = this.user.getRegister('STEP1');

    this.loading = true;

    if (data) {
      const email = data.email;
      const password = data.password;

      this.api.callReq(
        {
          service: 'portal/login',
          method: 'post',
          params: { email: email, password: password },
        },
        (response: any) => {
          this.user.token = response.data.token;
          this.user.profile = response.data.user;
          this.user.cleanRegister();
          this.router.navigate(['register/payment']);
        },
        (error: any) => {
          this.alertData.data = {
            title: '¡Error!',
            content: error,
            className: AlertType.Danger,
          };
          this.loading = false;
        }
      );
    } else {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Ocurrio un error revisa tus datos.',
        className: AlertType.Danger,
      };

      this.loading = false;
    }
  }
}
