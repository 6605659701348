import { Component } from '@angular/core';
import { AlertType } from '@models/Alert.model';
import { MedicalEducation } from '@models/MedicalEducation.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-medical-education',
  templateUrl: './medical-education.component.html',
  styleUrls: ['./medical-education.component.scss'],
})
export class MedicalEducationComponent extends AmmFormComponent {
  override data: MedicalEducation = new MedicalEducation();
  environment:any = environment;

  constructor(api: ApiService, private user: UserService) {
    super(api);
  }

  override settings(): void {
    this.endpoint = 'portal/me/profile/college';
    this.method = 'put';

    this.api.callReq(
      {
        service: 'portal/me',
        method: 'get',
        params: {},
      },
      (response) => {
        if (response.status === 200 && response.data.profile) {
          this.data.setData(response.data.profile);
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  override isValid(): boolean {
    const valid = this.data.isValid();

    if (!valid) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena los campos requeridos *',
        className: AlertType.Danger,
      };
    }

    return valid;
  }

  override getParams() {
    return this.data.getJson();
  }
}
