import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AlertType } from '@models/Alert.model';
import { SpecialityEducation } from '@models/SpecialityEducation.model';
import { ApiService } from '@services/api.service';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-specialty-education',
  templateUrl: './specialty-education.component.html',
  styleUrls: ['./specialty-education.component.scss'],
})
export class SpecialtyEducationComponent extends AmmFormComponent {
  specialities: any[] = [];
  environment:any = environment;
  override data: SpecialityEducation = new SpecialityEducation();

  constructor(api: ApiService) {
    super(api);
  }

  override settings(): void {
    this.endpoint = 'portal/me/profile/speciality';
    this.method = 'put';
    
    const services = [this.api.get('specialities'), this.api.call('portal/me')];

    forkJoin(services).subscribe({
      next: ([specialityRes, profileRes]) => {
        if (specialityRes) {
          this.specialities = specialityRes;
        }

        if (profileRes.status === 200 && profileRes.data.profile) {
          this.data.setData(profileRes.data.profile);
        }

        this.loading = false;
      },
    });
  }

  override isValid(): boolean {
    const valid = this.data.isValid();

    if (!valid) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena los campos requeridos *',
        className: AlertType.Danger,
      };
    }

    return valid;
  }

  override getParams() {
    return this.data.getJson();
  }
}
