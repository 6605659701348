<div class="container">
  <div class="row">
    <div class="col-12 my-5" *ngIf="!isComponent">
      <h3 class="text-primary text-center">Aviso de privacidad</h3>
    </div>

    <div class="col-12 pb-4" *ngIf="privacy">
      <div class="text-justify" [innerHTML]="privacy"></div>
    </div>
  </div>
</div>
