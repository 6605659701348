import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringify'
})
export class StringifyPipe implements PipeTransform {

  transform(key: unknown): string {
    if (key) {
      return String(key);
    }
    
    return '';
  }

}
