import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[maxdigits]',
})
export class MaxDigitsDirective {
  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'
  ];

  @Input('maxlength') max: number = 10;
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key == ' ') {
      event.preventDefault();
      return;
    }

    const inputValue: string = this.el.nativeElement.value;
    const next: string = event.key;
    const nextString = inputValue.concat(next).replace(/ /g, '');

    if (!this.isValid(nextString) || nextString.length > this.max) {
      event.preventDefault(); // Cancelar la acción de escritura
    }
  }

  private isValid(input: string): boolean {
    const numericRegex = /^[0-9]+$/; // Expresión regular para solo números
    return numericRegex.test(input);
  }
}
