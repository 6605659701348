import { Component } from '@angular/core';
import { STATES } from '../info/Data';
import { UserService } from '@services/user.service';
import { ApiService } from '@services/api.service';
import { forkJoin } from 'rxjs';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['./institucional.component.scss'],
})
export class InstitucionalComponent extends AmmFormComponent {
  states: string[] = [...STATES];
  countries: string[] = [];
  environment:any = environment;
  
  override data: any = {};

  constructor(api: ApiService, private user: UserService) {
    super(api);
  }

  override isValid(): boolean {
    if (
      !this.data.institute ||
      !this.data.department ||
      !this.data.institute_email
    ) {
      this.failed('Llena todos los campos requeridos');
      return false;
    }

    return true;
  }

  override getParams() {
    return this.data;
  }

  override settings(): void {
    this.endpoint = 'portal/me/profile/institute';
    this.method = 'put';

    const services = [
      this.api.call('contact/countries'),
      this.api.call('portal/me'),
    ];

    forkJoin(services).subscribe({
      next: ([countriesRes, profileRes]) => {
        if (countriesRes.status === 200) {
          this.countries = countriesRes.data;
        }

        if (profileRes.status === 200) {
          this.data = profileRes.data;
        }

        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
      },
    });
  }
}
