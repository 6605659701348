import { Directive } from '@angular/core';

@Directive({
  selector: '[appMaxAlphanumeric]'
})
export class MaxAlphanumericDirective {

  constructor() { }

}
