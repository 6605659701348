import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

class Variable {
  key: string = '';
  value: any;
}

const PKEY = environment.cookie + '_token';
@Injectable({
  providedIn: 'root',
})
export class Storage {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  getData(key: string): any | null {
    const data = this.storage.get(PKEY) ?? {};

    if (data[key]) {
      return data[key];
    }

    return null;
  }
  setData(key: string, value: any) {
    const data = this.storage.get(PKEY) ?? {};
    data[key] = value;
    this.storage.set(PKEY, data);
  }

  getChildData(key: string, childKey: string) {
    const data: any = this.getData(key);
    if (data) {
      return data[childKey] ?? null;
    }

    return null;
  }
  setChildData(key: string, child: Variable) {
    const data: any = this.getData(key) ?? {};

    data[child.key] = child.value;
    this.setData(key, data);
  }

  cleanStorage(key?: string): void {
    if (key) {
      this.storage.remove(key);
      return;
    }

    this.storage.remove(PKEY);
  }
}
