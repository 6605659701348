import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertComponent } from './components/alert/alert.component';
import { AmmFormComponent } from './components/amm-form/amm-form.component';
import { AmmModalComponent } from './components/amm-modal/amm-modal.component';
import {
  DatePipe,
  DateZeroPipe,
  ShortTime,
  TimeZeroPipe,
} from './pipes/date.pipe';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { AmmMessageComponent } from './components/amm-message/amm-message.component';
import { PaymentActionPipe, PaymentTypePipe } from './pipes/payment.pipe';
import { UserTypeSelectorComponent } from './components/user-type-selector/user-type-selector.component';
import { StringifyPipe } from './pipes/stringify.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { MaxAlphanumericDirective } from './directives/max-alphanumeric.directive';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { MaxDigitsDirective } from './directives/max-digits.directive';
import { AmmTableComponent } from './components/amm-table/amm-table.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { ValidationDirective } from './directives/validation.directive';
import { DateTimePipe } from './pipes/date-time.pipe';
import { AutoFocusDirective } from './directives/autofocus.directive';
import { TermsComponent } from '../pages/terms/terms.component';
import { HotelTermsComponent } from './components/hotel-terms/hotel-terms.component';
import { SitePipe } from './pipes/site.pipe';
import { SafePipe } from './pipes/safepipe.pipe';

@NgModule({
  declarations: [
    // Components
    AlertComponent,
    AmmFormComponent,
    AmmMessageComponent,
    AmmModalComponent,
    AmmTableComponent,
    DateSelectorComponent,
    HotelTermsComponent,
    LoadingSpinnerComponent,
    UserTypeSelectorComponent,
    SidebarComponent,
    TermsComponent,

    // Pipes
    AlertModalComponent,
    DatePipe,
    ShortTime,
    DateTimePipe,
    DateZeroPipe,
    FileSelectorComponent,
    MaxAlphanumericDirective,
    PaymentActionPipe,
    PaymentTypePipe,
    StringifyPipe,
    TimeZeroPipe,
    SitePipe,
    SafePipe,

    // Directives
    AutoFocusDirective,
    MaxDigitsDirective,
    ValidationDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // NgSelect module
    NgSelectModule,

    // NgBootstrap modules
    NgbModule,

    // Material modules
    MatTabsModule,
  ],
  exports: [
    // Components
    AlertComponent,
    AmmFormComponent,
    AmmMessageComponent,
    AmmModalComponent,
    AmmTableComponent,
    DateSelectorComponent,
    FileSelectorComponent,
    HotelTermsComponent,
    LoadingSpinnerComponent,
    UserTypeSelectorComponent,
    SidebarComponent,
    TermsComponent,

    // Pipes
    DatePipe,
    ShortTime,
    DateTimePipe,
    DateZeroPipe,
    PaymentActionPipe,
    PaymentTypePipe,
    StringifyPipe,
    TimeZeroPipe,
    SitePipe,
    SafePipe,

    // Directives
    AutoFocusDirective,
    MaxDigitsDirective,
    ValidationDirective,

    // Modules exportations
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgbModule,
    NgSelectModule,
  ],
})
export class SharedModule {}
