import { Component } from '@angular/core';
import { SidebarItem } from '@models/Sidebar.model';
import { UserService } from '@services/user.service';
import { Profile } from '../models/Profile.model';
import { ApiService } from '../services/api.service';
import { Memebership } from '../models/Membership.model';
import { SiteEnum } from '../enums/Site.enum';
import { SiteService } from '../services/site.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  sidebarItems: SidebarItem[] = [];
  isLogged: boolean = false;
  // Register
  profile: Profile = new Profile();
  member: Memebership = new Memebership();
  step: number = 0;
  total: number = 6;
  site: SiteEnum = SiteEnum.Amm;
  timer: any;

  constructor(
    private user: UserService,
    private api: ApiService,
    private siteService: SiteService
  ) {
    this.user.stepChanged.subscribe((step: string) => {
      this.handleSteps(step);
    });
  }

  ngOnInit(): void {
    this.isLogged = this.user.logged;
    this.site = this.siteService.site;

    if (this.isLogged) {
      this.getProfile();
    } else {
      if (this.user.getRegister('MAINMEMBER')) {
        this.member = this.user.getRegister('MAINMEMBER');
      } else {
        this.member = this.user.getMembership();
      }

      this.sidebarItems = [
        // STEP1
        {
          name: 'Datos de acceso',
          link: `/register/access`,
        },
        // STEP2
        {
          name: 'Membresía',
          link: `/register/membership`,
        },
        // STEP3
        { name: 'Datos personales', link: `/register/info` },
        // STEP4 OR  STEP5 ON AMMOM
        {
          name: 'Información institucional',
          link: `/register/institutional`,
        },
        // STEP5 OR  STEP6 ON AMMOM
        {
          name: 'Pagar afiliación',
          link: `/register/payment`,
        },
      ];

      // STEP4
      if (this.site === 'ammom') {
        this.sidebarItems = this.insertItem(this.sidebarItems, 3, {
          name: 'Documentación',
          link: `/register/documentation`,
        });
      }

      if (
        this.member &&
        this.member.id === environment.memberships.no_profile
      ) {
        this.sidebarItems = this.insertItem(this.sidebarItems, 2, {
          name: 'Perfil de usuario No socio',
          link: '/register/no-membership',
        });
      }
    }
  }

  handleSidebar() {
    const isMember =
      this.profile.is_member === 1 &&
      this.profile.membership_id !== environment.memberships.pending;

    this.sidebarItems = [
      { name: 'Datos personales', link: `/account/info` },
      {
        name: 'Datos de acceso',
        link: `/account/access`,
      },
      {
        name: 'Membresía',
        link: `/account/membership`,
      },
      {
        name: 'Privacidad y reserva de datos',
        link: `/account/privacy`,
        separator: isMember,
      },
    ];

    // If not member don't add exclusive items
    if (!isMember) {
      return;
    }

    this.sidebarItems = [
      ...this.sidebarItems,
      {
        name: 'Información institucional',
        link: `/account/institutional`,
      },
      {
        name: 'Consultorio médico',
        link: `/account/doctor`,
      },
      {
        name: 'Educación Médica',
        link: `/account/medical`,
      },
      {
        name: 'Educación especialidad',
        link: `/account/speciality`,
      },
      {
        name: 'Otros grados',
        link: `/account/other`,
        separator: true,
      },
    ];

    if (this.site !== SiteEnum.Ammom) {
      this.sidebarItems.push({
        name: 'Recomendación',
        link: `/account/recommendation`,
      });
    }

    this.sidebarItems.push({
      name: 'Documentación',
      link: `/account/documentation`,
    });
  }

  insertItem(
    arr: SidebarItem[],
    position: number,
    side: SidebarItem
  ): SidebarItem[] {
    return [...arr.slice(0, position), side, ...arr.slice(position)];
  }

  getProfile() {
    this.api.call('portal/me').subscribe((response: any) => {
      if (response.status === 200) {
        this.profile.setProfile(response.data);
        this.user.profile = response.data;
        this.handleSidebar();
      }
    });
  }

  // Register step controller
  handleSteps(step: string) {
    if (step === '22') {
      if (this.sidebarItems[2].link !== '/register/no-membership') {
        this.sidebarItems = this.insertItem(this.sidebarItems, 2, {
          name: 'Perfil de usuario No socio',
          link: '/register/no-membership',
        });
      }

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setTimeout(() => {
        this.user.registerStep = 3;
      }, 250);

      return;
    }

    if (step === '33') {
      if (this.sidebarItems[2].link === '/register/no-membership') {
        this.sidebarItems.splice(2, 1);
      }

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setTimeout(() => {
        this.user.registerStep = 3;
      }, 250);

      return;
    }

    if (step) {
      this.step = Number(step);
    } else {
      this.step = this.user.registerStep;
    }
  }
}
