import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @Input() minToday: boolean = false;
  @Input() maxToday: boolean = false;
  @Input() data: string = '';
  @Input() name: string = 'name';
  @Input() id: string = 'name';
  @Input() set min(date: string) {
    if (date) {
      if (!this.data) this.data = date;

      this._min = this.utils.getDateFormated(date);

      if (Number(this.data.split('-')[2]) < Number(this._min.split('-')[2])) {
        this.data = this._min;
        setTimeout(() => {
          this.onDateChange(this.data);
        }, 0);
      }

      return;
    }
  }
  @Input() set max(date: string) {
    if (date) {
      this._max = this.utils.getDateFormated(date);
    }
  }

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  _max: string = '';
  _min: string = '';

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
    if (this.minToday) {
      this._min = new Date().toISOString().split('T')[0];
    }

    if (this.maxToday) {
      this._max = new Date().toISOString().split('T')[0];
    }
  }

  selectDate(event: any) {
    const date = event.target.value;

    if (typeof date === 'string') {
      this.onDateChange(date);
    }
  }

  onDateChange(date: string) {
    const formattedDate = this.utils.getDateFormated(date);

    if (formattedDate) {
      this.onChange.emit(formattedDate);
    }
  }
}
