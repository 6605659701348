import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { PublicGuard } from './services/guards/public.guard';
import { PrivateGuard } from './services/guards/private.guard';
import { MainComponent } from './pages/main/main.component';
import { AccountComponent } from './account/account.component';
import { InfoComponent } from './account/pages/info/info.component';
import { MembershipComponent } from './account/pages/membership/membership.component';
import { PrivacyComponent } from './account/pages/privacy/privacy.component';
import { AccessComponent } from './account/pages/access/access.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentsComponent } from './payment/pages/payments/payments.component';
import { PreferencesComponent } from './payment/pages/preferences/preferences.component';
import { InvoiceComponent } from './payment/pages/invoice/invoice.component';
import { BillingDataComponent } from './payment/pages/billing-data/billing-data.component';
import { MakePaymentComponent } from './payment/pages/make-payment/make-payment.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RecoverComponent } from './auth/recover/recover.component';
import { InstitutionalInformationComponent } from './account/pages/institutional-information/institutional-information.component';
import { DoctorsOfficeComponent } from './account/pages/doctors-office/doctors-office.component';
import { MedicalEducationComponent } from './account/pages/medical-education/medical-education.component';
import { OtherDegreesComponent } from './account/pages/other-degrees/other-degrees.component';
import { SpecialtyEducationComponent } from './account/pages/specialty-education/specialty-education.component';
import { RecommendationComponent } from './account/pages/recommendation/recommendation.component';
import { DocumentationComponent } from './account/pages/documentation/documentation.component';
import { InstitucionalComponent } from './account/pages/institucional/institucional.component';
import { HomeEnum } from './enums/Home.enum';
import { environment } from 'src/environments/environment';
import { PaymentEnum } from './payment/enums/PaymentKey.enum';
import { AutologinComponent } from './auth/autologin/autologin.component';

const routes: Routes = [
  {
    path: 'autologin/:token',
    component: AutologinComponent,
  },
  // auth routes - canActivate: [PublicGuard]
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PublicGuard],
    title: `Sistema Usuarios ${environment.site?.toUpperCase()}`,
  },
  // Application routes - canActivate: [PrivateGuard]
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'register/payment',
        pathMatch: 'full',
        component: HomeComponent,
        canActivate: [PrivateGuard],
        title: `Pago - ${environment.site?.toUpperCase()}`,
      },
      {
        path: 'register',
        component: AccountComponent,
        children: [
          {
            path: 'access',
            component: AccessComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'membership',
            component: MembershipComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'no-membership',
            component: MembershipComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'privacy',
            component: PrivacyComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'institutional',
            component: InstitutionalInformationComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'documentation',
            component: DocumentationComponent,
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'info',
            component: InfoComponent,
            canActivate: [PublicGuard],
            title: `Registro - ${environment.site?.toUpperCase()}`,
          },
          { path: '**', redirectTo: 'access' },
        ],
      },
      {
        path: 'terms',
        component: TermsComponent,
        title: `Aviso de privacidad - ${environment.site?.toUpperCase()}`,
      },

      {
        path: 'recover',
        component: RecoverComponent,
        canActivate: [PublicGuard],
        title: `${environment.site?.toUpperCase()}`,
      },
      {
        path: 'recover/:token',
        component: RecoverComponent,
        canActivate: [PublicGuard],
      },
      {
        path: 'account',
        component: AccountComponent,
        title: `Mi cuenta - ${environment.site?.toUpperCase()}`,
        children: [
          {
            path: 'access',
            component: AccessComponent,
            title: `Acceso - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'membership',
            component: MembershipComponent,
            title: `Membresía - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'privacy',
            component: PrivacyComponent,
            title: `Privacidad - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'institutional',
            component: InstitucionalComponent,

            title: `Información institucional - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'doctor',
            component: DoctorsOfficeComponent,
            title: `Consultorio médico - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'medical',
            component: MedicalEducationComponent,
            title: `Educación Médica - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'other',
            component: OtherDegreesComponent,
            title: `Otros grados - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'speciality',
            component: SpecialtyEducationComponent,
            title: `Especialidad - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'recommendation',
            component: RecommendationComponent,
            title: `Recomendaciones - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'documentation',
            component: DocumentationComponent,
            title: `Documentación - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'info',
            component: InfoComponent,
            title: `Mi cuenta - ${environment.site?.toUpperCase()}`,
          },
          {
            path: '*',
            redirectTo: 'info',
          },
        ],
        canActivate: [PrivateGuard],
      },
      {
        path: 'payment',
        component: PaymentComponent,
        children: [
          {
            path: 'billing-data',
            component: BillingDataComponent,
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'invoice',
            component: InvoiceComponent,
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          // { path: ':slug/:id', component: MakePaymentComponent },
          {
            path: 'membership',
            component: PaymentsComponent,
            data: { slug: 'membership', back: '/payment/membership' },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'membership/:id',
            component: MakePaymentComponent,
            data: {
              service: 'membership',
              type: PaymentEnum.Membership,
              back: '/payment/membership',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'subscription',
            component: PaymentsComponent,
            data: { slug: 'subscription', back: '/payment/subscription' },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'subscription/:id',
            component: MakePaymentComponent,
            data: {
              service: 'subscription',
              type: PaymentEnum.Subscription,
              back: '/payment/subscription',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'course/:id',
            component: MakePaymentComponent,
            data: {
              service: 'subscription',
              type: PaymentEnum.Course,
              back: '/congress/courses',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'hotel/:id',
            component: MakePaymentComponent,
            data: {
              service: 'hotel',
              type: PaymentEnum.Hotel,
              back: '/congress/hotel-reservation',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'congress',
            component: PaymentsComponent,
            data: { slug: 'congress', back: '/congress' },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'congress/:id',
            component: MakePaymentComponent,
            data: {
              service: 'congress',
              type: PaymentEnum.Congress,
              back: '/congress',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'webcast',
            component: PaymentsComponent,
            data: { slug: 'webcast', back: '/webcast' },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'webcast/:id',
            component: MakePaymentComponent,
            data: {
              service: 'webcast',
              type: PaymentEnum.Webcast,
              back: '/webcast',
            },
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          {
            path: 'preferences',
            component: PreferencesComponent,
            title: `Pagos - ${environment.site?.toUpperCase()}`,
          },
          // { path: ':slug', component: AllComponent },
          { path: '', redirectTo: 'all', pathMatch: 'full' },
          { path: '**', redirectTo: 'all' },
        ],
        canActivate: [PrivateGuard],
      },
      {
        path: 'congress',
        loadChildren: () =>
          import('./congress/congress.module').then((m) => m.CongressModule),
      },
      {
        path: 'webcast',
        loadChildren: () =>
          import('./webcast/webcast.module').then((m) => m.WebcastModule),
      },
      {
        path: 'evaluation',
        loadChildren: () =>
          import('./evaluation/evaluation.module').then(
            (m) => m.EvaluationModule
          ),
      },
      {
        path: '',
        component: HomeComponent,
        canActivate: [PrivateGuard],
        data: { type: HomeEnum.Default },
        title: `Sistema Usuarios ${environment.site?.toUpperCase()}`,
      },
      { path: '**', redirectTo: '' },
    ],
  },
  { path: '**', redirectTo: '' },
];
const options: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  scrollPositionRestoration: 'top',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
