export class Recommendation {
  recomendations: string[] = ['', ''];

  getJson(): any {
    const recommendation = this.recomendations.filter(
      (recommendation) => recommendation !== ''
    );
    return [...recommendation];
  }

  isValid(): boolean {
    return (
      this.recomendations.length === 2 &&
      this.recomendations.every((item) => !!item)
    );
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
