import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

class MenuItem {
  name: string = '';
  link: string = '';
  image: string = '';
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  profile: Profile = new Profile();
  isLogged: boolean = false;
  speciality: boolean = false;
  membership: boolean = false;
  site: SiteEnum = SiteEnum.Amm;
  environment: any = environment;
  servicesItems: MenuItem[] = [];
  modalOpened: boolean = false;

  @ViewChild('specialityModal', { read: TemplateRef })
  specialityModal?: TemplateRef<any>;

  constructor(
    private user: UserService,
    private router: Router,
    private api: ApiService,
    private modalService: NgbModal,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.site = this.siteService.site;

    this.user.profileChanged.subscribe((token: string) => {
      if (this.user.profile) {
        this.profile.setData(this.user.profile);
        this.validate();
      }

      this.isLogged = this.user.logged;
    });

    if (this.isLogged) this.getProfile();

    this.initServices();
  }

  initServices() {
    this.servicesItems = [
        // {
        //   name: 'Socios',
        //   link: '/members',
        //   image: 'user.png',
        // },
        {
          name: 'Congresos',
          link: '/congress',
          image: 'group.png',
        },
        {
          name: 'Cursos y Sesiones',
          link: '/webcast',
          image: 'webcast.png',
        },
        {
          name: 'Constancias y Evaluaciones',
          link: '/evaluation',
          image: 'certificate.png',
        },
        {
          name: 'Pagos y facturación',
          link: '/payment/membership',
          image: 'pay.png',
        },
      ];

  }

  getProfile() {
    this.api.call('portal/me').subscribe((response: any) => {
      if (response.status === 200) {
        this.profile.setProfile(response.data);
        this.user.profile = response.data;
        this.validate();
      }
    });
  }

  validate() {
    if (this.profile.id === -1) return;

    this.membership =
      this.profile.membership_id === environment.memberships.no_profile;

    this.speciality =
      this.user.logged &&
      (this.profile.speciality_id === null ||
        this.profile.speciality_id === -1);

    if (
      this.specialityModal &&
      !this.modalOpened &&
      (this.membership || this.speciality)
    ) {
      this.modalService.open(this.specialityModal, {
        centered: true,
        backdrop: 'static',
      });

      this.modalOpened = true;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.modalOpened = false;
  }

  logout() {
    this.user.clearSession();
    this.router.navigate(['/login']);
  }
}
