<div class="row mx-0 gy-3">
  <div class="col-12">
    {{ title }}
    <strong class="text-primary ms-2" *ngIf="required">*</strong>
  </div>

  <div class="col-12 my-3" *ngIf="description">
    <p class="mb-0" [innerHTML]="description"></p>
  </div>

  <div class="col-12" *ngIf="templates[0] && templates[0].name">
    <div class="d-flex align-items-center">
      <span
        class="text-primary me-3"
        *ngFor="let template of templates; index as ind"
      >
        {{ template.name }}

        <i
          class="fa-solid fa-x clickable text-primary"
          (click)="remove(fileInput, ind)"
        ></i>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button class="btn btn-light w-auto px-4" (click)="fileInput.click()">
      <i [ngClass]="btnIcon"></i> {{ btnText }}
    </button>
  </div>

  <div class="col-12">
    <input
      #fileInput
      type="file"
      hidden
      (change)="onFileSelected($event)"
      [accept]="fileTypes"
      [multiple]="maxFiles > 1 ? 'multiple' : null"
    />
  </div>

  <div class="col-12">
    <a
      class="amm-link text-decoration-underline"
      href="{{ docs }}"
      *ngIf="docs"
      target="_blank"
      >Descargar</a
    >
  </div>
</div>
