import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Observer, forkJoin } from 'rxjs';
import { Document } from '@models/Document.component';

const MB_SIZE = 1024 * 1024;

@Component({
  selector: 'app-form-file-selector',
  templateUrl: './form-file-selector.component.html',
  styleUrls: ['./form-file-selector.component.scss'],
})
export class FormFileSelectorComponent implements OnInit {
  loading: boolean = false;
  templates: Document[] = [];

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() docs: string | undefined = '';
  @Input() btnText: string = 'SUBIR ARCHIVO';
  @Input() btnIcon: string =
    'fa-light fa-file-arrow-up bg-primary text-light p-1 me-2';
  @Input() fileTypes: string = 'image/png, image/jpeg, .png, .jpg, .pdf';
  @Input() maxSize: number = 5;
  @Input() maxFiles: number = 1;
  @Input() required: boolean = false;
  @Output() success: EventEmitter<Document[]> = new EventEmitter();
  @Output() error: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.maxSize = this.maxSize * MB_SIZE;
  }

  reset(inputRef: HTMLInputElement) {
    inputRef.value = '';

    this.templates = [
      {
        name: '',
        contents: '',
      },
    ];
  }

  // 1 Titulo, 2 Cedula
  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files instanceof FileList) {
      if (files.length > this.maxFiles) {
        this.error.emit('El archivo supera el tamaño máximo permitido');
        return;
      }

      const allFilesValid = Array.from(files).every((file) => {
        return file.size <= this.maxSize;
      });

      if (!allFilesValid) {
        this.error.emit('El archivo supera el tamaño máximo permitido');
        return;
      }

      this.convertFilesToBase64(files).subscribe({
        next: (base64Array: string[]) => {
          for (let i = 0; i < base64Array.length; i++) {
            const template: Document = {
              name: '',
              contents: '',
            };
            template.name = files[i].name;
            template.contents = base64Array[i];

            if (this.templates.length >= this.maxFiles) {
              this.templates.shift();
            }

            this.templates.push(template);
          }

          this.success.emit(this.templates);
        },
        error: (error) => {
          // Manejo del error
          console.error(error);
          this.error.emit(error);
        },
      });
    }
  }

  remove(inputElement: HTMLInputElement, index: number) {
    if (inputElement.files) {
      const files = Array.from(inputElement.files);

      if (this.templates.length <= 1) {
        this.reset(inputElement);
        return;
      }

      this.templates.splice(index, 1);

      if (index >= 0 && index < files.length) {
        files.splice(index, 1);
        inputElement.files = this.createFileList(files);
      }
    }
  }

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();

    for (const file of files) {
      dataTransfer.items.add(file);
    }

    return dataTransfer.files;
  }

  convertFilesToBase64(files: FileList): Observable<string[]> {
    const conversions: Observable<string>[] = Array.from(files).map((file) =>
      this.convertToBase64(file)
    );
    return forkJoin(conversions);
  }

  convertToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.onerror = () => {
        observer.error('Error al cargar el archivo.');
      };

      reader.readAsDataURL(file);
    });
  }
}
