import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModal, AlertType } from '@models/Alert.model';
import { UserService } from '@services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-message',
  templateUrl: './register-message.component.html',
  styleUrls: ['./register-message.component.scss'],
})
export class RegisterMessageComponent {
  @Input() step: number = 0;
  @Input() total: number = 4;

  alertData: AlertModal = new AlertModal();
  environment: any = environment;

  constructor() {}

  ngOnInit(): void {
    this.alertData.data = {
      className: AlertType.Pink,
      content:
        `Esta cuenta será <strong>válida</strong> para iniciar proceso de <strong>Afiliación</strong> a la ` +
        this.environment.association.short_name +
        `, <strong>Registro a los Congresos</strong> de la Asociación o cualquier actividad futura que requiera acceso restringido.`,
    };
  }
}
