import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { SITES, Site } from './sites.data';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeEnum } from '@enums/Home.enum';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { CongressService } from 'src/app/congress/services/congress.service';
import { Congress } from 'src/app/congress/models/Congress.model';
import { forkJoin } from 'rxjs';

interface Shortcut {
  name: string;
  icon: string;
  image: string;
  link: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isLogged: boolean = false;
  isRegister: boolean = false;
  loading: boolean = true;
  profile: Profile = new Profile();
  shortcuts: Shortcut[] = [];
  site: Site = new Site();
  siteEnum: SiteEnum = SiteEnum.Amm;
  type: HomeEnum = HomeEnum.Default;
  services = [this.api.call('portal/me')];

  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private siteService: SiteService,
    private congressService: CongressService
  ) {
    this.congressService.congressChanged.subscribe((cong: any) => {
      const congress: Congress = this.congressService.congress;

      if (this.type === 'congress') {
        this.site.messages = [];

        if (congress.id !== -1 && congress.url) {
          this.site.subtitleAction.text = `${congress.description}`;
          this.site.subtitleAction.link = congress.url;
        }
      }
    });
  }

  ngOnInit(): void {
    this.isLogged = this.user.logged;
    this.isRegister = this.router.url.includes('register/payment');
    this.siteEnum = this.siteService.site;

    if (this.isLogged) {
      this.type = this.route.snapshot.data['type'] ?? HomeEnum.Default;
      this.initShortcuts();

      if (this.siteEnum) {
        switch (this.siteEnum) {
          case SiteEnum.Ammom:
            this.type =
              this.type === 'congress'
                ? HomeEnum.AmmomCongress
                : HomeEnum.Ammom;

            this.shortcuts = this.shortcuts.filter(
              (shortcut) => shortcut.link !== '/evaluation'
            );

            break;

          case SiteEnum.Somos:
            this.type = HomeEnum.Somos;

            break;
        }
      }

      this.site = SITES[this.type];

      if (
        this.type === 'congress' ||
        this.type === 'default' ||
        this.type === 'somos' ||
        this.type === 'ammom'
      ) {
        this.site.messages = [];
        const congress: Congress = this.congressService.congress;

        if (congress.id !== -1 && congress.url) {
          this.site.subtitleAction.text = `${congress.description}`;
          this.site.subtitleAction.link = congress.url;
        } else {
          this.services.push(this.api.get('portal/congress'));
        }
      }

      this.load();
    }
  }

  initShortcuts() {
    this.shortcuts = [
      {
        name: 'Modificar mis datos',
        image: 'user.png',
        icon: 'fa-light fa-user text-pink',
        link: '/account/info',
      },
      {
        name:
          this.siteEnum !== SiteEnum.Ammom
            ? 'Mi congreso'
            : 'Mi Congreso / Certificación DXA',
        image: 'group.png',
        icon: 'fa-duotone fa-sitemap',
        link: '/congress',
      },
      {
        name: 'Pagos y facturación',
        image: 'pay.png',
        icon: 'fa-brands fa-cc-visa',
        link: '/payment/',
      },
      {
        name:
          this.siteEnum !== SiteEnum.Ammom
            ? 'Evaluación y constancias'
            : 'Cursos y Sesiones',
        image:
          this.siteEnum !== SiteEnum.Ammom ? 'certificate.png' : 'webcast.png',
        icon: 'fa-light fa-file-certificate',
        link: '/evaluation',
      },
    ];

    switch (this.type) {
      case HomeEnum.Evaluation:
        this.shortcuts[2].link = '/payment/webcast';
        this.shortcuts[3].name = 'Descargar Constancias';
        this.shortcuts[3].link = '/evaluation/certificates';

        this.shortcuts.unshift(this.shortcuts[3]);
        this.shortcuts.pop();

        break;

      case HomeEnum.Congress:
        this.shortcuts[1].name = 'Registro a Congreso';
        this.shortcuts[1].link = '/congress/register';
        this.shortcuts[2].link = '/payment/congress';

        this.shortcuts.unshift(this.shortcuts[1]);
        this.shortcuts.splice(2, 1);

        break;

      case HomeEnum.AmmomWebcasts:
        this.shortcuts[0].name = 'Registro a Cursos y Sesiones';
        this.shortcuts[0].link = '/webcast/courses-and-sessions';
        this.shortcuts[2].link = '/payment/webcast';

        break;

      case HomeEnum.Default:
        this.shortcuts[2].link = '/payment/membership';

        break;

      default:
        this.shortcuts[2].link = `/payment/${
          this.isRegister ? 'membership' : this.type
        }`;

        break;
    }

    // AMMOM doesnt have EVALUATION
    if (
      this.siteEnum === SiteEnum.Ammom &&
      this.type !== HomeEnum.AmmomWebcasts
    ) {
      this.shortcuts = this.shortcuts.filter((shortcut) => {
        return !shortcut.link.includes('/evaluation');
      });
    }
  }

  load() {
    forkJoin(this.services).subscribe({
      next: ([profileRes, congressRes]) => {
        if (profileRes.status === 200) {
          this.profile.setProfile(profileRes.data);
          // this.user.profile = response.data;

          if (this.profile.is_member === 0) {
            this.shortcuts = this.shortcuts.filter(
              (shortcut) => !shortcut.link.includes('payment')
            );
          } else if (this.isRegister) {
            this.shortcuts = this.shortcuts.filter(
              (shortcut) => !shortcut.link.includes('congress')
            );
          }
        }

        if (congressRes && congressRes.status === 200) {
          const data: any[] = congressRes.data;

          if (data && Array.isArray(data)) {
            const congress = data.find((con) => {
              return con.current === 1;
            });

            if (congress) {
              if (congress.id !== -1 && congress.url) {
                this.site.subtitleAction.text = `${congress.description}`;
                this.site.subtitleAction.link = congress.url;
              }
            }
          }
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
