import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-autologin',
  template: ` <p>autologin works!</p> `,
  styles: [],
})
export class AutologinComponent {
  constructor(
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const params = this.route.snapshot.params;

    const token = params['token'];

    if (token) {
      this.user.clearSession();
      this.user.token = token;

      if (queryParams['redirectTo']) {
        this.router.navigate([queryParams['redirectTo']], {
          replaceUrl: true,
        });

        return;
      }
    }

    this.router.navigate(['/'], {
      replaceUrl: true,
    });
  }
}
