import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertModal, AlertType } from '@models/Alert.model';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';

@Component({
  selector: 'app-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss'],
})
export class CardComponent {
  alertData: AlertModal = new AlertModal();
  name: string = '';
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#F056A3',
        color: '#5A5457',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'es',
  };
  @ViewChild(StripeCardComponent, { static: true })
  newCard?: StripeCardComponent;
  @Output() success = new EventEmitter<string>();
  @Output() failed = new EventEmitter();

  constructor(private stripeService: StripeService) {}

  getToken() {
    if (!this.newCard) {
      return;
    }

    this.stripeService
      .createPaymentMethod({
        type: 'card',
        card: this.newCard.element,
        billing_details: {
          name: this.name,
        },
      })
      .subscribe({
        next: (result) => {
          if (result && result.paymentMethod) {
            this.success.emit(result.paymentMethod.id);
          } else {
            this.alertData.data = {
              title: '¡Error!',
              content:
                result.error.message ??
                'Error al procesar el pago, verifique los datos de su tarjeta.',
              className: AlertType.Danger,
            };

            this.failed.emit();
          }
        },
        error: (error) => {
          console.error(error);

          this.alertData.data = {
            title: '¡Error!',
            content:
              'Error al procesar el pago, verifique los datos de su tarjeta.',
            className: AlertType.Danger,
          };
        },
      });
  }
}
