import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AlertModal, AlertType } from '@models/Alert.model';
import { Memebership } from '@models/Membership.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-specialty-modal',
  templateUrl: './specialty-modal.component.html',
  styleUrls: ['./specialty-modal.component.scss'],
})
export class SpecialtyModalComponent implements OnInit {
  @Input() specialityError: boolean = false;
  @Input() membershipError: boolean = false;
  @Output() close = new EventEmitter();

  alertData: AlertModal = new AlertModal();
  loading: boolean = true;
  specialities: any = [];
  data: Profile = new Profile();
  specialityId: number = -1;
  membershipId: number = -1;
  success: boolean = false;
  memberships: Memebership[] = [];
  environment: any = environment;
  site: SiteEnum = SiteEnum.Amm;

  constructor(private api: ApiService, private siteService: SiteService) {
    this.site = this.siteService.site;
  }

  ngOnInit(): void {
    const services = [];

    if (this.specialityError) {
      services.push(this.api.get('specialities'));
    }

    if (this.membershipError) {
      services.push(this.api.call('portal/me/memberships'));
    }

    if (services.length === 0) {
      this.closeModal();

      return;
    }

    forkJoin(services).subscribe({
      next: (responses: any) => {
        if (this.specialityError) {
          if (responses[0]) {
            this.specialities = responses[0];
          }

          if (this.membershipError) {
            if (responses[1].status === 200) {
              this.memberships = responses[1].data;
            }
          }
        }

        if (this.membershipError) {
          if (responses[0].status === 200) {
            this.memberships = responses[0].data;
          }
        }

        this.loading = false;
      },
      error: (errors) => {
        console.error(errors);
        this.loading = false;
      },
    });
  }

  closeModal() {
    this.close.emit();
  }

  handleSave() {
    if (!this.isValid()) {
      return;
    }

    this.loading = true;

    const services = [];

    if (this.specialityError) {
      services.push(
        this.api.call('portal/me/speciality', 'put', {
          speciality_id: this.specialityId,
        })
      );
    }

    if (this.membershipError) {
      services.push(
        this.api.call('portal/me/membership', 'put', {
          membership_id: this.membershipId,
        })
      );
    }

    forkJoin(services).subscribe({
      next: (responses: any[]) => {
        let success: boolean = false;
        let message: string = '';

        if (this.specialityError) {
          if (responses[0].status === 200) {
            success = true;
          } else {
            success = false;
            message = responses[0].message;
          }

          if (this.membershipError) {
            if (responses[1].status === 200) {
              success = true;
            } else {
              success = false;
              message = responses[1].message;
            }
          }
        } else {
          if (this.membershipError) {
            if (responses[0].status === 200) {
              success = true;
            } else {
              success = false;
              message = responses[0].message;
            }
          }
        }

        this.alertData.data = {
          title: '',
          content: success ? '¡Datos guardados exitosamente!' : message,
          className: success ? AlertType.Success : AlertType.Danger,
        };

        this.success = true;
        this.loading = false;

        setTimeout(() => {
          this.closeModal();
        }, 2500);
      },
      error: (error) => {
        this.alertData.data = {
          title: '¡Error!',
          content: error.message,
          className: AlertType.Danger,
        };
        this.loading = false;
      },
    });
  }

  isValid(): boolean {
    if (this.membershipError && this.membershipId === -1) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Debe seleccionar un perfil.',
        className: AlertType.Danger,
      };

      return false;
    }

    if (this.specialityError && this.specialityId === -1) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Debe seleccionar una especialidad.',
        className: AlertType.Danger,
      };

      return false;
    }

    return true;
  }
}
