<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="row mt-lg-4 mb-4 mb-lg-5">
    <h2 class="col-12">
      Información Socios {{ environment.association.name }}
    </h2>
  </div>

  <div class="row mb-3 mb-md-4">
    <div class="col-12">
      <h3>Documentación probatoria</h3>

      <p class="mb-0">
        Formatos admitidos: PDF, JPG o PNG. Archivos de un máximo de 5 MB
      </p>
    </div>
  </div>

  <app-form-file-selector
    title="Título ó constancia de especialidad"
    (success)="onFileSelected($event, 1)"
    (error)="onFileError($event)"
    [docs]="data.document_title"
    [required]="true"
  ></app-form-file-selector>

  <hr />

  <app-form-file-selector
    title="Cédula profesional"
    (success)="onFileSelected($event, 2)"
    (error)="onFileError($event)"
    [docs]="data.document_card"
    [required]="true"
  ></app-form-file-selector>

  <hr *ngIf="site === 'ammom'" />

  <app-form-file-selector
    title="Síntesis curricular"
    description="Síntesis curricular en 2 o 3 cuartillas como máximo, que incluya información sobre formación académica, experiencia laboral y logros relevantes. Datos personales, como dirección, teléfono, RFC, lugar y fecha de nacimiento."
    (success)="onFileSelected($event, 3)"
    (error)="onFileError($event)"
    [docs]="data.document_curricular"
    [required]="true"
    *ngIf="site === 'ammom'"
  ></app-form-file-selector>

  <div class="row gy-3 mb-4">
    <div class="col-12 align-items-center">
      <small class="text-primary">
        <strong>* Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="handleSubmit()">
        {{ logged ? "GUARDAR CAMBIOS" : "SUBIR Y CONTINUAR" }}

        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
