<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="text-center mt-4 mb-5">
    Información Socios {{ environment.association.name }}
  </h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Información institucional</h3>
    </div>

    <div class="col-12">
      Institución

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm mt-2"
          [(ngModel)]="data.institute"
          validationdirective
          [required]="true"
        />
      </span>
    </div>

    <div class="col-12">
      Departamento

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.department"
          placeholder="Nombre"
          validationdirective
          [required]="true"
        />
      </span>
    </div>

    <div class="col-12">
      Correo electrónico institucional
      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.institute_email"
          placeholder="usuario@dominio.com"
          validationdirective
          [required]="true"
        />
      </span>
    </div>
  </div>

  <div class="row gy-4 mb-4" *ngIf="environment.register.recommendations">
    <h3 class="col-12 mb-0">Recomendaciones</h3>

    <div class="col-12">
      2 Miembros titulares de la {{ environment.association.name }} que lo
      recomiendan
    </div>

    <div class="col-12">
      Socio 1

      <input
        type="text"
        class="form-control input-amm text-start mt-2"
        placeholder="Nombre completo"
        [(ngModel)]="data.recomendations[0]"
      />
    </div>

    <div class="col-12">
      Socio 2

      <input
        type="text"
        class="form-control input-amm text-start mt-2"
        placeholder="Nombre completo"
        [(ngModel)]="data.recomendations[1]"
      />
    </div>
  </div>

  <div class="row gy-4 mb-4" *ngIf="environment.register.public_data">
    <hr class="col-12 my-5" />

    <div class="col-12">
      <h3>Publicación de datos Institucionales</h3>
    </div>

    <div class="col-12 mb-4" (click)="data.public = 1">
      <label
        class="card card-hoverable clickable p-2 pd-md-3"
        [ngClass]="{ 'card-selected': data.public === 1 }"
        for="cardRadio1"
      >
        <small
          class="xsmall text-info my-2"
          [ngClass]="data.public === 1 ? 'show-data' : 'hide-data d-none'"
        >
          SU SELECCIÓN
        </small>

        <h6 class="card-title">Información pública</h6>

        <div class="form-check w-100">
          <input
            class="form-check-input"
            type="radio"
            name="information"
            id="cardRadio1"
            [value]="1"
            [(ngModel)]="data.public"
          />
          <label class="form-check-label" for="cardRadio1">
            Autorizo hacer público los datos para el Directorio Electrónico de
            Socios
          </label>
        </div>
      </label>
    </div>

    <div class="col-12" (click)="data.public = 0">
      <label
        class="card card-hoverable clickable p-2 pd-md-3"
        [ngClass]="{ 'card-selected': data.public === 0 }"
        for="cardRadio2"
      >
        <small
          class="xsmall text-info my-2"
          [ngClass]="data.public === 0 ? 'show-data' : 'hide-data d-none'"
        >
          SU SELECCIÓN
        </small>

        <h6 class="card-title">Información no pública</h6>

        <div class="form-check w-100">
          <input
            class="form-check-input"
            type="radio"
            name="information"
            id="cardRadio2"
            [value]="0"
            [(ngModel)]="data.public"
          />
          <label class="form-check-label" for="cardRadio2">
            No deseo hacer público los datos para el Directorio Electrónico de
            Socios
          </label>
        </div>
      </label>
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <small>
        <strong><span class="text-primary">*</span> Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="saveChanges()">
        SIGUIENTE
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
