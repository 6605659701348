export class InstituteInfo {
  institute: string = '';
  department: string = '';
  institute_email: string = '';
  recomendations: string[] = ['', ''];

  institute_country: string = 'México';
  institute_city: string = '';
  institute_municipality: string = '';
  institute_external_house_number: string = '';
  institute_internal_house_number: any = null;
  institute_neighborhood: string = '';
  state_id: any = null;
  institute_state: string | null = null;
  institute_street: string = '';
  public: number = 0;
  institute_zip_code: string = '';
  speciality_id: number | null = null;

  getRegister(
    recommendations: boolean = true,
    publicData: boolean = true
  ): Object {
    const params: any = {
      institute: this.institute,
      department: this.department,
      institute_email: this.institute_email,
    };

    if (recommendations) params['recomendations'] = this.recomendations;
    if (publicData) params['public'] = Number(this.public);

    return params;
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }

  getProfileToSave() {
    return {
      institute: this.institute,
      department: this.department,
      institute_email: this.institute_email,
      institute_street: this.institute_street,

      institute_external_house_number: this.institute_external_house_number,
      institute_internal_house_number: this.institute_internal_house_number,
      institute_neighborhood: this.institute_neighborhood,
      institute_municipality: this.institute_municipality,
      institute_state: this.institute_state,
      institute_city: this.institute_city,
      institute_zip_code: this.institute_zip_code,
      institute_country: this.institute_country,
    };
  }

  isValid(): boolean {
    return (
      this.institute !== '' &&
      this.institute.length > 0 &&
      this.department !== '' &&
      this.department.length > 0 &&
      this.institute_email !== '' &&
      this.institute_email.length > 0 &&
      (this.public === 0 || this.public === 1)
    );
  }
}
