import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertModal, AlertType } from '@models/Alert.model';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-amm-modal',
  templateUrl: './amm-modal.component.html',
  styleUrls: ['./amm-modal.component.scss'],
})
export class AmmModalComponent {
  @Input() title = '';
  @Input() headerClass = 'bg-primary';
  @Input() modalBodyClass = '';
  @Input() hasFooter = false;
  @Output() close = new EventEmitter();

  constructor(private api: ApiService) {}

  closeModal() {
    this.close.emit(true);
  }
}
