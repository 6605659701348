import { SiteEnum } from '@enums/Site.enum';

export const environment = {
  analytics: '',
  cookie: 'amm_sys',
  domain: 'mastologia.org.mx',
  waPhone: '',
  key: 'amm',
  login: {
    title: 'Bienvenido a la Asociación Mexicana de Mastología',
    description:
      '37 años de experiencia en la educación multidisciplinaria del cáncer de mama',
  },
  register: {
    recommendations: true,
    public_data: true,
  },
  memberships: {
    no_profile: 1,
    pending: 6,
    transitional: 3,
  },
  association: {
    name: 'AMM',
    short_name: 'ASOCIACIÓN MEXICANA DE MASTOLOGÍA',
    legal: 'Asociación Mexicana de Mastología A. C. 2023',
    congress: 'XX Congreso Nacional de Mastología 2023',
  },
  congress: {
    from: '2023-10-01',
    to: '2023-10-10',
    ages: 17,
  },
  works: {
    title:
      'El título debe ser conciso; de modo tal que exprese con pocas palabras y de modo preciso el objetivo de la investigación, las variables bajo consideración, la población o universo sobre la que se extenderán las conclusiones, y de ser necesario, dónde y cuando se llevará a cabo. Asimismo, es conveniente que mencione el tipo de diseño que tendrá la investigación.',
    antecedent:
      'Es lo que fundamenta la necesidad de realizar una investigación para generar conocimientos que aporte al conocimiento existente. El planteamiento del problema debe brindar un argumento convincente de que los conocimientos disponibles son insuficientes para dar cuenta del problema y sus posibles alternativas de solución, o brindar un argumento claro de la necesidad de someter a prueba si lo que se conoce y se da como un hecho verdadero, puede no ser tan cierto dado nuevos hallazgos o nuevas situaciones.',
    objective: `
    Los objetivos establecen qué aspectos específicos planteados en el problema se estudiarán. <br />
    La formulación de los objetivos debe ser clara y precisa con respecto a las variables o categorías, las relaciones entre ellas, la población sobre la que se extenderán los resultados y cuando corresponde, el período de tiempo al que se refiere.

    <br />
    <br />

    Cuando existe más de un objetivo se pueden identificar el Objetivo general y los Objetivos específicos.

    <br />
    <br />

    <ul>
      <li>
      Objetivo general: Debe explicitar lo que se espera lograr con el estudio en términos de conocimiento. Debe dar una noción clara de lo que se pretende describir, determinar, identificar, comparar y verificar.
      </li>

      <li>
      Objetivos específicos: Son la descomposición y secuencia lógica del objetivo general. Son un anticipo del diseño de la investigación.
      </li>
    </ul>
    `,
    materials: `
    Describe los procedimientos y procesos que han llevado a la consecución de los resultados obtenidos, para que el lector obtenga una idea precisa de las fases sucesivas que se han seguido para la realización del trabajo, generalmente se aporta información sobre los siguientes aspectos:

    <br />
    <br />

    <ol type="a">
      <li>Tipo de diseño metodológico.</li>

      <li>Informantes y lugar de estudio (o materiales y modo de obtención).</li>

      <li>Técnica de obtención de datos.</li>

      <li>Trabajo de campo.</li>

      <li>Organización y procesamiento de los datos.</li>

      <li>Análisis de datos.</li>

      <li>Cuestiones éticas.</li>
    </ol>
`,
    results: `En el apartado de resultados se exponen los datos obtenidos, siguiendo un orden lógico que permita responder a las preguntas u objetivos planteados y generalmente con el apoyo de tablas o elementos gráficos. El propósito es presentar objetivamente los principales hallazgos de la investigación. En este apartado se suelen incluir diferentes tipos de contenidos:

    <br />
    <br />

    <ol type="a">
      <li>Texto.</li>

      <li>Tablas.</li>

      <li>Figuras, gráficos o imágenes.</li>
    </ol>
    `,
    discussions: `
    Consiste en una interpretación o valoración de los resultados obtenidos. El objetivo es situar los resultados del tema estudiado en el contexto general del área de conocimiento. Generalmente es la parte más difícil de elaborar. Los contenidos más habituales que se suelen incluir en la discusión son los siguientes:

    <br />
    <br />

    <ol type="a">
      <li>Relacionar los datos obtenidos con los hallazgos de otros estudios previos.</li>

      <li>Explicar o hacer hincapié en los aspectos más novedosos e importantes del estudio.</li>
      
      <li>Ofrecer recomendaciones o implicaciones prácticas basadas en los datos.</li>
      
      <li>Presentar y discutir las limitaciones del estudio.</li>
      
      <li>Sugerir nuevas líneas de trabajo.</li>
    </ol>
    `,
    conclusions: `El resumen o conclusiones debe dar una idea clara al lector, sobre cuál es la pregunta central que la investigación pretende responder y su justificación, debe contener un breve recuento de los métodos y procedimientos contenidos en el capítulo metodología.`,
  },
  endpoint: 'https://stage.cms.mastologia.org.mx/api/',
  stripeKey: 'pk_test_ddnwrRDUlXwRDQiuyi9raYQV00NkAXuNfw',
  production: false,
  site: SiteEnum.Amm,
  webcast:'https://stage.webcast.mastologia.org.mx/'
};
