import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import terms from '../../../../assets/data/terms.json';

@Component({
  selector: 'app-hotel-terms',
  templateUrl: './hotel-terms.component.html',
  styleUrls: ['./hotel-terms.component.scss']
})
export class HotelTermsComponent {
    terms:string = '';
    constructor() {
      let k = environment.key as string;
      this.terms = terms[k as keyof typeof terms];
    }
}
