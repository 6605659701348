import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortdate',
})
export class DatePipe implements PipeTransform {
  // Date with format : yyyy-mm-dd return date dd/mm/yyyy
  transform(date: string): string {
    const parts = date.split('-');

    if (parts && parts.length >= 3) {
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return date;
  }
}

@Pipe({
  name: 'shorttime',
})
export class ShortTime implements PipeTransform {
  transform(date: string): string {
    const parts = date.split(':');

    if (parts && parts.length >= 3) {
      return `${parts[0]}:${parts[1]}:${parts[2]}`;
    }

    return date;
  }
}

@Pipe({
  name: 'zerodate',
})
export class DateZeroPipe implements PipeTransform {
  // Date with format : yyyy-mm-dd return date dd/mm/yyyy
  transform(date: string): string {
    const timeFormat: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };

    const dateObj = new Date(date).toLocaleDateString('es-MX', timeFormat);

    return dateObj;
  }
}

@Pipe({
  name: 'zerotime',
})
export class TimeZeroPipe implements PipeTransform {
  transform(date: string): string {
    const timeFormat: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'America/Mexico_City',
    };

    const dateObj = new Date(date).toLocaleTimeString('es-MX', timeFormat);

    return dateObj;
  }
}
