import { Component, ViewChild } from '@angular/core';
import { AlertModal, AlertType } from '@models/Alert.model';
import { InvoiceDATA } from '@models/Invoice.model';
import { ApiService } from '@services/api.service';
import { BillFormComponent } from '../../components/bill-form/bill-form.component';

@Component({
  selector: 'app-billing-data',
  templateUrl: './billing-data.component.html',
  styleUrls: ['./billing-data.component.scss'],
})
export class BillingDataComponent {
  alertData: AlertModal = new AlertModal();
  loading = false;
  invoiceData: InvoiceDATA = new InvoiceDATA();

  @ViewChild(BillFormComponent) billForm?: BillFormComponent;

  constructor(private api: ApiService) {}

  updateBillingData() {
    if (this.billForm) {
      this.billForm.validate();
    }

    if (!this.invoiceData.isValid() && !this.invoiceData.vat_constancy) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Selecciona tu Constancia Fiscal',
        className: AlertType.Danger,
      };

      return;
    }

    if (!this.invoiceData.isValid()) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena todos los campos.',
        className: AlertType.Danger,
      };

      return;
    }

    if (!this.invoiceData.isVatNameValid()) {
      const msg = `El RFC de la persona ${
        this.invoiceData.vat_type === 'moral' ? 'Moral' : 'Física'
      } debe tener ${
        this.invoiceData.vat_type === 'moral' ? '12' : '13'
      } caracteres.`;

      this.alertData.data = {
        title: '¡Error!',
        content: msg,
        className: AlertType.Danger,
      };

      return;
    }

    this.loading = true;

    this.api.callReq(
      {
        service: 'portal/invoices/vat',
        method: 'put',
        params: this.invoiceData.getJSON(),
      },
      (response: any) => {
        this.alertData.data = {
          title: '¡Éxito!',
          content: 'Los datos se han actualizado correctamente.',
          className: AlertType.Success,
        };

        this.loading = false;
      },
      (error: any) => {
        console.error(error);
        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      }
    );
  }
}
