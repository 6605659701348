import {
  AfterContentInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { SidebarItem } from '@models/Sidebar.model';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterContentInit, OnInit {
  @Input() title: string = '';
  @Input() titleLink: string = '';
  @Input() items: SidebarItem[] = [];
  @Input() isLogged: boolean = true;
  @Input() step: number = 0;
  @Input() total: number = 0;

  @Output() onDownload: EventEmitter<string> = new EventEmitter();

  isMobile: boolean = false;
  activeChild: number = -1;
  enables: number[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth < 992;

    if (!this.isMobile) {
      this.close();
    }
  }

  constructor(
    private user: UserService,
    private router: Router,
    private offcanvasService: NgbOffcanvas
  ) {
    this.user.stepChanged.subscribe((step: string) => {
      if (step === '22' || step === '33') {
        return;
      }

      if (step) {
        this.step = Number(step);

        if (this.items.length > 0) this.handleStep();
      } else {
        this.step = user.registerStep;
      }

      if (this.step === this.total) {
        this.enables = [this.total];
      } else {
        this.enables = Array.from({ length: this.step }, (_, i) => i + 1);
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 992;

    if (!this.isMobile) {
      this.close();
    }

    const url = this.router.url;

    for (let i = 0; i < this.items.length; i++) {
      if (url === this.items[i].link) {
        if (this.items[i].children) {
          this.activeChild = i;
        }
      }
    }

    if (this.step === this.total) {
      this.enables = [this.total];
    } else {
      this.enables = Array.from({ length: this.step }, (_, i) => i + 1);
    }
  }

  ngAfterContentInit(): void {
    if (this.isLogged) return;

    if (this.items.length > 0) this.handleStep();
  }

  open(content: any) {
    this.offcanvasService.open(content);
  }

  close() {
    this.offcanvasService.dismiss();
  }

  handleStep() {
    if (this.isLogged) return;

    const item = this.items[this.user.registerStep - 1];
    // console.log(item);

    if (item) this.router.navigate([item.link]);
  }

  handleChildren(hasChild: boolean, index: number) {
    if (hasChild && index !== this.activeChild) {
      this.activeChild = index;
    } else {
      this.activeChild = -1;
    }
  }

  handleActive(item: SidebarItem, index: number) {
    this.handleChildren(item.children ? true : false, index);
  }

  download(url: string) {
    this.onDownload.emit(url);

    this.close();
  }
}
