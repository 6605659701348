import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  waPhone: string = '';

  constructor() {
    const phone = environment.waPhone;

    if (phone) {
      this.waPhone = phone
    }
  }
}
