import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin } from 'rxjs';

import { AlertModal, AlertType } from '@models/Alert.model';
import { Payment, PaymentSubscription } from '@models/Payment.model';

import { Storage } from '@services/storage.service';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';

import { PaymentEnum } from '../../enums/PaymentKey.enum';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent {
  currentPay: number = 0;
  alertData: AlertModal = new AlertModal();
  payments: Payment[] = [];
  suscriptions: PaymentSubscription[] = [];
  loading: boolean = true;
  slug: string = '';
  isCongress: boolean = false;
  environment: any = environment;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    protected siteService: SiteService,
    private storage: Storage
  ) {}

  ngOnInit(): void {
    const data = this.route.snapshot.data;
    if (data['slug']) this.slug = data['slug'];

    this.isCongress = this.slug === 'congress';

    forkJoin([
      this.api.call(
        this.isCongress
          ? `portal/congress/subscription`
          : `portal/subscriptions/${this.slug}`
      ),
      this.api.call(`portal/payment`),
    ]).subscribe({
      next: ([subscriptionRes, paymentRes]) => {
        if (subscriptionRes.status === 200) {
          this.suscriptions = subscriptionRes.data;

          if (this.suscriptions.length > 0) {
            this.currentPay = this.suscriptions[0].id;
          }
        } else {
          console.error(subscriptionRes.message);
          this.alertData.data = {
            title: '¡Error!',
            content: subscriptionRes.message,
            className: AlertType.Danger,
          };
        }

        if (paymentRes.status === 200) {
          this.payments = paymentRes.data;
          this.payments = this.payments.map((payment) => {
            payment.description = payment.description.replace(
              /\n/g,
              '<br><br>'
            );
            payment.description = payment.description.replace(
              /\\n/g,
              '<br><br>'
            );

            return payment;
          });
        } else {
          console.error(paymentRes.message);
          this.alertData.data = {
            title: '¡Error!',
            content: paymentRes.message,
            className: AlertType.Danger,
          };
        }

        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      },
    });
  }

  goTo() {
    if (this.router.url.includes('speciality')) {
      this.router.navigate([`/payment/speciality/${this.currentPay}`]);
      return;
    }

    if (this.router.url.includes('webcast')) {
      const payment = this.suscriptions.find((s) => s.id === this.currentPay);

      if (payment) {
        this.storage.setData(PaymentEnum.Webcast, payment);
      }
    }

    this.router.navigate([`/payment/${this.slug}/${this.currentPay}`]);
  }

  handleAction(payment: Payment) {
    if (
      payment.subscription_type === 'membership' ||
      payment.subscription_type === 'affiliation'
    ) {
      this.downloadInvoice(payment.id);
    }

    if (payment.subscription_type === 'webcast') {
      this.router.navigate([`/evaluation/certificates`]);
    }
  }

  downloadInvoice(id: number) {
    this.loading = true;
    this.api.download(`portal/payment/${id}/constancy`, () => {
      this.loading = false;
    });
  }
}
