export class SpecialityEducation {
  speciality_id: number | null = null;
  speciality_years: string = '';
  speciality_institute: string = '';
  speciality_start_date: string = '';
  speciality_end_date: string = '';
  speciality_card: string = '';
  speciality_advice_years: string = '';

  getJson() {
    return JSON.stringify(this);
  }

  isValid(): boolean {
    return true;
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
