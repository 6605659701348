export const GENDERS: string[] = ['Masculino', 'Femenino', 'Otro'];
export const STATES: string[] = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Chiapas',
  'Chihuahua',
  'Ciudad de México',
  'Coahuila',
  'Colima',
  'Durango',
  'Estado de México',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
];

export const PREFIX: string[] = [
  'DR.',
  'DRA.',
  'ENF.',
  'ENF. QCA.',
  'EST.',
  'LE.',
  'LIC.',
  'LIC. T.F.',
  'PSIC.',
  'SR.',
  'SRA.',
  'T.R.',
  'LIC. ENF.',
  'TEC. RAD.',
  'MC.',
];

export const AMMOM_PREFIX: string[] = [
  'DR.',
  'DRA.',
  'MNC.',
  'TEC.',
  'LIC.',
  'MTRO.',
  'M. EN C.',
  'SR.',
  'SRA.',
];
