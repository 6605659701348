<div class="row w-100 m-0" [ngClass]="{ loading: loading }">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="col-12 mb-4">
    <h3>Facturas</h3>
  </div>

  <div class="col-12" *ngIf="invoices.length === 0">
    <h5 class="text-primary">No hay facturas emitidas.</h5>
  </div>

  <div class="col-12" *ngIf="invoices.length > 0">
    <div class="w-100 table-responsive">
      <table>
        <tr class="table-header text-primary">
          <th>Fecha de emisión</th>
          <th>Folio</th>
          <th>Concepto</th>
          <th>Estado</th>
          <th>Monto</th>
          <th>Descargar PDF</th>
          <th>Descargar XML</th>
        </tr>

        <tr *ngFor="let invoice of invoices">
          <td>{{ invoice.created_at | zerodate }}</td>
          <td>{{ invoice.folio }}</td>
          <td>{{ invoice.concept }}</td>
          <td *ngIf="invoice.invoiceable === 0">
            <span class="text-muted">No disponible</span>
          </td>
          <td *ngIf="invoice.invoiceable === 1">
            <span
              class="text-success"
              *ngIf="invoice.status === 'completed'; else invoiceError"
              >Vigente</span
            >

            <ng-template #invoiceError>
              <p class="text-danger">
                <i class="fa-solid fa-triangle-exclamation"></i> Error
              </p>

              <button
                class="btn btn-danger"
                *ngIf="invoice.status !== 'completed'"
                (click)="solveInvoice(invoice)"
              >
                Solucionar
              </button>
            </ng-template>
          </td>
          <td>${{ invoice.amount }}</td>
          <td>
            <button
              class="btn text-primary"
              (click)="downloadFile(invoice.id)"
              *ngIf="invoice.cfdi_pdf_path"
            >
              <i class="fa-light fa-download fa-xl"></i>
            </button>

            <small
              class="horizontally-container"
              *ngIf="!invoice.cfdi_pdf_path"
            >
              No disponible
              <a
                href="mailto:{{
                  environment.site === 'ammom'
                    ? 'educacion@ammom.mx'
                    : 'contacto@' + environment.domain
                }}"
                class="text-primary w-100"
                >Contactar {{ environment.association.name }}</a
              >
            </small>
          </td>
          <td>
            <button
              class="btn text-primary"
              (click)="downloadFile(invoice.id, 'xml')"
              *ngIf="invoice.cfdi_xml_path"
            >
              <i class="fa-thin fa-file-code fa-xl"></i>
            </button>

            <small
              class="horizontally-container"
              *ngIf="!invoice.cfdi_xml_path"
            >
              No disponible
              <a
                href="mailto:{{
                  environment.site === 'ammom'
                    ? 'educacion@ammom.mx'
                    : 'contacto@' + environment.domain
                }}"
                class="text-primary w-100"
                >Contactar {{ environment.association.name }}</a
              >
            </small>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
