export class OtherDegrees {
  grade_name: string = '';
  grade_speciality: string | null = null;
  grade_college: string = '.';
  grade_start_date: string = '.';
  grade_end_date: string = '.';

  getJson() {
    return JSON.stringify(this);
  }

  isValid(): boolean {
    return true;
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
