import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Congress } from '../models/Congress.model';

@Injectable({
  providedIn: 'root',
})
export class CongressService {
  private _congress: Congress = new Congress();
  private _id: number = -1;
  private congressObservable = new BehaviorSubject<string>('');
  congressChanged = this.congressObservable.asObservable();

  changeCongress(congress: Congress) {
    this._congress = congress;
    this.id = this._congress.id;
    this.congressObservable.next(`congress-${this.id}`);
  }

  get congress() {
    return this._congress;
  }

  private set id(value: number) {
    this._id = value;
  }

  public get id(): number {
    return this._id;
  }
}
