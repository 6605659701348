<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="mt-4 mb-5 text-center">Información Socios {{ environment.association.name }}</h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Especialidad</h3>
    </div>

    <div class="col-12">
      <label for="">Especialidad</label>
      <span class="mt-2">
        <ng-select
          [items]="specialities"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="data.speciality_id"
          [clearable]="false"
          placeholder="Elija especialidad"
        ></ng-select>
      </span>
    </div>

    <div class="col-12">
      Años de residencia
      <input
        type="number"
        class="form-control input-amm mt-2"
        [(ngModel)]="data.speciality_years"
        placeholder="Numero"
      />
    </div>

    <div class="col-12">
      Institución
      <span class="mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.speciality_institute"
          placeholder="Nombre"
        />
      </span>
    </div>

    <div class="col-12">
      Fecha de inicio
      <span class="w-100 mt-2">
        <app-date-selector
          id="date-start"
          name="date-start"
          class="w-100"
          [data]="data.speciality_start_date"
          (onChange)="data.speciality_start_date = $event"
        ></app-date-selector>
      </span>
    </div>

    <div class="col-12">
      Fecha de finalización
      <span class="mt-2">
        <app-date-selector
          id="date-end"
          name="date-end"
          class="w-100"
          [data]="data.speciality_end_date"
          (onChange)="data.speciality_end_date = $event"
        ></app-date-selector>
      </span>
    </div>

    <div class="col-12">
      Cédula especialidad
      <span class="mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.speciality_card"
          placeholder="Cédula"
        />
      </span>
    </div>

    <div class="col-12">
      Consejo de especialidad (años)
      <span class="mt-2">
        <input
          type="number"
          class="form-control input-amm"
          [(ngModel)]="data.speciality_advice_years"
          placeholder="Años"
        />
      </span>
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <small class="text-primary">
        <strong>* Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
