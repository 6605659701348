import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

declare var gtag: Function;

class Response {
  data: any;
  status!: number;
  code!: number;
  message: string[] = [];
}

class Param {
  service: string = '';
  method: string = 'get';
  params: any = {};
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    protected router: Router,
    private userSession: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  private built(service: string): string {
    return environment.endpoint + service;
  }

  private parse(data: any): Response {
    let response = new Response();
    response.status = data.status;
    response.message = data.message;
    response.code = data.code;
    response.data = data.data;

    return response;
  }

  callReq(
    param: Param,
    successCallback: (response: any) => void,
    errorCallback: (error: any) => void
  ): void {
    param.method = param.method.toLowerCase();

    this.call(param.service, param.method, param.params).subscribe({
      next: (response: any) => {
        if (response.status == 200) {
          successCallback(response);
        } else {
          errorCallback(response.message);
        }
      },
      error: (error: any) => {
        errorCallback(error.message);
      },
    });
  }

  get(service: string, params: any = {}): Observable<any> {
    return this.http.get<Response>(this.built(service), params).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  call<T>(
    service: string,
    method = 'get',
    params: any = {}
  ): Observable<Response> {
    let serviceParams = method == 'get' ? { params: params } : params;

    if (method == 'post') {
      return this.http.post<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    } else if (method == 'put') {
      return this.http.put<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    } else if (method == 'delete') {
      return this.http
        .delete<Response>(this.built(service), serviceParams)
        .pipe(
          map((data: any) => {
            return this.parse(data);
          })
        );
    } else {
      return this.http.get<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    }
  }

  send(name: string, params: any) {
    if (environment.production) {
      try {
        gtag('event', name, params);
      } catch (err) {
        console.error(err);
      }
    }
  }

  open(service: string, finish: (res: boolean) => void, isFull = false) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        finish(true);
        window.open(u, '_blank');
      },
      error: (error: any) => {
        console.error(error);
        finish(false);
      },
    });
  }
  
  print(service: string, finish: (res: boolean) => void, isFull = false) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        const blob = new Blob([response.body], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);

        // Crear un elemento <iframe> oculto para cargar el PDF
        const iframe: any = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfUrl;

        // Agregar el <iframe> al documento
        document.body.appendChild(iframe);

        iframe.onload = () => {
          setTimeout(() => {
            iframe.contentWindow.print();
            finish(true);
          }, 500);
        };
      },
      error: (error: any) => {
        console.error(error);
        finish(false);
      },
    });
  }

  downloadSimple(service: string) {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http
      .get<HttpResponse<any>>(this.built(service), options)
      .subscribe((response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');
          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        const l = document.createElement('a');
        l.href = u;
        l.download = file;
        l.click();
      });
  }

  download(
    service: string,
    finish: (exit: boolean) => void,
    isFull = false,
    name = 'document.pdf',
    type = 'pdf'
  ) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          finish(false);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          let split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        } else {
          file += '.' + type;
        }

        if (isPlatformBrowser(this.platformId)) {
          let u = window.URL.createObjectURL(response.body);
          let l = document.createElement('a');
          l.href = u;
          l.download = name;
          l.click();
          finish(true);
        } else {
          finish(false);
        }
      },
      error: (error: any) => {
        console.error(error);
        finish(false);

        let errorMsg = error.error.message
          ? error.error.message
          : error.message;
        alert(errorMsg);
      },
    });
  }
}
