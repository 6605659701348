import { environment } from 'src/environments/environment';

export class SiteImage {
  source: string = '';
  title: string = '';
  subtitle: string = '';
  footer: string = '';
}

export class SiteAction {
  text: string = '';
  link: string = '';
}

export class Site {
  site?: string;
  image: SiteImage = new SiteImage();
  subtitle: string = '';
  messages: string[] = [];
  subtitleAction: SiteAction = new SiteAction();
}

export const SITES: any = {
  somos: {
    image: {
      source: '/assets/somos/images/dashboard.jpg',
      title: 'Bienvenidos a ' + environment.association.short_name,
      subtitle: 'Esta es su Cuenta',
      footer: 'Último acceso: 12/03/2023 11:57 hrs.',
    },
    subtitle: 'Mensaje de la Sociedad',
    messages: ['Próximo Congreso 2023'],
    subtitleAction: {
      text: 'Actualice su cuenta y cuotas de Afiliación',
      link: '/account/info',
    },
  },
  ammom: {
    image: {
      source: '/assets/ammom/images/dashboard.jpg',
      title: 'Bienvenidos a ' + environment.association.short_name,
      subtitle: 'Esta es su Cuenta',
      footer: 'Último acceso: 12/03/2023 11:57 hrs.',
    },
    subtitle: 'Mensajes de la Asociación',
    messages: ['Próximo Congreso 2024'],
    subtitleAction: {
      text: 'Para completar su proceso de Admisión o actualización de cuenta, llene todos los campos solicitados en la sección Cuenta y suba la información requerida.',
      link: '/account/info',
    },
  },
  AmmomCongress: {
    site: '/congress',
    image: {
      source: '/assets/ammom/images/congress.jpg',
      title: 'Congreso AMMOM 2024',
      subtitle: 'Bienvenido',
      footer: 'Último acceso: 12/03/2023 11:57 hrs.',
    },
    subtitle: 'Mensajes de la Asociación',
    messages: ['Próximo Congreso 2024'],
    subtitleAction: {
      text: 'Participe en el Congreso AMMOM',
      link: '/congress/register',
    },
  },
  ammom_webcasts: {
    site: '/congress',
    image: {
      source: '/assets/images/webcasts.jpg',
      title: 'Cursos y Sesiones Académicas de la Asociación',
      subtitle: 'Bienvenido',
      footer: '',
    },
    subtitle: '',
    messages: [],
    subtitleAction: {
      text: '',
      link: '',
    },
  },
  congress: {
    site: '/congress',
    image: {
      source: '/assets/images/congress.jpg',
      title:
        'Bienvenido a los Congresos de la ' +
        environment.association.short_name,
      subtitle: 'Cuenta asociada a Actividades del Congreso',
      footer: 'Último acceso: 12/03/2023 11:57 hrs.',
    },
    subtitle: 'Mensajes importantes',
    messages: [
      'Próximamente XX Congreso Nacional de Mastología, Acapulco 2023',
    ],
    subtitleAction: {
      text: 'Regístrese en el Congreso 2023',
      link: '/congress/register',
    },
  },
  default: {
    image: {
      source: '/assets/images/dashboard.jpg',
      title: 'Bienvenidos a la ' + environment.association.short_name,
      subtitle: 'Esta es su Cuenta',
      footer: 'Último acceso: 12/03/2023 11:57 hrs.',
    },
    subtitle: 'Mensaje de la Asociación',
    messages: [
      'Próximamente XX Congreso Nacional de Mastología, Acapulco 2023',
    ],
    subtitleAction: {
      text: 'Actualice su cuenta y cuotas de Afiliación',
      link: '/account/info',
    },
  },
  evaluation: {
    site: '/evaluation',
    image: {
      source: '/assets/images/evaluation.png',
      title:
        'Evaluaciones y Constancias de la ' +
        environment.association.short_name,
      subtitle: 'Bienvenido',
      footer: '',
    },
    subtitle: '',
    messages: [],
    subtitleAction: {
      text: '',
      link: '',
    },
  },
};
