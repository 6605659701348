import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, catchError, map, throwError } from 'rxjs';

import { UserService } from '../../user.service';

@Injectable()
export class ApiInterceptorInterceptor implements HttpInterceptor {
  constructor(private user: UserService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    let userToken = this.user.token;

    if (userToken != null) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + userToken),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 404 || response.error.status == 404) {
          alert(response.error.message.join('\n'));
        }

        if (response.status == 401 || response.error.status == 401) {
          this.user.clearSession();
          this.router.navigate(['/login']);
        }

        return throwError(response);
      })
    );
  }
}
