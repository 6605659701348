import { PaymentEnum as PaymentEnum } from '../payment/enums/PaymentKey.enum';
import { InvoiceDATA } from './Invoice.model';

export class Payment {
  id: number = 0;
  amount: string = '';
  type: string = '';
  description: string = '';
  quantity: number = 1;
  subscription_type: string | null = null;
  invoice: number = 0;
  created_at: string = '';
  vigency: string = '';
}

export class Discount {
  amount: string = '';
  name: string = '';
}

// It is used on payment service to get data
export class PaymentSubscription {
  id: number = -1;
  name: string = '';
  amount: number = 0;
  concept: string = '';
  discount: Discount | null = null;
  extras: string[] = [];

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) this[key] = data[key];
    }
  }
}

// EXCLUSIVE MAKE PAMENT DATA
export class MakePayment {
  subscription_id: number = 0;
  payment_method_id: string = '';
  // Band to control if invoice is required or not, 1 is not required, 2 is required and 3 is required and is foreign
  invoice: number = 1;
  email: string = '';
  save_invoicing: number = 0;
  invoicing: InvoiceDATA = new InvoiceDATA();
  // [{ subscription_id: 54, quantity: 1, data: {} }];
  extras: any[] = [];
  // isZero amount handler
  isZero: boolean = false;
  paymentType: PaymentEnum = PaymentEnum.Default;
  // Speciality handler
  isSpeciality: boolean = false;
  speciality_id: number = 0;

  getJSON() {
    if (this.isZero) {
      return {
        subscription_id: this.subscription_id,
        invoice: 0,
        extras: this.extras,
      };
    }

    const params: any = {
      email: this.email,
      extras: this.extras,
      invoice: this.invoice,
      invoicing: this.invoice !== 1 ? this.invoicing.getJSON() : {},
      payment_method_id: this.payment_method_id,
      save_invoicing: Number(this.save_invoicing),
      subscription_id: this.subscription_id,
    };

    if (this.isSpeciality) {
      params['speciality_id'] = this.speciality_id;
    }

    return params;
  }
}
