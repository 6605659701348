<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="row">
    <div class="col-12 p-0" *ngIf="profile.is_member === 1 && site !== 'ammom'">
      <div class="row m-0">
        <div class="col-12 px-0 mb-4">
          <h3>Privacidad</h3>
        </div>

        <div class="col-12 px-0 mb-4" (click)="data.public = true">
          <label
            class="card card-hoverable clickable p-2 pd-md-3"
            [ngClass]="{ 'card-selected': data.public }"
            for="cardRadio1"
          >
            <small
              class="text-info xsmall my-2"
              [ngClass]="
                profile.public === 1 ? 'show-data' : 'hide-data d-none'
              "
            >
              SU SELECCIÓN
            </small>

            <h6 class="card-title">Información pública</h6>

            <div class="form-check w-100">
              <input
                class="form-check-input"
                type="radio"
                name="information"
                id="cardRadio1"
                [value]="true"
                [(ngModel)]="data.public"
              />
              <label class="form-check-label" for="cardRadio1">
                Autorizo hacer público los datos para el Directorio Electrónico
                de Socios
              </label>
            </div>
          </label>
        </div>

        <div class="col-12 px-0" (click)="data.public = false">
          <label
            class="card card-hoverable clickable p-2 pd-md-3"
            [ngClass]="{ 'card-selected': !data.public }"
            for="cardRadio2"
          >
            <small
              class="text-info xsmall my-2"
              [ngClass]="
                profile.public === 0 ? 'show-data' : 'hide-data d-none'
              "
            >
              SU SELECCIÓN
            </small>

            <h6 class="card-title">Información no pública</h6>

            <div class="form-check w-100">
              <input
                class="form-check-input"
                type="radio"
                name="information"
                id="cardRadio2"
                [value]="false"
                [(ngModel)]="data.public"
              />
              <label class="form-check-label" for="cardRadio2">
                No deseo hacer público los datos para el Directorio Electrónico
                de Socios
              </label>
            </div>
          </label>
        </div>
      </div>
    </div>

    <hr class="my-4 my-lg-5" *ngIf="site !== 'ammom'" />

    <div class="col-12 card py-3 mb-4" *ngIf="site !== 'ammom'">
      <h5>Preferencias envío de información</h5>

      <div class="form-check clickable my-3">
        <input
          class="form-check-input"
          type="radio"
          name="dataRadio"
          id="dataRadio1"
          [value]="true"
          [(ngModel)]="data.newsletter"
        />
        <label class="form-check-label" for="dataRadio1">
          Deseo recibir información de eventos y notificaciones de la
          Asociación.
        </label>
      </div>

      <div class="form-check clickable">
        <input
          class="form-check-input"
          type="radio"
          name="dataRadio"
          id="dataRadio2"
          [value]="false"
          [(ngModel)]="data.newsletter"
        />
        <label class="form-check-label" for="dataRadio2">
          No deseo recibir información de eventos y notificaciones de la
          Asociación.
        </label>
      </div>
    </div>

    <div class="col-12 card py-3 mb-4">
      <h5>Conformidad con los Términos y condiciones</h5>

      <div class="form-check my-3">
        <input
          class="form-check-input"
          type="radio"
          name="termsRadio"
          id="termsRadio1"
          [value]="true"
          [(ngModel)]="data.legals"
        />
        <label class="form-check-label" for="termsRadio1">
          Al formar parte de la Asociación, acepto los
          <a class="text-primary" routerLink="/terms">Términos y condiciones</a>
          y su
          <a class="text-primary" routerLink="/terms">Aviso de Privacidad</a>
        </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="termsRadio"
          id="termsRadio2"
          [value]="false"
          [(ngModel)]="data.legals"
        />
        <label class="form-check-label" for="termsRadio2">
          No acepto los
          <a class="text-primary" routerLink="/terms">Términos y condiciones</a>
          y su
          <a class="text-primary" routerLink="/terms">Aviso de Privacidad</a>.
        </label>
      </div>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12 my-4">
      <button
        class="btn btn-primary btn-amm"
        (click)="submit()"
        [disabled]="loading"
      >
        GUARDAR
      </button>
    </div>
  </div>
</div>
