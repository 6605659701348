import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarItem } from '@models/Sidebar.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {
  sidebarItems: SidebarItem[];
  isCongress: boolean = false;

  constructor(private router: Router) {
    this.isCongress = router.url.includes('congress');
    
    this.sidebarItems = [
      {
        name: 'Pagos',
        link: `/payment/${this.isCongress ? 'congress' : 'subscription'}`,
        icon: 'home',
      },
      // { name: 'Preferencias de pago', link: '/payment/preferences', icon: 'account_box' },
      { name: 'Facturas', link: '/payment/invoice', icon: 'account_box' },
      {
        name: 'Datos de facturación',
        link: '/payment/billing-data',
        icon: 'account_box',
      },
    ];
  }
}
