<div class="app-container">
  <router-outlet></router-outlet>

  <div class="wa-btn" *ngIf="waPhone">
    <a
      class="btn btn-primary"
      [href]="'https://wa.me/' + waPhone"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i class="fa-brands fa-whatsapp fa-xl"></i>
    </a>
  </div>
</div>
