<div class="container py-4 px-lg-4" [ngClass]="{ loading: loading }">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="text-center mt-4 mb-5">
    Información Socios {{ environment.association.name }}
  </h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Información institucional</h3>
    </div>

    <div class="col-12">
      Institución
      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm mt-2"
          [(ngModel)]="data.institute"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Departamento

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.department"
          placeholder="Nombre"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Correo electrónico institucional

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.institute_email"
          placeholder="usuario@dominio.com"
          validationdirective
        />
      </span>
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Domicilio institución</h3>
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Calle"
        [(ngModel)]="data.institute_street"
      />
    </div>

    <div class="col-6">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Núm. Ext."
        [(ngModel)]="data.institute_external_house_number"
      />
    </div>

    <div class="col-6">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Núm. Int."
        [(ngModel)]="data.institute_internal_house_number"
      />
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Colonia"
        [(ngModel)]="data.institute_neighborhood"
      />
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Municipio o alcaldía"
        [(ngModel)]="data.institute_municipality"
      />
    </div>

    <div class="col-12">
      <ng-select
        [items]="states"
        bindLabel="name"
        bindValue="id"
        placeholder="Estado"
        [(ngModel)]="data.institute_state"
        [clearable]="false"
        *ngIf="
          data.institute_country?.toLowerCase() === 'méxico' ||
            data.institute_country?.toLowerCase() === 'mexico';
          else noMexico
        "
      ></ng-select>

      <ng-template #noMexico>
        <input
          type="text"
          class="form-control input-amm"
          placeholder="Estado"
          [(ngModel)]="data.institute_state"
        />
      </ng-template>
    </div>

    <div class="col-12">
      <input
        type="number"
        class="form-control input-amm text-start"
        placeholder="Código postal"
        [(ngModel)]="data.institute_zip_code"
        maxdigits
        [maxlength]="5"
      />
    </div>

    <div class="col-12">
      <ng-select
        [items]="countries"
        bindLabel="name"
        bindValue="name"
        placeholder="Elija un país"
        [(ngModel)]="data.institute_country"
        [clearable]="false"
      ></ng-select>
    </div>

    <div class="col-12">
      <input
        type="text"
        class="form-control input-amm text-start"
        placeholder="Ciudad"
        [(ngModel)]="data.institute_city"
      />
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <small>
        <strong><span class="text-primary">*</span> Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
