import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from './storage.service';

class Variable {
  key: string = '';
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private stepObservable = new BehaviorSubject<string>('');
  stepChanged = this.stepObservable.asObservable();

  private profileObservable = new BehaviorSubject<string>('');
  profileChanged = this.profileObservable.asObservable();

  constructor(private storage: Storage) {}

  get logged(): boolean {
    return this.token ? true : false;
  }

  // TOKEN KEY
  get token(): string {
    return this.storage.getData('token') ?? '';
  }
  set token(token: string) {
    this.storage.setData('token', token);
    this.profileObservable.next('profile');
  }

  // PROFILE KEY
  get profile(): any {
    return this.storage.getData('profile') ?? '';
  }
  set profile(profile: any) {
    this.storage.setData('profile', profile);
    this.profileObservable.next('profile');
  }

  // REGISTER KEY
  get register(): any {
    return this.storage.getData('register') ?? '';
  }
  getRegister(key?: string): any {
    const data: any = this.register;

    if (key) {
      if (data[key]) {
        return data[key];
      } else return null;
    }

    if (data) return data;

    return null;
  }
  setRegister({ key, value }: Variable) {
    const data: any = this.register || {};
    data[key] = value;
    this.storage.setData('register', data);
  }

  saveMembership(membership: any) {
    this.storage.setData('membership', membership);
  }

  getMembership() {
    return this.storage.getData('membership');
  }

  cleanRegister() {
    this.registerStep = 1;
    this.storage.setData('register', {});
  }

  // REGISTER STEP CONTROL KEY
  get registerStep(): number {
    return Number(this.storage.getData('step') ?? 1);
  }
  
  set registerStep(step: number) {
    this.storage.setData('step', step);
    this.stepObservable.next(String(step));
  }

  clearSession() {
    this.token = '';
    this.storage.cleanStorage();
  }
}
