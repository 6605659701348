import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AlertModal } from '@models/Alert.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  timerActive: boolean = false;
  timerRef: any;
  constructorClass: string = 'hidden-alert';
  _alert: AlertModal = new AlertModal();

  @ViewChild('alert') alert?: ElementRef;

  @Input() time: number = 3000;
  @Input() set data(alertData: AlertModal) {
    this._alert = alertData;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.constructorClass = '';
    }, 300);
  }

  closeAlert() {
    this._alert.active = false;
  }
}
