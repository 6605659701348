import { Discount } from '@models/Payment.model';

export class HotelReservation {
  arrivalDate: string = '';
  amount: number = 0;
  leaveDate: string = '';
  nights: number = 0;
  room: HotelRoom = new HotelRoom();
  terms: boolean = false;
  social: boolean = true;
  total: number = 1;
  adults: number = 1;
  children: number = 0;
  guests: HotelGuest[] = [];
  type: string = '';
  error: string = '';

  setData({ room, ...data }: any): void {
    for (const key in this) {
      if (data[key] !== undefined) this[key] = data[key];
    }

    const nRoom = new HotelRoom();
    if (room) nRoom.setData(room);

    this.room = nRoom;
  }

  isValid(): boolean {
    if (this.adults <= 0) {
      this.error = 'Ingresa una cantidad de personas';
      return false;
    }

    if (!this.arrivalDate) {
      this.error = 'Ingresa la fecha de llegada';
      return false;
    }

    if (!this.leaveDate) {
      this.error = 'Ingresa la fecha de salida';
      return false;
    }

    if (!this.checkDate(this.arrivalDate, this.leaveDate)) {
      this.error =
        'Rango de fecha no válido. Debe reservar un mínimo de: <strong>1 noche</strong> y máximo de: <strong>10 noches</strong>';
      return false;
    }

    if (this.room.id === -1) {
      this.error = 'Elige una habitación';
      return false;
    }

    return true;
  }

  checkDate(startDate: string, endDate: string): boolean {
    const fechaInicioObj = new Date(startDate);
    const fechaFinObj = new Date(endDate);

    const diferenciaTiempo = fechaFinObj.getTime() - fechaInicioObj.getTime();
    const diferenciaDias = Math.ceil(diferenciaTiempo / (1000 * 3600 * 24));

    return diferenciaDias >= 1 && diferenciaDias <= 10;
  }
}

export class HotelRoom {
  id: number = -1;
  name: string = '';
  amount: number = 0;
  concept: string = '';
  discount: Discount | null = null;
  type: string = 'hotel';
  data: HotelRoomData = new HotelRoomData();

  setData({ data, ...hotelData }: any): void {
    this.data.setData(data);

    for (const key in this) {
      if (hotelData[key] !== undefined) this[key] = hotelData[key];
    }
  }
}

export class HotelRoomData {
  id: number = 1;
  name: string = 'Deluxe';
  message: string | null = null;
  min: number = 1;
  max: number = 2;
  extra: number | null = null;

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) this[key] = data[key];
    }
  }
}

export class HotelGuest {
  guestType: number = -1;
  name: string = '';
  lastname: string = '';
  email: string = '';

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) this[key] = data[key];
    }
  }

  isValid(): boolean {
    const { guestType, ...data } = this;

    if (guestType === -1) return false;
    // DATA ES SOLO STRINGS

    const values = Object.values(data);

    return values.every((value) => value && value.length > 0);
  }
}
