<app-amm-modal title="RECUPERACIÓN DE CONTRASEÑA" (close)="closeModal()">
  <!-- <div modalTitle>
    RECUPERACIÓN DE CONTRASEÑA
  </div> -->

  <div modalContent>
    <div
      class="row py-3 m-0 modal-image text-white text-center"
      [style.background-image]="
        'url(/assets/images/horizontal_' + environment.key + '.jpg)'
      "
    >
      <div class="col-12 p-0 text-center">
        <img
          [src]="'images/logo_header.png' | siteAsset : site"
          alt="Logo"
          width="120"
        />
      </div>

      <div class="col-12 mt-3 text-center">
        <strong>
          {{ environment.association.short_name }}
        </strong>
      </div>
    </div>

    <form [formGroup]="form" class="row p-2 p-md-3 m-0 text-center">
      <div class="col-12 my-4">
        <p>
          Ingresa tu correo electrónico de socio y te enviaremos instrucciones
          para restablecer tu contraseña.
        </p>
      </div>

      <div class="col-12">
        <input
          type="text"
          class="form-control input-amm mb-3"
          id="email"
          name="email"
          placeholder="Email"
          formControlName="email"
        />
      </div>

      <div class="col-12 mt-2" *ngIf="isValid('email')">
        <strong class="text-danger">
          * Ingresa un correo electrónico válido.
        </strong>
      </div>

      <div class="col-12 my-3">
        <small
          >Te recomendamos revisar tu carpeta de SPAM o correo no deseado y
          marcarlo como correo seguro, si tardas en recibir el mensaje.</small
        >
      </div>

      <app-alert [data]="alertData"></app-alert>

      <div class="col-6 col-md-5 mx-auto mt-4">
        <button
          class="btn btn-outline-dark"
          (click)="closeModal()"
          [disabled]="loading"
        >
          {{ success ? "CERRAR" : "CANCELAR" }}
        </button>
      </div>
      <div class="col-6 col-md-5 mx-auto mt-4" *ngIf="!success">
        <button
          class="btn btn-primary btn-amm"
          (click)="submitForm()"
          [disabled]="loading || success"
        >
          SOLICITAR
        </button>
      </div>
    </form>
  </div>
</app-amm-modal>
