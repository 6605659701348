export class DoctorOffice {
  hospital_name: string = '';
  hospital_number: string = '';
  hospital_phone: string = '';
  hospital_ext: string = '';

  hospital_address_street: string = '';
  hospital_address_ext: string = '';
  hospital_address_int: string = '';
  hospital_address_colony: string = '';
  hospital_address_municipality: string = '';
  hospital_address_state: string | null = null;
  hospital_address_zip: string = '';
  hospital_address_country: string = 'México';
  hospital_address_city: string = '';

  getJson(): any {
    return {
      hospital_name: this.hospital_name,
      hospital_number: this.hospital_number,
      hospital_phone: this.hospital_phone,
      hospital_ext: this.hospital_ext,
      hospital_address_street: this.hospital_address_street,
      hospital_address_ext: this.hospital_address_ext,
      hospital_address_int: this.hospital_address_int,
      hospital_address_colony: this.hospital_address_colony,
      hospital_address_state: this.hospital_address_state,
      hospital_address_municipality: this.hospital_address_municipality,
      hospital_address_zip: this.hospital_address_zip,
      hospital_address_country: this.hospital_address_country,
      hospital_address_city: this.hospital_address_city,
    };
  }

  isValid(): boolean {
    return Boolean(this.hospital_name && this.hospital_number);
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
