import { Component, Input } from '@angular/core';
import { Table } from '@models/Table.model';

@Component({
  selector: 'app-amm-table',
  templateUrl: './amm-table.component.html',
  styleUrls: ['./amm-table.component.scss']
})
export class AmmTableComponent {
  @Input() data: Table = new Table();
}
