<div class="row w-100 m-0">
  <div class="col-12 mb-3">
    <input
      type="text"
      class="form-control input-amm mb-3"
      id="name-card"
      placeholder="Nombre"
      [(ngModel)]="name"
    />

    <ngx-stripe-card
      containerClass="input-amm p-2 text-primary my-3"
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
    ></ngx-stripe-card>

    <app-alert [data]="alertData"></app-alert>
  </div>
</div>
