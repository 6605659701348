import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Memebership } from '@models/Membership.model';

@Component({
  selector: 'app-membership-selector',
  templateUrl: './membership-selector.component.html',
  styleUrls: ['./membership-selector.component.scss'],
})
export class MembershipSelectorComponent {
  @Input() currentId: number = 0;
  @Input() data: Memebership = new Memebership();
  @Input() isCollapsed: boolean = true;
  @Input() hasRadio: boolean = true;
  @Output() onCollapse: EventEmitter<number> = new EventEmitter();
  @Output() onMemberChange = new EventEmitter<number>();
  groupName: string = 'membership-gp';
  

  changeType() {
    this.onMemberChange.emit(this.data.id);
  }

  handleCollapsable() {
    this.isCollapsed = !this.isCollapsed;

    if (!this.isCollapsed) this.onCollapse.emit(this.data.id);
  }
}
