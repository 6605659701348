<input
  class="form-control input-amm w-100"
  [value]="data"
  type="date"
  name="date"
  id="date"
  (change)="selectDate($event)"
  [min]="_min"
  [max]="_max"
/>
