import { Component } from '@angular/core';
import { STATES } from '../info/Data';
import { UserService } from '@services/user.service';
import { ApiService } from '@services/api.service';
import { forkJoin } from 'rxjs';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { DoctorOffice } from '@models/DoctorOffice.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doctors-office',
  templateUrl: './doctors-office.component.html',
  styleUrls: ['./doctors-office.component.scss'],
})
export class DoctorsOfficeComponent extends AmmFormComponent {
  states: string[] = [...STATES];
  countries: string[] = [];
  override data: DoctorOffice = new DoctorOffice();
  environment:any = environment;

  constructor(api: ApiService, private user: UserService) {
    super(api);
  }

  override isValid(): boolean {
    const valid = this.data.isValid();

    !valid && this.failed('Llena todos los campos requeridos');

    return valid;
  }

  override getParams() {
    return this.data.getJson();
  }

  override settings(): void {
    this.endpoint = 'portal/me/profile/hospital';
    this.method = 'put';

    const services = [
      this.api.call('contact/countries'),
      this.api.call('portal/me'),
    ];

    forkJoin(services).subscribe({
      next: ([countriesRes, profileRes]) => {
        if (countriesRes.status === 200) {
          this.countries = countriesRes.data;
        }

        if (profileRes.status === 200) {
          this.data.setData(profileRes.data.profile);
        }

        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
      },
    });
  }
}
