import { Component, Input } from '@angular/core';
import { Memebership } from '@models/Membership.model';
import { Profile } from '@models/Profile.model';

import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finish-register',
  templateUrl: './finish-register.component.html',
  styleUrls: ['./finish-register.component.scss'],
})
export class FinishRegisterComponent {
  @Input() profile: Profile = new Profile();

  currentMembership: Memebership = new Memebership();
  environment: any = environment;
  isLogged: boolean = false;

  constructor(private user: UserService, protected siteService: SiteService) {}

  ngOnInit(): void {
    this.currentMembership = this.user.getMembership() ?? new Memebership();
    this.isLogged = this.user.logged;
  }
}
