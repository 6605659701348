<div class="table-responsive">
  <table class="table m-0 amm-table-striped table-bordered">
    <thead>
      <tr>
        <th
          class="amm-header"
          *ngFor="let header of data.headers"
          [class]="header.color"
          scope="col"
        >
          {{ header.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data.data">
        <td
          class="amm-cols"
          [ngClass]="col.currency ? 'text-end' : 'text-start'"
          *ngFor="let col of row"
        >
          {{ col.currency ? (+col.content | currency) : col.content }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div
  *ngIf="data.footer"
  class="pt-3"
  [innerHTML]="data.footer"
  style="font-size: 11px"
></div>
