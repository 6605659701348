import { Component } from '@angular/core';
import { Recommendation } from '@models/Recommendation.model';
import { ApiService } from '@services/api.service';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent extends AmmFormComponent {
  override data: Recommendation = new Recommendation();
  environment:any = environment;

  constructor(api: ApiService) {
    super(api);
  }

  override settings(): void {
    // todo: change this
    this.endpoint = 'portal/me/profile/recomendations';
    this.method = 'put';

    this.api.callReq(
      {
        service: 'portal/me',
        method: 'get',
        params: {},
      },
      (response) => {
        if (response.status === 200) {
          if (
            response.data.recomendations &&
            Array.isArray(response.data.recomendations)
          ) {
            this.data.recomendations = [];
            response.data.recomendations.forEach((recom: string) => {
              if (this.data.recomendations.length < 2) {
                this.data.recomendations.push(recom);
              }
            });
          } else if (response.data.profile) {
            this.data.setData(response.data.profile);
          }
        }

        this.loading = false;
      },
      (error) => {
        console.error(error);

        this.loading = false;
      }
    );
  }

  override isValid(): boolean {
    const valid = this.data.isValid();

    !valid && this.failed('Llena todos los campos requeridos');

    return valid;
  }

  override getParams() {
    return { recomendations: this.data.getJson() };
  }
}
