export class MedicalEducation {
  college_name: string = '';
  college_register: string = '';
  college_foreinger: string = '';

  getJson(): any {
    return JSON.stringify(this);
  }

  isValid(): boolean {
    return Boolean(this.college_name && this.college_register);
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }
}
