import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertType } from '@models/Alert.model';
import { Memebership } from '@models/Membership.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent extends AmmFormComponent {
  memberships: Memebership[] = [];
  newMembers: Memebership[] = [];
  specialities: any;
  collapseId: number = -1;
  // Id set by service don't change otherwise
  currentId: number = -1;
  isLogged: boolean = false;
  // Can be changed if is logged or not
  service: string = 'portal/memberships';
  // Exclusive register variables
  membership: Memebership = new Memebership();
  step: number = 0;
  nextStep: number = 3;
  saved: boolean = false;
  // Message when afiliation or membership is not paied
  message: string = '';
  submitted: boolean = false;
  // IF NO MEMBER WANTS JOIN TO MEMBERSHIP
  specialityId: number = -1;

  // just some profiles can change membership
  canChange: boolean = true;
  environment: any = environment;

  override data: Profile = new Profile();

  @ViewChild('specialityModal', { read: TemplateRef })
  membershipModal?: TemplateRef<any>;

  constructor(
    api: ApiService,
    private user: UserService,
    private router: Router,
    protected siteService: SiteService
  ) {
    super(api);
  }

  ngOnInit(): void {
    this.isLogged = this.user.logged;
    this.step = this.user.registerStep;
    this.endpoint = 'portal/me/membership';
    this.method = 'post';

    let params: string = '';

    if (this.isLogged) {
      this.service = 'portal/me/memberships';

      this.getProfile();
    } else {
      this.saved = this.router.url.includes('/no-membership');
      this.service = 'portal/memberships';
      this.getRegisterData();

      if (this.saved) {
        this.nextStep = 4;

        if (
          this.user.getRegister('MAINMEMBER') &&
          this.user.getRegister('MAINMEMBER').id
        )
          params = String(this.user.getRegister('MAINMEMBER').id);
      }

      this.getMemberships(params ? `membership_id=${params}` : '');
    }
  }

  getProfile() {
    this.api.call('portal/me').subscribe((response: any) => {
      if (response.status === 200) {
        this.data.setProfile(response.data);
        // Check if can change membership
        this.canChange =
          this.data.upgradable === environment.memberships.no_profile ||
          this.data.is_member === 0;

        if (
          this.data.debit === 0 ||
          this.data.membership_id === environment.memberships.pending
        ) {
          this.message = `El pago de su afiliación para la Membresía de Socio ${
            this.data.membership_id === environment.memberships.pending
              ? 'Asociado'
              : 'Transitorio'
          } está pendiente, para activar su cuenta de Socio favor de completar el pago`;
        }
      }

      this.getMemberships();
    });
  }

  getMemberships(param: string = '') {
    this.loading = true;

    this.api.callReq(
      {
        service: `${this.service}${param ? '?' : ''}` + param,
        method: 'get',
        params: {},
      },
      (response: any) => {
        this.memberships = response.data;

        this.memberships = this.memberships.map((membership) => {
          const benefits = membership.description.split('<separator>');

          if (benefits[0]) {
            membership.description = benefits[0];
          }

          if (benefits[1] && benefits[1].split('\n').length > 0) {
            membership.benefits = benefits[1].split('\n');
            membership.benefits.shift();
            membership.benefits.pop();
          }

          return membership;
        });

        if (this.isLogged) {
          this.currentId = this.data.membership_id;
        } else {
          if (this.memberships.length === 0) {
            this.saveDataRegister();
            this.data.membership_id = -1;

            return;
          }
        }

        this.loading = false;
      },
      (error: any) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  override success(response: any): void {
    // this.alertData.data = {
    //   title: '¡Solicitud enviada!',
    //   content: 'Tu solicitud esta pendiente de revision.',
    //   className: AlertType.Pink,
    // };

    this.submitted = true;
    this.loading = false;
  }

  override failed(error: any): void {
    console.error(error);
    this.alertData.data = {
      title: '¡Error!',
      content: error,
      className: AlertType.Danger,
    };

    this.loading = false;
  }

  override isValid(): boolean {
    if (
      this.isLogged &&
      this.data.membership_id === environment.memberships.no_profile
    )
      return false;

    if (this.data.membership_id === -1) {
      this.alertData.data = {
        content: 'Selecciona una membresía.',
        className: AlertType.Danger,
      };

      return false;
    }

    return true;
  }

  override getParams(): {} {
    return {
      membership_id: this.data.membership_id,
    };
  }

  // VISUAL Change membership when it's LOGGED and is member
  selectMembership(member: Memebership) {
    // if (member.id !== 1) return;
    if (this.canChange && !this.submitted) this.data.membership_id = member.id;
  }

  // REGISTER FUNTIONS
  getRegisterData() {
    const member = this.user.getMembership();

    if (member) {
      this.data.membership_id = member.id;
      this.membership = member;
      this.currentId = member.id;
    }
  }

  saveDataRegister() {
    this.loading = true;

    const data = {
      key: 'STEP2',
      value: {
        membership_id: this.data.membership_id,
      },
    };

    this.user.setRegister(data);
    this.user.registerStep =
      this.nextStep < this.step ? this.step : this.nextStep;

    this.loading = false;
  }

  handleAction() {
    if (!this.isLogged) {
      this.changeMembership();
      return;
    }

    if (this.data.is_member === environment.memberships.no_profile) {
      this.changeMembership();
    } else {
      if (this.canChange) {
        this.confirmSpeciality();
      }
    }
  }

  // Call service when logged in
  changeMembership() {
    if (this.isLogged) {
      if (this.currentId === this.data.membership_id) {
        this.alertData.data = {
          content: '¡Selecciona una membresía diferente!',
          className: AlertType.Dark,
        };

        return;
      }

      this.submit();
      return;
    }

    if (!this.isValid()) return;

    if (
      this.membership.id === environment.memberships.no_profile &&
      !this.saved
    ) {
      this.user.registerStep = 22;

      const value = {
        key: 'STEP3',
        value: this.data.getRegisterJSON(),
      };

      this.user.setRegister(value);
    } else {
      if (
        this.membership.id === environment.memberships.pending ||
        this.membership.id === environment.memberships.transitional
      ) {
        this.user.registerStep = 33;
      }

      this.getMemberships(`membership_id=${this.data.membership_id}`);
      return;
    }

    this.changeMember(this.membership);
  }

  confirmSpeciality() {
    // if (this.specialityId === -1) {
    //   return;
    // }
    this.router.navigate([`/payment/membership`]);
    // this.closeModal();
  }

  // ONLY not logged IT save in storage
  changeMember(member: Memebership) {
    this.user.saveMembership(member);

    if (!this.saved) {
      this.membership = member;

      this.user.setRegister({
        key: 'MAINMEMBER',
        value: this.membership,
      });
    }

    this.data.membership_id = member.id;
    this.currentId = member.id;
  }
}
