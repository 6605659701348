import { Pipe, PipeTransform } from '@angular/core';

const PAYMENT_TYPES: any = {
  congress: 'Congreso',
  // anulidad
  membership: 'Cuota',
  affiliation: 'Afiliación',
  virtual: 'Congreso Virtual',
  webcast: 'Webcast'
};

@Pipe({
  name: 'paymenttype',
})
export class PaymentTypePipe implements PipeTransform {
  transform(type: string | null): string {
    if (type) {
      return PAYMENT_TYPES[type];
    }

    return 'Otro';
  }
}

const PAYMENT_ACTIONS: any = {
  // congress: 'Carta Permiso',
  // virtual: 'Carta Permiso',
  membership: 'Constancia Socio',
  affiliation: 'Descargar Constancia',
  webcast: 'Ir a Constanias'
};

@Pipe({
  name: 'paymentactiontype',
})
export class PaymentActionPipe implements PipeTransform {
  transform(type: string, vigency?: string): string {
    if (type === 'membership' || type === 'affiliation') {
      return `${PAYMENT_ACTIONS[type]} ${vigency ?? ''} `;
    }

    return PAYMENT_ACTIONS[type];
  }
}
