import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, AlertType } from '@models/Alert.model';
import { Profile } from '@models/Profile.model';
import { UsertType } from '@models/UserType';
import { ApiService } from '@services/api.service';
import { SiteService } from '@services/site.service';
import { UserService } from '@services/user.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  alertData: AlertModal = new AlertModal();
  currentUserType: number = -1;
  currentCollapse: number = -1;
  email: string = '';
  loading: boolean = true;
  logoImage: string = '';
  password: string = '';
  step: number = 0;
  userProfile: Profile = new Profile();
  site: SiteEnum = SiteEnum.Amm;
  environment: any = environment;
  usersType: UsertType[] = [];
  support: string = '';

  @ViewChild('passwordModal', { read: TemplateRef })
  passwordModal?: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private user: UserService,
    private router: Router,
    private modalService: NgbModal,
    private siteService: SiteService
  ) {
    this.site = this.siteService.site;
    this.initLoginImage();

    if (user.profile) {
      this.userProfile.setData(user.profile);
      this.email = this.userProfile.email;
      this.currentUserType = user.profile.partner;
      this.step = 1;
    }

    this.loading = false;
  }

  ngOnInit(): void {
    this.initUsersType();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (this.passwordModal && params['email']) {
        this.openModal(this.passwordModal);
      }
    });
  }

  initLoginImage() {
    switch (this.site) {
      case SiteEnum.Amm:
        this.logoImage = 'assets/svg/logo.svg';
        break;

      case SiteEnum.Ammom:
        this.logoImage = 'assets/ammom/images/logo-ammom.png';

        break;

      case SiteEnum.Somos:
        this.logoImage = 'assets/somos/images/logo_amm2.png';

        break;

      default:
        this.logoImage = 'assets/svg/logo.svg';

        break;
    }
  }

  changeUser(userInd: number) {
    this.currentUserType = userInd;
  }

  dismissAlert() {
    this.alertData.setActive(false);
  }

  changeMail() {
    this.step = 0;
    this.user.profile = '';
  }

  checkMail() {
    if (!this.email) {
      this.alertData.data = {
        title: '',
        content: '¡Ingresa tu correo electrónico.!',
        className: AlertType.Warnings,
      };

      return;
    }

    this.alertData.setActive(false);

    if (this.loading) return;

    this.loading = true;
    const params = {
      email: this.email,
      partner: this.currentUserType,
    };

    // 'portal/status', 'post', params
    this.api.call('portal/status', 'post', params).subscribe({
      next: (response: any) => {
        if (response.status === 402) {
          this.handleAlert(response.message, AlertType.Secondary);
          this.loading = false;

          return;
        }
        // No account found or partner error
        if (response.status === 400) {
          this.handleAlert(response.message, AlertType.Danger);

          this.loading = false;

          return;
        }

        // Has account but password is not set then redirect to set new password
        if (response.status === 401) {
          this.handleAlert(response.message, AlertType.Info, '');
          this.loading = false;
          this.user.profile = params;

          return;
        }

        this.user.profile = params;
        this.loading = false;
        this.step = 1;
      },
      error: (error: any) => {
        console.error(error);
        this.handleAlert(error, AlertType.Danger);
        this.user.profile = '';

        this.loading = false;
      },
    });
  }

  register() {
    this.user.cleanRegister();
    this.router.navigate(['/register/access']);
  }

  openModal(content: any) {
    const modalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  login() {
    if (this.loading) return;
    this.loading = true;

    this.api.callReq(
      {
        service: 'portal/login',
        method: 'post',
        params: { email: this.email, password: this.password },
      },
      (response: any) => {
        this.user.token = response.data.token;
        this.user.profile = response.data.user;
        this.router.navigate(['/']);
      },
      (error: any) => {
        this.handleAlert(error, AlertType.Danger);

        this.loading = false;
      }
    );
  }

  handleAlert(message: string, type: AlertType, title: string = '¡Error!') {
    this.alertData.data = {
      title: title,
      content: message,
      className: type,
    };
  }

  initUsersType() {
    switch (this.siteService.site) {
      case SiteEnum.Ammom:
        this.support = `educacion@ammom.mx`;

        this.usersType = [
          {
            id: 1,
            name: 'SOCIO AMMOM (Miembro Pleno o Adjunto)',
            description:
              'Socio con cuenta activa con acceso a congresos y sesiones virtuales.',
          },
          {
            id: 0,
            name: 'NO SOCIO (Aliado Solidario)',
            description:
              'Usuario registrado en algún evento de AMMOM y/o con interés en cursos y sesiones sin ser miembro',
          },
        ];

        break;
      default:
        this.support = `soporte@${environment.domain}`;

        this.usersType = [
          {
            id: 1,
            name: 'SOCIO ' + this.siteService.site.toUpperCase(),
            description:
              'Socio con cuenta activa con acceso a congresos y sesiones virtuales.',
          },
          {
            id: 0,
            name: 'NO SOCIO',
            description:
              'Usuario registrado en eventos académicos con cuenta activa o por completar.',
          },
        ];

        break;
    }
  }
}
